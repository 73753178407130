/* eslint-disable */
import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useHistory, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiDatePicker from "components/SuiDatePicker";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { URL_RUNSETS } from "api/myslr";
import axios from "api/index";
import { useAuth } from "auth-context/auth.context";
import FormField from "./FormField";

// sweetalert2 components
import Swal from "sweetalert2";

function NewRunset() {
  const { user } = useAuth();
  const history = useHistory();
  const { dataset_id, querystring_id } = useParams();
  const [algorithm, setAlgoritm] = useState({ value: "gensim_lda", label: "Gensim LDA" });
  const [topicNum, setTopicNum] = useState(2);

  const [error, setError] = useState(null);

  const handlePost = () => {
    let postData = new FormData();
    postData.append("algorithm", algorithm.value);
    postData.append("topic_num", topicNum);
    postData.append("runset_dataset", dataset_id);
    axios
      .post(URL_RUNSETS, postData, {
        headers: {
          Authorization: `${user.token}`,
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        if (response.status == 201) {
          Swal.fire("Success!", "Run configuration created", "success");
          history.replace(`/myslr/querystring-page/${querystring_id}/1`);
        } else {
          Swal.fire("Error!", "We can't create this configuration, check your data again", "error");
        }
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  New topic modeling Model Training configuration
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Choose best parameters to launch model training
                </SuiTypography>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <SuiBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Algoritm
                        </SuiTypography>
                      </SuiBox>
                      <SuiSelect
                        defaultValue={[{ value: "started", label: "Started" }]}
                        options={[
                          { value: "gensim_lda", label: "Gensim LDA" },
                          { value: "gensim_lsi", label: "Gensim LSI" },
                        ]}
                        value={algorithm}
                        onChange={(value) => {
                          //adding the onChange event
                          setAlgoritm(value);
                        }}
                      />
                    </SuiBox>
                  </Grid>
                  <Grid item xs={6}>
                    <SuiBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Topic number
                        </SuiTypography>
                      </SuiBox>
                      <SuiInput
                        type="number"
                        value={topicNum}
                        onChange={(event) => {
                          setTopicNum(event.target.value);
                        }}
                      />
                    </SuiBox>
                  </Grid>
                </Grid>
                <Divider />
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="button" fontWeight="bold">
                      Gensim LDA
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mt={0} mb={3}>
                    <SuiTypography component="label" variant="caption">
                      This module allows both LDA model estimation from a training corpus and
                      inference of topic distribution on new, unseen documents. The core estimation
                      code is based on the onlineldavb.py script, by Matthew D. Hoffman, David M.
                      Blei, Francis Bach: "Online Learning for Latent Dirichlet Allocation", NIPS
                      2010.
                    </SuiTypography>
                  </SuiBox>

                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="button" fontWeight="bold">
                      Gensim LSI
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mt={0} mb={3}>
                    <SuiTypography component="label" variant="caption">
                      Implements fast truncated SVD (Singular Value Decomposition). The SVD
                      decomposition can be updated with new observations at any time, for an online,
                      incremental, memory-efficient training.
                    </SuiTypography>
                  </SuiBox>

                  {/* <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="button" fontWeight="bold">
                      MALLET LDA
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mt={0} mb={3}>
                    <SuiTypography component="label" variant="caption">
                      This module allows both LDA model estimation from a training corpus and
                      inference of topic distribution on new, unseen documents, using an (optimized
                      version of) collapsed gibbs sampling from MALLET. MALLET is a Java-based
                      package for statistical natural language processing, document classification,
                      clustering, topic modeling, information extraction, and other machine learning
                      applications to text.
                    </SuiTypography>
                  </SuiBox> */}
                </SuiBox>

                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <Link
                    to={`/myslr/querystring-page/${querystring_id}/1`}
                    className="decoration-none"
                  >
                    <SuiBox mr={1}>
                      <SuiButton buttonColor="light">cancel</SuiButton>
                    </SuiBox>
                  </Link>
                  <div onClick={handlePost}>
                    <SuiButton variant="gradient" buttonColor="info">
                      save
                    </SuiButton>
                  </div>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewRunset;
