/* eslint-disable */
import { useState, useEffect, useCallback } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

import { URL_QUERYSTRINGS, URL_DATASETS } from "api/myslr";
import axios from "api/index";
import { useAuth } from "auth-context/auth.context";
// Wordcloud
import WordCloud from "react-d3-cloud";
import DataTable from "../DataTable";

// sweetalert2 components
import Swal from "sweetalert2";

/* const wordCloudData = [
  { id: 1016, text: "studies", value: 1485, font: "serif", style: "normal" },
  { id: 1017, text: "review", value: 1271, font: "serif", style: "normal" },
  { id: 1018, text: "literature", value: 1255, font: "serif", style: "normal" },
  { id: 987, text: "research", value: 1075, font: "serif", style: "normal" },
  { id: 1019, text: "systematic", value: 950, font: "serif", style: "normal" },
  { id: 944, text: "study", value: 721, font: "serif", style: "normal" },
  { id: 1020, text: "patients", value: 719, font: "serif", style: "normal" },
  { id: 1021, text: "results", value: 711, font: "serif", style: "normal" },
  { id: 941, text: "data", value: 484, font: "serif", style: "normal" },
]; */

function Topics({ topics, selectTopic, runset_log }) {
  // var logdata = require(`https://my.danielerogano.it/static/runset/${runset_id}_lda.json`);
  var logdata = runset_log;
  const { user } = useAuth();
  const { borderWidth } = borders;
  const [error, setError] = useState();
  const [fTopics, setFTopics] = useState(null);
  const [maxSize, setMaxSize] = useState(1);
  const [selected, setSelected] = useState(null);
  const [topicNum, setTopicNum] = useState(null);
  const fontSize = useCallback((word) => (word.value / maxSize) * 100, [maxSize]);

  const [tableData, setTableData] = useState({
    columns: [
      { Header: "term", accessor: "text", width: "50%" },
      { Header: "score", accessor: "value", width: "50%", isSorted: true, isSortedDesc: true },
    ],
    rows: [],
  });

  const [fLogData, setFLogData] = useState(null);

  const [tableData2, setTableData2] = useState({
    columns: [
      // { Header: "topic", accessor: "topic", width: "50%" },
      { Header: "term", accessor: "term", width: "20%" },
      /*       {
        Header: "frequency",
        accessor: "frequency",
        width: "10%",
        isSorted: true,
        isSortedDesc: true,
      }, */
      {
        Header: "frequency",
        accessor: "freq",
        width: "10%",
      },
      {
        Header: "total",
        accessor: "total",
        width: "20%",
      },
      {
        Header: "loglift",
        accessor: "loglift",
        width: "20%",
      },
      {
        Header: "logprob",
        accessor: "logprob",
        width: "20%",
      },
    ],
    rows: [],
  });

  useEffect(() => {
    if (runset_log !== null) {
      let tinfodata = [];

      if (runset_log["tinfo"]) {
        for (var key in runset_log["tinfo"].Term) {
          var _data = {
            term: runset_log["tinfo"].Term[key],
            freq: runset_log["tinfo"].Freq[key],
            total: runset_log["tinfo"].Total[key],
            loglift: runset_log["tinfo"].loglift[key],
            logprob: runset_log["tinfo"].logprob[key],
          };
          tinfodata.push(_data);
        }
        // console.log("INFODATA: ", tinfodata);
      }

      let fdata = [];

      if (runset_log["token.table"]) {
        for (var key in runset_log["token.table"].Term) {
          var data = {
            topic: runset_log["token.table"].Topic[key],
            term: runset_log["token.table"].Term[key].replace(/_+/g, " "),
            frequency: runset_log["token.table"].Freq[key],
            freq: tinfodata
              .find((item) => item.term === runset_log["token.table"].Term[key])
              .freq.toFixed(2),
            total: tinfodata
              .find((item) => item.term === runset_log["token.table"].Term[key])
              .total.toFixed(2),
            loglift: tinfodata
              .find((item) => item.term === runset_log["token.table"].Term[key])
              .loglift.toFixed(5),
            logprob: tinfodata
              .find((item) => item.term === runset_log["token.table"].Term[key])
              .logprob.toFixed(5),
          };
          fdata.push(data);
        }
      }
      // console.log("ALL: ", runset_log);
      setFLogData(fdata);
      // console.log(fdata);
    }
  }, [fTopics, runset_log]);

  useEffect(() => {
    let sTopics = [];
    topics.map((t) => {
      let wdata = [];
      for (var key in t.topic_text) {
        var data = {
          text: key,
          value: t.topic_text[key],
        };
        wdata.push(data);
      }
      t.words = wdata;
      setMaxSize(Math.max(...wdata.map((b) => b.value)));
      sTopics.push(t);
    });
    setFTopics(sTopics);
  }, [topics]);

  useEffect(() => {
    if (selected !== null && fLogData) {
      let tmp = fTopics.find((item) => item.id === selected);
      setTableData((prevState) => ({
        ...prevState,
        rows: tmp.words.sort((a, b) => b.value - a.value),
      }));
      let tmp2 = fLogData.filter((item) => item.topic === topicNum);
      // console.log("FILTERED: ", tmp2);
      setTableData2((prevState) => ({
        ...prevState,
        // rows: tmp2,
        rows: tmp2.sort((a, b) => b.frequency - a.frequency),
      }));
    }
  }, [selected, topicNum]);

  const renderSingleTopic = (sel) => {
    let t = fTopics.find((item) => item.id === sel);
    return (
      <Grid key={t.id} item xs={6} sm={6} md={6} lg={6}>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (selected == t.id) {
              setSelected(null);
              selectTopic(null);
            } else {
              setSelected(t.id);
              selectTopic(t.id);
            }
          }}
        >
          <SuiBox
            mt={0.75}
            textAlign="center"
            lineHeight={1}
            borderRadius="10%"
            customClass="cursor-pointer"
          >
            <WordCloud data={t.words} fontSize={fontSize} rotate={0} padding={1} />
          </SuiBox>
        </div>
      </Grid>
    );
  };

  /* const renderTopics = fTopics
    ? fTopics.map((t) => (
        <Grid key={t.id} item xs={3} sm={3} md={3} lg={3}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (selected == t.id) {
                setSelected(null);
                selectTopic(null);
              } else {
                setSelected(t.id);
                selectTopic(t.id);
              }
            }}
          >
            <SuiBox
              mt={0.75}
              textAlign="center"
              lineHeight={1}
              borderRadius="10%"
              border={
                selected === t.id
                  ? `${borderWidth[4]} solid ${colors["info"].main}`
                  : `${borderWidth[1]} solid ${colors["warning"].main}`
              }
              customClass="cursor-pointer"
            >
              <WordCloud data={t.words} fontSize={fontSize} rotate={0} padding={1} />
            </SuiBox>
          </div>
          <SuiBox mt={0.75} textAlign="center" lineHeight={1}>
            <SuiTypography variant="button" textColor="text" fontWeight="regular">
              Topic {t.topic_num} ({t.dominant_topic_papers})
            </SuiTypography>
          </SuiBox>
        </Grid>
      ))
    : null; */

  const renderTopics = fTopics
    ? fTopics.map((t) => (
        <Grid key={t.id} item xs={3} sm={3} md={3} lg={3}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              // console.log("TABLE:", fLogData);
              if (selected == t.id) {
                setSelected(null);
                selectTopic(null);
                setTopicNum(null);
              } else {
                setSelected(t.id);
                selectTopic(t.id);
                setTopicNum(t.topic_num);
              }
            }}
          >
            <SuiBox
              mt={0.75}
              textAlign="center"
              lineHeight={1}
              // borderRadius="10%"
              border={
                selected === t.id
                  ? `${borderWidth[3]} solid ${colors["info"].main}`
                  : `${borderWidth[1]} solid ${colors["warning"].main}`
              }
              customClass="cursor-pointer"
            >
              <SuiTypography variant="h2" textColor="text" fontWeight="regular">
                Topic {t.topic_num}
              </SuiTypography>
              <SuiBox mt={0.75} textAlign="center" lineHeight={1}>
                <SuiTypography variant="button" textColor="text" fontWeight="regular">
                  {t.dominant_topic_papers} papers
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </div>
        </Grid>
      ))
    : null;

  return (
    <Card>
      <SuiBox width="100%" pt={1} pb={1} px={2}>
        <Grid container justifyContent="flex-start" wrap="nowrap" spacing={2}>
          {fTopics && renderTopics}
        </Grid>
      </SuiBox>
      {selected && (
        <SuiBox width="100%" pt={1} pb={1} px={2}>
          <Grid container justifyContent="flex-start" wrap="nowrap" spacing={2}>
            {renderSingleTopic(selected)}
            {fLogData ? (
              <DataTable
                table={tableData2}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={true}
              />
            ) : (
              <SuiBox>
                <SuiTypography>
                  Please delete and relaunch Topic Modeling in previous step (2. Paper Analysis)
                </SuiTypography>
              </SuiBox>
            )}
          </Grid>
        </SuiBox>
      )}
    </Card>
  );
}

export default Topics;
