/* eslint-disable */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DefaultPricingCard from "../PricingCards/DefaultPricingCard";

function TeamCards({ members }) {
  const [ammirato, rogano, felicetti, linzalone, corvello] = members;
  return (
    <SuiBox position="relative" zIndex={10} mt={-5} px={{ xs: 1, sm: 0 }}>
      <Grid container spacing={2} justifyContent="left">
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "Salvatore Ammirato" }}
            member={ammirato}
            bio={{
              label:
                "Salvatore Ammirato is Associate Professor at the Department of Mechanical, Energy and Management Engineering at University of Calabria (ITALY), where he teaches courses for undergraduate, postgraduate and PhD students in the field of Business management.",
              long: "After gaining his PhD, he was appointed as External Researcher at the Centre for Research in Transnational Education, Leadership and Performance, University of Canberra (AUSTRALIA) and as Visiting Professor at Tampere University of Technology in Pori (FINLAND). Salvatore was charged as principal investigator of research and industrial projects and member of the steering committees of other international projects of significance addressing organization change and Digitalization. Overall, he is the author of more than 80 papers published in international journals and conference proceedings. He is co-founder and Scientific coordinator of Echopress srl (www.echopress.it), a software development and consultancy company, official spin-off of the University of Calabria. Currently, he is the principal investigator of the project MySLR, (https://myslr.unical.it) a digital platform based on machine learning techniques which support researcher in carrying out systematic literature reviews. The project has been awarded as “best paper” at the IFKAD2021 international conference and awarded the “certificate of merit” at the Knowledge Management and Intellectual Capital Excellence Awards in 2022.",
            }}
            social={{
              academy: "https://scholar.google.it/citations?hl=it&user=O670TNEAAAAJ",
              linkedin: "https://www.linkedin.com/in/salvatore-ammirato-6a84393/",
              school: "https://www.researchgate.net/profile/Salvatore-Ammirato",
              mail: "salvatore.ammirato@unical.it",
              afference: "University of Calabria - ITALY",
              department: "Department of Mechanical, Energy and Management Engineering",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "Daniele Rogano" }}
            member={rogano}
            bio={{
              label:
                "Daniele Rogano, PhD, is a Research Fellow in the Department of Mechanical, Energy, and Management Engineering (DIMEG) at the University of Calabria (Italy). His current research interests include New Technologies, Data-driven Business and Artificial Intelligence ",
              long: "implementation in various contexts of Management and Industrial Engineering. He is co-founder and IT Manager of SOMOS srl (www.somos.srl), an innovative startup operating in the fields of Transport & Mobility, academic spin-off of the University of Calabria. He is the Senior Full Stack Developer of MySLR Platform (https://myslr.unical.it)",
            }}
            social={{
              academy: "https://scholar.google.it/citations?hl=it&user=tJdnqagAAAAJ",
              linkedin: "https://www.linkedin.com/in/daniele-rogano-9066b75/",
              school: "https://www.researchgate.net/profile/Daniele-Rogano",
              mail: "daniele.rogano@unical.it",
              afference: "University of Calabria - ITALY",
              department: "Department of Mechanical, Energy and Management Engineering",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "Alberto Felicetti" }}
            member={felicetti}
            bio={{
              label:
                "Alberto Michele Felicetti, PhD, is a researcher in the Department of Mechanical, Energy, and Management Engineering (DIMEG) at the University of Calabria (Italy) and visiting senior researcher at Tampere University of Technology, Finland.",
              long: "He teaches undergraduate and graduate courses in Innovation Management at the University of Calabria (Italy). His research interests are in the fields of innovation management, digital entrepreneurship and food information services. He is the author of more than 30 papers, some of them published in international journals like Business Process Management Journal, European Journal of Innovation Management, Knowledge Management Research and Practice, International Journal of Entrepreneurial Behavior and Research.",
            }}
            social={{
              academy: "https://scholar.google.it/citations?user=r1WuYhAAAAAJ&hl=it",
              linkedin: "https://www.linkedin.com/in/alberto-felicetti-2040baa6/",
              school: "https://www.researchgate.net/profile/Alberto-Felicetti",
              mail: "alberto.felicetti@unical.it",
              afference: "University of Calabria - ITALY",
              department: "Department of Mechanical, Energy and Management Engineering",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "Roberto Linzalone" }}
            member={linzalone}
            bio={{
              label:
                "Roberto Linzalone, PhD, is associate research fellow in Management Engineering at the University of Calabria (Italy). His research embraces Complexity Theory and Innovation, with particular focus on Business Model Innovation.",
              long: " He is author of several publications, including book chapters, journal articles, and conference papers.",
            }}
            social={{
              academy: "https://scholar.google.it/citations?hl=it&user=JQ69KfAAAAAJ",
              linkedin: "https://www.linkedin.com/in/roberto-linzalone-7b2572214/",
              school: "https://www.researchgate.net/profile/Roberto-Linzalone/",
              mail: "roberto.linzalone@unical.it",
              afference: "University of Calabria - ITALY",
              department: "Department of Mechanical, Energy and Management Engineering",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "Vincenzo Corvello" }}
            member={corvello}
            bio={{
              label:
                "Vincenzo Corvello is Associate Professor at the Department of Engineering, University of Messina. He holds a PhD in Business and Economic Engineering from the University Federico II of Naples.",
              long: " He teaches Innovation Management, Strategy and Organization and Project Management. His research interests are in the fields of innovation management and organizational theory. His research has been published in international journals. He is co-founder and has been CEO of Beautiful Mind S.r.l., a software development company, official spin-off of the University of Calabria. His research has been supported by national and international research grants. He has guest edited special issues in several international journals. He is the editor in chief of the European Journal of Innovation Management.",
            }}
            social={{
              academy: "https://scholar.google.it/citations?hl=it&user=zAkwsVYAAAAJ",
              linkedin: "https://www.linkedin.com/in/vincenzocorvello/",
              school: "https://www.researchgate.net/profile/Vincenzo-Corvello",
              mail: "vincenzo.corvello@unime.it",
              afference: "University of Messina - ITALY",
              department: "Department of Engineering",
            }}
          />
        </Grid>
      </Grid>
    </SuiBox>
  );
}

// Typechecking props for the PricingCards
TeamCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TeamCards;
