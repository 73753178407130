/* eslint-disable */

import { forwardRef } from "react";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Custom styles for the DefaultItem
import styles from "examples/Items/DefaultItem/styles";

const DefaultItem = forwardRef(({ color, icon, title, description, ...rest }, ref) => {
  const classes = styles({ color });

  return (
    <SuiBox {...rest} ref={ref} display="flex" alignItems="center">
      <SuiBox customClass={classes.defaultItem_iconBox}>
        <Icon className={classes.defaultItem_icon} fontSize="default">
          {icon}
        </Icon>
      </SuiBox>
      <SuiBox ml={2} lineHeight={1}>
        <SuiTypography display="block" variant="button" fontWeight="medium">
          {title}
        </SuiTypography>
        <SuiTypography variant="button" fontWeight="regular" textColor="text">
          {description}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
});

// Setting default values for the props of DefaultItem
DefaultItem.defaultProps = {
  color: "info",
};

export default DefaultItem;
