/* eslint-disable */
import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";

import axios from "api/index";
import { useAuth } from "auth-context/auth.context";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiBadgeDot from "components/SuiBadgeDot";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import DefaultLineChart from "./components/DefaultLineChart";
import ComplexReportsDoughnutChart from "examples/Charts/DoughnutCharts/ComplexReportsDoughnutChart";
import { TailSpin, MutatingDots } from "react-loader-spinner";

import DataTable from "./DataTable";
import ActionCell from "./components/ActionCell";
import StatusCell from "./components/StatusCell";

// sweetalert2 components
import Swal from "sweetalert2";

// Images
import badge from "assets/pics/badge-1.png";

// Data
import optimalkdata from "./data/optimalkdata";
import complexReportsDoughnutChartData from "layouts/applications/analytics/data/complexReportsDoughnutChartData";

import { URL_QUERYSTRINGS, URL_DATASETS } from "api/myslr";
import { ConstructionOutlined } from "@mui/icons-material";
import SuiTypography from "components/SuiTypography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import NoRunsets from "./components/NoRunsets";

function PapersAnalysis({ querystring_id, handleTab, goTo }) {
  const { id } = useParams();
  const { user } = useAuth();
  const [dataset, setDataset] = useState(null);
  const [datasetData, setDatasetData] = useState({
    dataset_querystring: querystring_id,
    excluded_papers: 0,
    optimal_values: "",
    id: 0,
    papers_num: 0,
    total_papers: 0,
    version: 0,
  });
  const [error, setError] = useState(null);

  const [menu, setMenu] = useState(null);

  const [runsetData, setRunsetData] = useState({
    columns: [
      { Header: "algorithm", accessor: "algorithm", width: "10%" },
      { Header: "topics", accessor: "topic_num", width: "5%" },
      { Header: "perplexity", accessor: "perplexity", width: "5%" },
      { Header: "coherence", accessor: "coherence", width: "5%" },
      { Header: "status", accessor: "status", width: "15%" },
      { Header: "created at", accessor: "created_at", width: "20%" },
      { Header: "actions", accessor: "actions", width: "5%" },
    ],
    rows: [],
  });

  const [timerCheck, setTimerCheck] = useState(true);

  const algorithmOptions = {
    gensim_lda: "LDA",
    gensim_lsi: "LSI",
  };

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const getOptimalK = (data) => {
    if (data) {
      let values = data.labels;
      let optimalk = Math.max(...data.datasets[0].data);
      return values[data.datasets[0].data.indexOf(optimalk)];
    } else {
      return "0";
    }
  };

  const handleViewResult = (RsId) => {
    goTo(RsId);
  };

  const handleBadgeShow = () => {
    Swal.fire({
      title: "Good job!",
      text: "You completed the Papers location and selection phase!",
      imageUrl: badge,
      imageWidth: 200,
      imageAlt: "Custom image",
    });
  };

  const handleFetchDefaultDataset = () => {
    axios
      .get(`${URL_QUERYSTRINGS}/${id}`, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        setDataset(response.data.default_dataset);
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const handleFetchDatasetData = () => {
    axios
      .get(`${URL_DATASETS}/${dataset}`, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        setDatasetData(response.data);
        let ref = response.data.runsets.map((v) => ({
          ...v,
          algorithm: (
            <div style={{ cursor: "pointer" }} onClick={() => handleViewResult(v.id)}>
              {algorithmOptions[v.algorithm]}
            </div>
          ),
          topic_num: v.topic_num,
          coherence: v.coherence,
          status: <StatusCell status={v.complete} id={v.id} />,
          created_at: new Date(v.created_at).toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }),
          actions: <ActionCell id={v.id} reload={reload} qs={response.data.dataset_querystring} />,
        }));
        setRunsetData((prevState) => ({
          ...prevState,
          rows: ref,
        }));
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const handleFetchRunsetData = () => {
    axios
      .get(`${URL_DATASETS}/${dataset}`, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        let ref = response.data.runsets.map((v) => ({
          ...v,
          algorithm: (
            <div style={{ cursor: "pointer" }} onClick={() => handleViewResult(v.id)}>
              {algorithmOptions[v.algorithm]}
            </div>
          ),
          topic_num: v.topic_num,
          coherence: v.coherence,
          status: <StatusCell status={v.complete} id={v.id} />,
          actions: <ActionCell id={v.id} reload={reload} qs={response.data.dataset_querystring} />,
        }));
        setRunsetData((prevState) => ({
          ...prevState,
          rows: ref,
        }));
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  useEffect(() => {
    if (dataset !== null) {
      handleFetchDatasetData();
    }
  }, [dataset]);

  const reload = () => {
    handleFetchDatasetData();
  };

  let timer;

  useEffect(() => {
    let check = false;
    runsetData.rows.map((v) => {
      if (!v.complete) {
        check = true;
      }
    });
    if (check) {
      setTimerCheck(true);
    } else {
      setTimerCheck(false);
    }

    if (runsetData.rows[0]) {
      handleTab(true);
    } else {
      handleTab(false);
    }

    if (timerCheck) {
      timer = setTimeout(() => {
        handleFetchRunsetData();
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [runsetData, timerCheck]);

  useEffect(() => {
    handleFetchDefaultDataset();
  }, []);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Yesterday</MenuItem>
      <MenuItem onClick={closeMenu}>Last 7 days</MenuItem>
      <MenuItem onClick={closeMenu}>Last 30 days</MenuItem>
    </Menu>
  );

  return (
    <SuiBox py={3}>
      {datasetData.optimal_values && (
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "dataset version", fontWeight: "medium" }}
                count="V"
                percentage={{
                  color: "success",
                  text: datasetData.version,
                }}
                icon={{ color: "dark", component: "numbers" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "Selected Papers", fontWeight: "medium" }}
                count={datasetData.total_papers - datasetData.excluded_papers}
                percentage={{
                  color: "success",
                  text:
                    (
                      ((datasetData.total_papers - datasetData.excluded_papers) /
                        datasetData.total_papers) *
                      100
                    ).toFixed(1) + "%",
                }}
                icon={{ color: "dark", component: "article" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "best topic number", fontWeight: "medium" }}
                count={getOptimalK(datasetData.optimal_values)}
                //percentage={{ color: "success", text: "-2%" }}
                icon={{ color: "dark", component: "settings_suggest" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "Starting Algorithm", fontWeight: "medium" }}
                count="Gensim"
                percentage={{ color: "success", text: "LDA" }}
                icon={{ color: "dark", component: "functions" }}
              />
            </Grid>
          </Grid>
        </SuiBox>
      )}
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          {datasetData.optimal_values ? (
            <>
              <Grid item xs={12} lg={5}>
                <DefaultLineChart
                  title="Coherence values"
                  description={
                    <SuiBox display="flex" ml={-1}>
                      <SuiBadgeDot color="info" badgeContent="Gensim LDA" />
                      <SuiBadgeDot color="success" badgeContent="LSI" />
                    </SuiBox>
                  }
                  chart={datasetData.optimal_values}
                />
              </Grid>
              <Grid item xs={12} lg={7}>
                <Card>
                  <SuiBox mb={2}>
                    <SuiTypography variant="h6" textAlign="center" padding={2}>
                      Topic Modeling Config sets
                    </SuiTypography>
                  </SuiBox>
                  {runsetData.rows[0] ? (
                    <DataTable
                      table={runsetData}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      isSorted={false}
                    />
                  ) : (
                    <NoRunsets />
                  )}
                  <Stack padding={2} direction="column" alignItems="center">
                    {true ? (
                      <Link
                        to={`/myslr/new-runset/${datasetData.id}/${datasetData.dataset_querystring}`}
                        className="decoration-none"
                      >
                        <SuiButton variant="gradient" buttonColor="info" size="small">
                          + new analysis
                        </SuiButton>
                      </Link>
                    ) : null}
                  </Stack>
                </Card>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Card className="overflow-visible">
                <CardContent>
                  <SuiTypography variant="h4" align="center" fontWeight="bold" gutterBottom>
                    We are calculating optimal number of topics for your dataset. You will receive
                    an email when the process is completed.
                  </SuiTypography>
                </CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: 10,
                  }}
                >
                  <MutatingDots height={150} width={150} />
                </div>
              </Card>
            </Grid>
          )}

          <Grid item xs={12} lg={5}>
            {/* <ComplexReportsDoughnutChart
              title="Referrals"
              chart={complexReportsDoughnutChartData}
              tooltip="See which websites are sending traffic to your website"
              action={{
                type: "internal",
                route: "/",
                color: "secondary",
                label: "see all referrals",
              }}
            /> */}
          </Grid>
        </Grid>
      </SuiBox>
      {/* <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Social />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Pages />
        </Grid>
      </Grid> */}
    </SuiBox>
  );
}

export default PapersAnalysis;
