/* eslint-disable */
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Box from "@mui/material/Box";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "./components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import workflow from "assets/images/workflow.png";

import { Divider } from "@mui/material";

function Landing() {
  return (
    <DashboardLayout>
      <Header />
      <div id="why">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                Literature Reviews are established research method able to advance knowledge in a
                scientific domain by organising and reviewing the existing literature. Existing
                knowledge, in fact, acts as foundation for research and for new knowledge creation,
                and new research must build logically and coherently on the existing knowledge.
                Literature reviews, then, are critical for scientific knowledge creation.
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={2}>
              <SuiTypography display="block" variant="body" fontWeight="regular">
                Several types of literature reviews exist, different from each other depending on
                the purpose of the review. Considering the high volume of scientific production, the
                emergence of interdisciplinary approaches and the high fragmentation of research
                contributions, a relevant research method to review and synthesise the available
                knowledge is the Systematic Literature Review, SLR in brief. Systematic methods
                offer the possibility to provide a clear picture of a knowledge domain and, because
                of the objective and repeatable method used, allow other scholars to confirm,
                challenge or expand the findings. SLRs represent a consistent and robust approach to
                understand extant research and to provide ideas and directions to undertake novel
                studies. "A comprehensive, unbiased search is one of the fundamental differences
                between a traditional narrative review and a systematic review."(Tranfield et al.,
                2003)
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={2}>
              <SuiTypography variant="body" fontWeight="regular">
                The key point of Systematic Literature Reviews is that all the sources matching a
                set of objectives (i.e., inclusion/exclusion criteria) must be included in the
                source list. In other types of review, instead, the researcher is free to consider a
                sample of all the available sources, and criteria of inclusion are subjective. This
                bias affects the research process and results.
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={2}>
              <SuiTypography display="block" variant="body" fontWeight="regular" fontStyle="italic">
                The previous text is extracted and edited from:
              </SuiTypography>
            </SuiBox>
            <Grid container item xs={12} lg={11} mb={2} className="mx-auto">
              <SuiTypography variant="body" fontWeight="regular">
                <ul>
                  <li>
                    S. Ammirato, A.M. Felicetti, D. Rogano, R. Linzalone, V. Corvello (2022) –
                    “Digitalizing the Systematic Literature Review process: the MySLR platform”,
                    Knowledge Management Research & Practice, DOI: 10.1080/14778238.2022.2041375;
                    Vol.: ahead-of-print, n°: ahead-of-print
                  </li>
                </ul>
              </SuiTypography>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </DashboardLayout>
  );
}

export default Landing;
