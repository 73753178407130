import axios from "./index";

const base = "users";
const none = "";

export const Login = (data) => axios.post(`${base}/login`, data);

export const Register = (data) => axios.post(`${base}/register`, data);

export const Recovery = (data) => axios.post(`${none}/password_reset/`, data);

export const ChangePassword = (data) => axios.post(`${none}/password_reset/confirm/`, data);

export const Logout = (data) =>
  axios.post(`${base}/logout`, data, { headers: { Authorization: `${data.token}` } });
