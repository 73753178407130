/* eslint-disable */
import { useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiDatePicker from "components/SuiDatePicker";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { URL_PROJECTS } from "../../../api/myslr";
import axios from "../../../api/index";
import { useAuth } from "../../../auth-context/auth.context";

// sweetalert2 components
import Swal from "sweetalert2";

function NewProject() {
  const { user } = useAuth();
  const history = useHistory();
  const [projectName, setProjectName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [projectDescription, setProjectDescription] = useState("");
  const [projectStatus, setProjectStatus] = useState("Started");

  const [error, setError] = useState(null);

  const handleSetStartDate = (newDate) => setStartDate(newDate);

  const handlePost = () => {
    let postData = new Object();
    if (projectName.length == 0) {
      setError("Please insert a name for the project");
    }
    postData.project_name = projectName.replace(/(<([^>]+)>)/gi, "");
    postData.project_description =
      projectDescription.length > 0
        ? projectDescription.replace(/(<([^>]+)>)/gi, "")
        : "No description";
    postData.project_status = projectStatus.value;
    postData.created_at = startDate;
    // postData.owner = user._id;
    var jsonString = JSON.stringify(postData);
    axios
      .post(URL_PROJECTS, postData, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        if (response.status == 201) {
          Swal.fire("Success!", "Project created", "success");
          history.push("/myslr/projects-list");
        } else {
          Swal.fire("Error!", "We can't create this project, check your data again", "error");
        }
      })
      .catch((_error) => {
        // setError(_error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  New Project
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Create new project
                </SuiTypography>
                <Divider />
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Project Name
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor
                    placeholder="Project name...."
                    value={projectName}
                    onChange={setProjectName}
                  />
                </SuiBox>
                <SuiBox mt={3} mb={2}></SuiBox>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Project Description
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      This is what others team members will read about the project, so make it good!
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor
                    placeholder="Project description...."
                    value={projectDescription}
                    onChange={setProjectDescription}
                  />
                </SuiBox>

                {/* <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <SuiBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Creation date
                        </SuiTypography>
                      </SuiBox>
                      <SuiDatePicker value={startDate} onChange={handleSetStartDate} />
                    </SuiBox>
                  </Grid>
                  <Grid item xs={6}>
                    <SuiBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Project Status
                        </SuiTypography>
                      </SuiBox>
                      <SuiSelect
                        defaultValue={[{ value: "started", label: "Started" }]}
                        options={[
                          { value: "started", label: "Started" },
                          { value: "in progress", label: "In progress" },
                          { value: "complete", label: "Complete" },
                        ]}
                        value={projectStatus}
                        onChange={setProjectStatus}
                      />
                    </SuiBox>
                  </Grid>
                </Grid> */}
                <h6
                  style={{
                    fontSize: ".7em",
                    color: "red",
                    textAlign: "center",
                    fontWeight: 400,
                    transition: ".2s all",
                  }}
                >
                  {error}
                </h6>

                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <Link to="/myslr/projects-list" className="decoration-none">
                    <SuiBox mr={1}>
                      <SuiButton buttonColor="light">cancel</SuiButton>
                    </SuiBox>
                  </Link>
                  <div onClick={handlePost}>
                    <SuiButton variant="gradient" buttonColor="info">
                      create project
                    </SuiButton>
                  </div>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewProject;
