/* eslint-disable */
import { useEffect, useState, useRef } from "react";

// react-router-dom components
import { Link, useHistory, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiDatePicker from "components/SuiDatePicker";
import SuiEditor from "./SuiEditor";
import SuiButton from "components/SuiButton";
import SourceCard from "../SourceCard";

import { URL_SOURCEFILES } from "api/myslr";
import axios from "api/index";
import { useAuth } from "auth-context/auth.context";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";

// sweetalert2 components
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SuiDropzone from "./SuiDropzone";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import { TailSpin } from "react-loader-spinner";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import pubmedTutorial from "assets/tutorial/pubmed_import.mp4";
// Images
import filePreview from "assets/pics/txt-file.png";

function OtherSourcesBox({ querystring_id, reload, isQueryOwner }) {
  const { borderWidth, borderColor } = borders;
  const { user } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isHowtoOpened, setIsHowtoOpened] = useState(false);
  const [sourcefileData, setSourcefileData] = useState(null);
  const [fileData, setFileData] = useState({
    filename: "",
    filesize: 0,
  });
  const [newFileData, setNewFileData] = useState({
    sourcefile_querystring: querystring_id,
    sourcefile: null,
    sourcefile_db: "pubmed",
    sourcefile_query_used: "",
  });
  const [error, setError] = useState(null);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleTutorialOpen = () => {
    setIsHowtoOpened(true);
  };

  Modal.setAppElement("body");

  const customStyles = {
    content: {
      backgroundColor: "white",
      zIndex: "10",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleSave = () => {
    setOpen(false);
    setIsLoading(true);
    var formData = new FormData();
    formData.append("sourcefile", newFileData.sourcefile);
    formData.append("sourcefile_querystring", newFileData.sourcefile_querystring);
    formData.append("sourcefile_db", newFileData.sourcefile_db);
    if (newFileData.sourcefile_query_used !== "") {
      formData.append(
        "sourcefile_query_used",
        newFileData.sourcefile_query_used.replace(/(<([^>]+)>)/gi, "")
      );
    } else {
      formData.append("sourcefile_query_used", "None");
    }
    axios
      .post(URL_SOURCEFILES, formData, {
        headers: { Authorization: `${user.token}`, "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        handleFetch();
        setIsLoading(false);
      })
      .catch((_error) => {
        setIsLoading(false);
        setError(_error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "Pubmed dataset will be lost. You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          axios
            .delete(`${URL_SOURCEFILES}/${sourcefileData.id}`, {
              headers: { Authorization: `${user.token}` },
            })
            .then((response) => {
              if (response.status == 204) {
                handleFetch();
              } else {
                newSwal.fire("Error!", "We can't delete your project", "error");
              }
            })
            .catch((_error) => {
              setError(_error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          newSwal.fire("Cancelled", "File safe!", "error");
        }
      });
  };

  const handleFetch = () => {
    axios
      .get(`${URL_SOURCEFILES}?sourcefile_querystring=${querystring_id}&sourcefile_db=pubmed`, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        setSourcefileData(response.data[0]);
        setIsLoading(false);
        reload();
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  useEffect(() => {}, [newFileData]);

  const handleQueryChange = (event) => {
    setNewFileData((prevState) => ({
      ...prevState,
      sourcefile_query_used: event,
    }));
  };

  const Uploader = () => (
    <SuiDropzone
      options={{
        addRemoveLinks: true,
        uploadMultiple: false,
        init: function () {
          this.on("addedfile", (file) => {
            setNewFileData((prevState) => ({
              ...prevState,
              sourcefile: file,
            }));
          });
          this.on("complete", (file) => {
            setFileData((prevState) => ({
              filename: file.name,
              filesize: (file.size / 1000000).toFixed(2),
            }));
          });
        },
      }}
    />
  );

  const Preview = () => (
    <SuiBox mt={2}>
      <SuiBox mb={1} lineHeight={0}>
        <SuiBox component="img" src={filePreview} alt="File preview" width={64} mb={3} />
        <SuiTypography component="label" variant="caption" fontWeight="bold">
          {fileData.filename}&nbsp;&nbsp;
          <SuiTypography variant="caption" fontWeight="regular" textColor="text">
            ({fileData.filesize} MB)
          </SuiTypography>
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );

  return (
    <SuiBox>
      <Grid container spacing={3} justifyContent="center" direction="column" alignItems="center">
        <Grid item xs={12}>
          <Divider />
          {isLoading ? (
            <TailSpin />
          ) : sourcefileData ? (
            <Grid item xs={12}>
              <SourceCard data={sourcefileData} handleDelete={handleDelete} />
            </Grid>
          ) : isQueryOwner ? (
            <SuiBox
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="center"
              borderRadius="xl"
              boxShadow="xxl"
              border={`${borderWidth[1]} dashed ${borderColor}`}
              textAlign="center"
              p={3}
            >
              <SuiTypography>
                Please import a MEDLINE PubMed .txt file following the template format.
              </SuiTypography>
              <Grid item xs={12}>
                <div onClick={handleClickOpen}>
                  <SuiBox
                    backgroundColor="info"
                    borderRadius="50%"
                    width="3.625rem"
                    height="3.625rem"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                    mx="auto"
                    backgroundGradient
                    customClass="cursor-pointer"
                  >
                    <Icon className="" fontSize="small">
                      add
                    </Icon>
                  </SuiBox>
                </div>
                <div onClick={handleTutorialOpen}>
                  <SuiBox
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="left"
                    textAlign="left"
                    p={1}
                  >
                    <SuiButton buttonColor="success" size="small">
                      How to export a research file from MEDLINE PubMed
                    </SuiButton>
                  </SuiBox>
                </div>
                <Dialog open={open} onClose={handleClose} maxWidth={false}>
                  <DialogTitle>Add new file</DialogTitle>
                  <DialogContent>
                    <SuiBox>
                      <SuiBox mt={3}>
                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SuiTypography component="label" variant="caption" fontWeight="bold">
                            File
                          </SuiTypography>
                        </SuiBox>
                        {newFileData.sourcefile ? <Preview /> : <Uploader />}
                      </SuiBox>
                      <SuiBox mt={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SuiTypography component="label" variant="caption" fontWeight="bold">
                                Used query&nbsp;&nbsp;
                                <SuiTypography
                                  variant="caption"
                                  fontWeight="regular"
                                  textColor="text"
                                >
                                  (optional)
                                </SuiTypography>
                              </SuiTypography>
                            </SuiBox>
                            <SuiEditor
                              value={newFileData.sourcefile_query_used}
                              placeholder="Insert used query..."
                              onChange={handleQueryChange}
                            />
                          </Grid>
                        </Grid>
                      </SuiBox>
                    </SuiBox>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </SuiBox>
          ) : (
            <SuiBox
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="center"
              borderRadius="xl"
              boxShadow="xxl"
              border={`${borderWidth[1]} dashed ${borderColor}`}
              textAlign="center"
              p={3}
            >
              <SuiTypography>No files were uploaded for Web of Science</SuiTypography>
            </SuiBox>
          )}
        </Grid>
      </Grid>
      <Modal
        isOpen={isHowtoOpened}
        onRequestClose={() => setIsHowtoOpened(false)}
        style={customStyles}
      >
        <div>
          <ReactPlayer url={pubmedTutorial} playing={true} controls={true} loop={true} />
        </div>
      </Modal>
    </SuiBox>
  );
}

export default OtherSourcesBox;
