/* eslint-disable */
import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useHistory, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiDatePicker from "components/SuiDatePicker";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { URL_QUERYSTRINGS } from "../../../api/myslr";
import axios from "../../../api/index";
import { useAuth } from "../../../auth-context/auth.context";

// sweetalert2 components
import Swal from "sweetalert2";

function NewQuerystring() {
  const { user } = useAuth();
  const history = useHistory();
  const { project_id } = useParams();
  const [querystringName, setQuerystringName] = useState("");
  const [querystringDescription, setQuerystringDescription] = useState("");

  const [error, setError] = useState(null);

  const handlePost = () => {
    let postData = new Object();
    if (querystringName.length == 0) {
      setError("Please insert a name for the querystring");
    }
    postData.querystring_name = querystringName.replace(/(<([^>]+)>)/gi, "");
    postData.querystring_description =
      querystringDescription.length > 0
        ? querystringDescription.replace(/[^\w\s]/gi, "")
        : "No description";
    postData.querystring_project = project_id;
    // postData.owner = user._id;
    axios
      .post(URL_QUERYSTRINGS, postData, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        if (response.status == 201) {
          Swal.fire("Success!", "Querystring created", "success");
          history.replace(`/myslr/project-page/${project_id}`);
        } else {
          Swal.fire("Error!", "We can't create this project, check your data again", "error");
        }
      })
      .catch((_error) => {
        // setError(_error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  New Query Research
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Create a new research query
                </SuiTypography>
                <Divider />
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Research Query Name
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    placeholder="Name...."
                    value={querystringName}
                    onChange={(event) => {
                      setQuerystringName(event.target.value);
                    }}
                  />
                </SuiBox>
                <SuiBox mt={3} mb={2}></SuiBox>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Research Query Description
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    ></SuiTypography>
                  </SuiBox>
                  <SuiInput
                    placeholder="Description...."
                    value={querystringDescription}
                    onChange={(event) => {
                      setQuerystringDescription(event.target.value);
                    }}
                  />
                </SuiBox>
                <SuiBox mt={2} mb={2} textAlign="center">
                  <h6
                    style={{
                      fontSize: ".7em",
                      color: "red",
                      textAlign: "center",
                      fontWeight: 400,
                      transition: ".2s all",
                    }}
                  >
                    {error}
                  </h6>
                </SuiBox>
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <Link to="/myslr/projects-list" className="decoration-none">
                    <SuiBox mr={1}>
                      <SuiButton buttonColor="light">cancel</SuiButton>
                    </SuiBox>
                  </Link>
                  <div onClick={handlePost}>
                    <SuiButton variant="gradient" buttonColor="info">
                      save
                    </SuiButton>
                  </div>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewQuerystring;
