/* eslint-disable */
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import SignUpCover from "layouts/authentication/sign-up/cover";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import ResetCover from "layouts/authentication/reset-password/cover";
import ChangePassword from "layouts/authentication/reset-password/basic";

import Error403 from "layouts/authentication/error/403";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";

import ProjectPage from "layouts/myslr/project-page";
import EditProject from "layouts/myslr/edit-project";
import QuerystringPage from "layouts/myslr/querystring-page";
import EditQuerystring from "layouts/myslr/edit-querystring";
import NewQuerystring from "layouts/myslr/new-querystring";
import NewRunset from "layouts/myslr/new-runset";

const privateRoutes = [
  {
    name: "Basic",
    key: "basic",
    route: "/authentication/sign-in/basic",
    component: SignInBasic,
  },
  {
    name: "Cover",
    key: "cover",
    route: "/authentication/sign-in/cover",
    component: SignInCover,
  },
  {
    name: "Illustration",
    key: "illustration",
    route: "/authentication/sign-in/illustration",
    component: SignInIllustration,
  },
  {
    name: "Basic",
    key: "basic",
    route: "/authentication/sign-up/basic",
    component: SignUpBasic,
  },
  {
    name: "Cover",
    key: "cover",
    route: "/authentication/sign-up/cover",
    component: SignUpCover,
  },
  {
    name: "Illustration",
    key: "illustration",
    route: "/authentication/sign-up/illustration",
    component: SignUpIllustration,
  },
  {
    name: "Cover",
    key: "cover",
    route: "/authentication/reset-password/cover",
    component: ResetCover,
  },
  {
    name: "Change",
    key: "cover",
    route: "/authentication/reset-password/confirm",
    component: ChangePassword,
  },

  {
    name: "Project page",
    key: "project",
    route: "/myslr/project-page/:id",
    component: ProjectPage,
    protected: true,
  },
  {
    name: "Edit Project",
    key: "project",
    route: "/myslr/edit-project/:id",
    component: EditProject,
    protected: true,
  },
  {
    name: "Querystring page",
    key: "querystring",
    route: "/myslr/querystring-page/:id",
    component: QuerystringPage,
    protected: true,
  },
  {
    name: "Querystring analysis",
    key: "querystring",
    route: "/myslr/querystring-page/:id/:tab",
    component: QuerystringPage,
    protected: true,
  },
  {
    name: "Edit Querystring",
    key: "querystring",
    route: "/myslr/edit-querystring/:id",
    component: EditQuerystring,
    protected: true,
  },
  {
    name: "New Querystring",
    key: "new-querystring",
    route: "/myslr/new-querystring/:project_id",
    component: NewQuerystring,
    protected: true,
  },
  {
    name: "New Runset",
    key: "new-runset",
    route: "/myslr/new-runset/:dataset_id/:querystring_id",
    component: NewRunset,
    protected: true,
  },
  {
    name: "Error 403",
    key: "403",
    route: "/authentication/error/403",
    component: Error403,
  },
  {
    name: "Error 404",
    key: "404",
    route: "/authentication/error/404",
    component: Error404,
  },
  {
    name: "Error 500",
    key: "500",
    route: "/authentication/error/500",
    component: Error500,
  },
];

export default privateRoutes;
