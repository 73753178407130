/* eslint-disable no-console */
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

import { Divider } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved11 from "assets/images/curved-images/curved11.jpg";
import SuiEditor from "../SuiEditor";
import { Register } from "../../../../api/auth";

function Cover() {
  const history = useHistory();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [reason, setReason] = useState("");
  const [buttonText, setButtonText] = useState("Sign up");
  const [error, setError] = useState(undefined);

  const [agreement, setAgreement] = useState(false);

  const handleSetAgremment = () => setAgreement(!agreement);

  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (name === "") {
      return setError("You must enter your name.");
    }
    if (email === "") {
      return setError("You must enter your email.");
    }
    if (password === "") {
      return setError("You must enter a password.");
    }
    if (reason === "") {
      return setError("You must enter a brief description of the research goals.");
    }
    if (affiliation === "") {
      return setError("You must enter affiliation data");
    }
    if (agreement === false) {
      return setError("You must agree to the terms and conditions before you can submit data");
    }
    try {
      setButtonText("Signing up");
      const response = await Register({
        username: name,
        affiliation,
        reason,
        email,
        password,
      });
      if (response.data && response.data.success === false) {
        setButtonText("Sign up");
        return setError(response.data.msg);
      }
      return history.push("/authentication/sign-in/cover");
    } catch (err) {
      setButtonText("Sign up");
      if (err.response) {
        return setError(err.response.data.msg);
      }
      return setError("There has been an error.");
    }
  };

  return (
    <CoverLayout
      title="MySLR"
      description="Enter your name, email and password to register."
      image={curved11}
      top={12}
    >
      <SuiBox component="form" role="form">
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Name
            </SuiTypography>
          </SuiBox>
          <SuiInput
            onChange={(event) => {
              setName(event.target.value);
              setError(undefined);
            }}
            placeholder="Name"
          />
        </SuiBox>
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SuiTypography>
          </SuiBox>
          <SuiInput
            onChange={(event) => {
              setEmail(event.target.value);
              setError(undefined);
            }}
            type="email"
            placeholder="Email"
          />
        </SuiBox>
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SuiTypography>
          </SuiBox>
          <SuiInput
            onChange={(event) => {
              setPassword(event.target.value);
              setError(undefined);
            }}
            type="password"
            placeholder="Password"
          />
        </SuiBox>
        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Affiliation
            </SuiTypography>
          </SuiBox>
          <SuiInput
            onChange={(event) => {
              setAffiliation(event.target.value);
              setError(undefined);
            }}
            placeholder="University, Company, ecc."
          />
        </SuiBox>

        <SuiBox mb={2} lineHeight={1.25}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Reason for joining
            </SuiTypography>
          </SuiBox>
          <SuiEditor onChange={setReason} placeholder="Brief description of the research goals" />
        </SuiBox>

        <>
          <Divider />
          <Box justifyContent="center">
            <SuiTypography variant="caption" fontWeight="bold">
              TERMS AND CONDITIONS
            </SuiTypography>
          </Box>
          <SuiBox>
            <SuiTypography variant="body1" fontSize={14}>
              MySLR is a proprietary platform that can be used freely. We ask each user:
            </SuiTypography>
          </SuiBox>
          <SuiBox pl={2}>
            <ul>
              <li>
                <SuiTypography variant="body1" fontSize={14}>
                  To register a new account on Myslr platform by specifying the affiliation and the
                  research goals.
                </SuiTypography>
              </li>
            </ul>
          </SuiBox>
          <SuiBox mb={2} pl={2}>
            <ul>
              <li>
                <SuiTypography variant="body1" fontSize={14}>
                  To mention explicitly the use of MySLR when publishing results, using the
                  following reference:
                </SuiTypography>
                <SuiTypography variant="body1" fontSize={14} fontStyle="italic">
                  S. Ammirato, A.M. Felicetti, D. Rogano, R. Linzalone, V. Corvello (2022) –
                  “Digitalizing the Systematic Literature Review process: the MySLR platform”,
                  Knowledge Management Research & Practice, DOI: 10.1080/14778238.2022.2041375;
                  Vol.: ahead-of-print, n°: ahead-of-print
                </SuiTypography>
              </li>
            </ul>
          </SuiBox>
          <Divider />
          <SuiTypography variant="caption" fontWeight="bold">
            DISCLAIMER
          </SuiTypography>
          <SuiBox>
            <SuiTypography variant="body1" fontSize={12} fontStyle="italic">
              MySLR is provided free of charge and "AS IS" without any warranty of any kind. The
              implied warranties of merchantability, fitness for a particular purpose and
              non-infringment are expressly disclaimed. In no event will the authors (Salvatore
              Ammirato and Daniele Rogano) or his employer (University of Calabria) be liable to any
              party for any damages for any use of the platform.
            </SuiTypography>
          </SuiBox>
        </>

        <SuiBox display="flex" alignItems="center">
          <Checkbox checked={agreement} onChange={handleSetAgremment} />
          <SuiTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetAgremment}
            customClass="cursor-pointer user-select-none"
          >
            &nbsp;&nbsp;I agree the&nbsp;{" "}
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="bold" textGradient>
            Terms and Conditions
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={2} mb={2} textAlign="center">
          <h6
            style={{
              fontSize: ".7em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            {error}
          </h6>
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton onClick={register} variant="gradient" buttonColor="info" fullWidth>
            {buttonText}
          </SuiButton>
        </SuiBox>
        <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" textColor="text" fontWeight="regular">
            Already have an account?&nbsp;
            <SuiTypography
              component={Link}
              to="/authentication/sign-in/cover"
              variant="button"
              textColor="info"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}

export default Cover;
