/* eslint-disable */
import { useState } from "react";
import { useHistory } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import { URL_DATASETS } from "api/myslr";
import axios from "api/index";
import { useAuth } from "auth-context/auth.context";

// sweetalert2 components
import Swal from "sweetalert2";

function ActionCell({ id, text, dataset, reload }) {
  const { user } = useAuth();
  // const history = useHistory();
  const history = createHistory();
  const [error, setError] = useState(null);

  const handleExclude = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Keyword exclusion",
        text: "The keyword will be excluded from corpus for further analysis",
        showCancelButton: true,
        confirmButtonText: "Yes, exclude it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          let data = { id: id, text: text };

          axios
            .post(`${URL_DATASETS}/${dataset}/exclude_keyword`, data, {
              headers: { Authorization: `${user.token}` },
            })
            .then((response) => {
              if (response.status == 200) {
                // newSwal.fire("Success!", "Keyword excluded", "success");
                reload();
              } else {
                newSwal.fire("Error!", "We can't exclude this keyword", "error");
              }
            })
            .catch((_error) => {
              setError(_error);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          newSwal.fire("Cancel", "Keyword safe!", "error");
        }
      });
  };

  const handleView = () => {
    <Link to="/myslr/querystring-page" className="decoration-none"></Link>;
  };

  return (
    <SuiBox display="flex" alignItems="center">
      <div key={id} onClick={handleExclude}>
        <SuiTypography
          variant="body1"
          textColor="secondary"
          customClass="cursor-pointer line-height-0"
        >
          <Tooltip title="Exclude keyword" placement="left">
            <Icon className="">delete</Icon>
          </Tooltip>
        </SuiTypography>
      </div>
    </SuiBox>
  );
}

export default ActionCell;
