/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

function Home({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 42 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Basic-Elements" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Rounded-Icons"
          transform="translate(-2319.000000, -291.000000)"
          fill={colors[color].main}
          fillRule="nonzero"
        >
          <g id="Icons-with-opacity" transform="translate(1716.000000, 291.000000)">
            <g id="box-3d-50" transform="translate(603.000000, 0.000000)">
              <path
                stroke="null"
                id="svg_4"
                d="m45.65316,21.61624l-6.72872,-7.04805l0,-8.14168c0,-1.47213 -1.1389,-2.66517 -2.54639,-2.66517c-1.40427,0 -2.54317,1.19304 -2.54317,2.66517l0,2.81068l-5.01006,-5.24802c-2.47703,-2.59317 -6.78375,-2.58857 -9.25513,0.00469l-16.82431,17.62237c-0.99318,1.04237 -0.99318,2.72901 0,3.76979c0.99362,1.04209 2.60698,1.04209 3.60024,0l16.82269,-17.62284c0.54803,-0.57103 1.51396,-0.57103 2.05912,-0.00169l16.82556,17.62453c0.49892,0.52109 1.14939,0.78028 1.79967,0.78028c0.65163,0 1.30317,-0.25947 1.80057,-0.78028c0.99353,-1.04069 0.99353,-2.72732 -0.00009,-3.76979z"
              />
              <path
                stroke="null"
                id="svg_5"
                d="m25.08324,12.82233c-0.48879,-0.51171 -1.28023,-0.51171 -1.76759,0l-14.7987,15.49641c-0.23364,0.24464 -0.3661,0.57864 -0.3661,0.92719l0,11.3025c0,2.65222 2.05303,4.80259 4.58499,4.80259l7.32685,0l0,-11.88537l8.2719,0l0,11.88537l7.32694,0c2.53187,0 4.5849,-2.15037 4.5849,-4.80249l0,-11.3026c0,-0.34855 -0.13129,-0.68256 -0.3661,-0.92719l-14.79709,-15.49641z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

// Setting default values for the props of Cube
Home.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the Cube
Home.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Home;
