/* eslint-disable */
import { useState } from "react";
import { useHistory } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import { URL_PROJECTS } from "../../../../../api/myslr";
import axios from "../../../../../api/index";
import { useAuth } from "../../../../../auth-context/auth.context";

// sweetalert2 components
import Swal from "sweetalert2";

function ActionCell({ id, reload, own }) {
  const { user } = useAuth();
  // const history = useHistory();
  const history = createHistory();
  const [error, setError] = useState(null);

  const handleDelete = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          axios
            .delete(`${URL_PROJECTS}/${id}`, { headers: { Authorization: `${user.token}` } })
            .then((response) => {
              if (response.status == 204) {
                // newSwal.fire("Success!", "Project deleted", "success");
                reload();
                // history.go(0);
              } else {
                newSwal.fire("Error!", "We can't delete your project", "error");
              }
            })
            .catch((_error) => {
              setError(_error);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          newSwal.fire("Cancelled", "Project safe!", "error");
        }
      });
  };

  const handleView = () => {
    <Link to="/myslr/project-page" className="decoration-none"></Link>;
  };

  /*   const handleDelete = () => {
    axios
      .delete(`${URL_PROJECTS}/${id}`, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        console.log(response.status);
        if (response.status == 204) {
          Swal.fire("Success!", "Project deleted", "success");
          history.go(0);
        } else {
          Swal.fire("Error!", "We can't delete your project", "error");
          history.go(0);
        }
      })
      .catch((_error) => {
        setError(_error);
      });
  }; */

  return (
    <SuiBox display="flex" alignItems="center">
      <Link to={`/myslr/project-page/${id}`} className="decoration-none">
        <SuiTypography
          variant="body1"
          textColor="secondary"
          customClass="cursor-pointer line-height-0"
        >
          <Tooltip title="Preview project" placement="top">
            <Icon className="">visibility</Icon>
          </Tooltip>
        </SuiTypography>
      </Link>
      {own && (
        <SuiBox mx={2}>
          <Link to={`/myslr/edit-project/${id}`} className="decoration-none">
            <SuiTypography
              variant="body1"
              textColor="secondary"
              customClass="cursor-pointer line-height-0"
            >
              <Tooltip title="Edit project" placement="top">
                <Icon className="">edit</Icon>
              </Tooltip>
            </SuiTypography>
          </Link>
        </SuiBox>
      )}
      {own && (
        <div key={id} onClick={handleDelete}>
          <SuiTypography
            variant="body1"
            textColor="secondary"
            customClass="cursor-pointer line-height-0"
          >
            <Tooltip title="Delete project" placement="top">
              <Icon className="">delete</Icon>
            </Tooltip>
          </SuiTypography>
        </div>
      )}
    </SuiBox>
  );
}

export default ActionCell;
