import axios from "./index";

const base = "v1";

export const Projects = (user) =>
  axios.get(`${base}/projects`, { headers: { Authorization: `${user.token}` } }).then(() => {
    // console.log("ok");
  });

export const URL_PROJECTS = `${base}/projects`;
export const URL_USER_EMAIL = `${base}/addcollab`;
export const URL_QUERYSTRINGS = `${base}/querystrings`;
export const URL_SOURCEFILES = `${base}/sourcefiles`;
export const URL_DATASETS = `${base}/datasets`;
export const URL_PAPERS = `${base}/papers`;
export const URL_PAPERS_LIMIT = `${base}/limitpapers`;

export const URL_SELECTED_PAPERS = `${base}/selectedpapers`;
export const URL_EXCLUDED_PAPERS = `${base}/excludedpapers`;
export const URL_PAPERS_OPTIONS = `${base}/papersoptions`;
export const URL_PAPERS_TYPE_OPTIONS = `${base}/paperstypeoptions`;
export const URL_DATASETS_KEYWORDS = `${base}/datasetkeywords`;

export const URL_RUNSETS = `${base}/runsets`;
export const URL_RESULTS = `${base}/results`;
export const URL_TOPICS = `${base}/topics`;
export const URL_TOPICS_PAPERS = `${base}/topicpapers`;
export const URL_TOPICS_PAPERS_EXPORT = `${base}/topicpapersexport`;
