// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function Separator({ text }) {
  return (
    <SuiBox position="relative" py={0.25}>
      <Divider />
      <SuiBox
        backgroundColor="white"
        position="absolute"
        top="50%"
        left="50%"
        px={1.5}
        lineHeight={1}
        sx={{ transform: "translate(-50%, -60%)" }}
      >
        <SuiTypography variant="button" fontWeight="medium" textColor="secondary">
          {text}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

// Typechecking props for the FaqCollapse
Separator.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Separator;
