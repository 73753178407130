/* eslint-disable */
import { useState, useEffect } from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import { Grid } from "@mui/material";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React components
import borders from "assets/theme/base/borders";
import Separator from "../components/Separator";

import { useAuth } from "auth-context/auth.context";
import { URL_PAPERS, URL_DATASETS } from "api/myslr";
import axios from "api/index";

function PaperCollapse({ paperData, dataset, open, index, collapse, setCollapse, rsid, ...rest }) {
  const { user } = useAuth();
  const { borderWidth, borderColor } = borders;
  const [error, setError] = useState(null);

  const RenderProb = () => {
    let fProbs = [];
    let probs = paperData.topics
      .filter((item) => item.runset === rsid)
      .map((m) => fProbs.push(parseFloat(m.prob)));
    let max = Math.max(...fProbs);

    return fProbs.map((t) => (
      <SuiBox
        component="span"
        sx={{
          m: 0.2,
          width: 64,
          border: t == max ? "2px solid  #17c1e8" : "1px dashed grey",
        }}
        display="flex"
        alignItems="center"
      >
        <SuiTypography variant="button" ml={1}>
          {Math.round(t * 100 * 100) / 100}%
        </SuiTypography>
      </SuiBox>
    ));
  };

  return (
    <SuiBox mb={1}>
      <SuiBox
        {...rest}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        customClass="cursor-pointer"
        onClick={() => (collapse === index ? setCollapse(false) : setCollapse(index))}
      >
        <SuiTypography
          variant="h6"
          textColor={open ? "dark" : "text"}
          customClass="user-select-none"
        >
          {paperData.title}
        </SuiTypography>

        <SuiBox
          color={open ? "dark" : "text"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <RenderProb ml={6} />
          <Icon className=" font-bold" fontSize="small">
            {open ? "remove" : "add"}
          </Icon>
        </SuiBox>
      </SuiBox>
      {collapse === index && (
        <Collapse timeout={400} in={open}>
          <SuiBox p={2} lineHeight={1}>
            <SuiTypography variant="button" textColor="dark" opacity={0.9} fontWeight="regular">
              <Grid container spacing={2}>
                <Grid item xs={8} md={8}>
                  <SuiBox mb={2}>
                    <SuiTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      textColor="text"
                    >
                      AUTHORS KEYWORDS: {paperData.keywords}
                    </SuiTypography>
                    <SuiTypography variant="button" fontWeight="regular" textColor="text">
                      JOURNAL: <b>{paperData.journal}</b>
                    </SuiTypography>
                    <SuiTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      textColor="text"
                    >
                      LANGUAGE:{" "}
                      <span className="font-bold">
                        {paperData.language == 0 ? "-" : paperData.language}
                      </span>
                    </SuiTypography>
                  </SuiBox>
                </Grid>
                <Grid item xs={4} md={4}>
                  <SuiBox mb={2}>
                    <SuiTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      textColor="text"
                    >
                      OPENACCESS: {paperData.openaccess == 0 ? " NO" : paperData.openaccess}{" "}
                      <span className="font-bold">
                        <a href={paperData.link} target="_blank">
                          (View at publisher)
                        </a>
                      </span>
                    </SuiTypography>
                    <SuiTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      textColor="text"
                    >
                      AUTHORS: {paperData.authors}
                    </SuiTypography>
                  </SuiBox>
                </Grid>
              </Grid>

              <SuiBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <SuiBox>
                  <SuiTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    textColor="text"
                  >
                    DOI:{" "}
                    <span className="font-bold">{paperData.doi == 0 ? "-" : paperData.doi}</span>{" "}
                  </SuiTypography>
                  <SuiTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    textColor="text"
                  >
                    ISSN:{" "}
                    <span className="font-bold">{paperData.issn == 0 ? "-" : paperData.issn}</span>
                  </SuiTypography>
                </SuiBox>
                <SuiBox>
                  <SuiTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    textColor="text"
                  >
                    YEAR:{" "}
                    <span className="font-bold">{paperData.year == 0 ? "-" : paperData.year}</span>
                  </SuiTypography>
                  <SuiTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    textColor="text"
                  >
                    TYPE:{" "}
                    <span className="font-bold">{paperData.type == 0 ? "-" : paperData.type}</span>
                  </SuiTypography>
                </SuiBox>
                <SuiBox>
                  <table
                    style={{ borderRadius: 4, border: "solid 1px black", borderColor: "black" }}
                  >
                    <tr style={{ padding: "4px", textAlign: "center" }}>
                      <td colspan="2" style={{ padding: "4px" }}>
                        CITATIONS ({paperData.citations})
                      </td>
                    </tr>
                    {paperData.scopus_citations != null && (
                      <tr>
                        <th style={{ padding: "4px", textAlign: "left" }}>Scopus</th>
                        <th style={{ padding: "4px" }}>{paperData.scopus_citations}</th>
                      </tr>
                    )}
                    {paperData.wos_citations != null && (
                      <tr>
                        <th style={{ padding: "4px", textAlign: "left" }}>Web of Science</th>
                        <th style={{ padding: "4px" }}>{paperData.wos_citations}</th>
                      </tr>
                    )}
                    {paperData.pubmed_citations != null && (
                      <tr>
                        <th style={{ padding: "4px", textAlign: "left" }}>MEDLINE PubMed</th>
                        <th style={{ padding: "4px" }}>{paperData.pubmed_citations}</th>
                      </tr>
                    )}
                  </table>
                </SuiBox>
                <SuiBox>
                  <table
                    style={{ borderRadius: 4, border: "solid 1px black", borderColor: "black" }}
                  >
                    <tr style={{ padding: "4px", textAlign: "center" }}>
                      <td style={{ padding: "4px" }}>SOURCES</td>
                    </tr>
                    {paperData.paper_source_db.split(";").map((item) => {
                      let name = "";
                      switch (item) {
                        case "scopus":
                          name = "Scopus";
                          break;
                        case "wos":
                          name = "Web of Science";
                          break;
                        case "pubmed":
                          name = "MEDLINE PubMed";
                          break;
                        default:
                          "none";
                      }
                      return (
                        <tr>
                          <th style={{ padding: "4px", textAlign: "left" }}>{name}</th>
                        </tr>
                      );
                    })}
                  </table>
                </SuiBox>
              </SuiBox>
              <Separator text={"Abstract"} />
              {paperData.abstract}
            </SuiTypography>
          </SuiBox>
        </Collapse>
      )}
    </SuiBox>
  );
}

export default PaperCollapse;
