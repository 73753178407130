import ga4 from "react-ga4";

const TRACKING_ID = "G-YK2J5VXPJB";
const isProduction = process.env.NODE_ENV === "production";

export const init = () =>
  ga4.initialize(TRACKING_ID, {
    testMode: !isProduction,
  });

export const sendEvent = (name) =>
  ga4.event({
    category: "Homepage",
    action: "Button",
    label: name, // optional
    value: 99, // optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });

export const sendPageview = (path) =>
  ga4.send({
    hitType: "pageview",
    page: path,
  });
