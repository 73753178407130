/* eslint-disable */
import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

// DefaultLineChart configurations
import configs from "./configs";

import Watermark from "@uiw/react-watermark";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";

const handleCaptureClick = async () => {
  const canvas = await html2canvas(document.getElementById("stackW"));
  const dataURL = canvas.toDataURL("image/png");
  downloadjs(dataURL, "myslr_years_docs.png", "image/png");
};

function DefaultLineChart({ title, description, height, chart }) {
  const chartDatasets = chart.datasets.map((dataset) => ({
    ...dataset,
    tension: 0.4,
    borderWidth: 3,
    pointRadius: 2,
    pointBackgroundColor: colors[dataset.color].main,
    borderColor: colors[dataset.color].main,
    maxBarThickness: 6,
  }));

  const { data, options } = configs(chart.labels, chartDatasets);

  const renderChart = (
    <SuiBox p={2}>
      {title || description ? (
        <SuiBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <SuiBox mb={1}>
              <SuiTypography variant="h6">
                {title}
                {"      "}
                <SuiButton variant="button" buttonColor="dark" onClick={handleCaptureClick}>
                  PNG&nbsp;
                  <Icon className="">download</Icon>
                </SuiButton>
              </SuiTypography>
            </SuiBox>
          )}
          <SuiBox mb={2}>
            <SuiTypography variant="button" fontWeight="regular" textColor="text">
              {description}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      ) : null}
      {useMemo(
        () => (
          <div id="stackW">
            <Watermark content="MySLR ©">
              <SuiBox height={height}>
                <Line data={data} options={options} />
              </SuiBox>
            </Watermark>
          </div>
        ),
        [chart, height]
      )}
    </SuiBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of DefaultLineChart
DefaultLineChart.defaultProps = {
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the DefaultLineChart
DefaultLineChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default DefaultLineChart;
