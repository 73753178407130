/* eslint-disable */
import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Billing page components
import Collaborator from "../Collaborator";
import AddCollaborator from "../AddCollaborator";

function Collaborators({ projectData, reload, isOwner }) {
  useEffect(() => {
    // console.log(projectData.collaborators);
  }, []);

  return (
    <Card className="h-100">
      <SuiBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <SuiTypography variant="h6" fontWeight="medium">
          Collaborators
        </SuiTypography>
        {isOwner && (
          <AddCollaborator project_id={projectData.id} reload={reload} isOwner={isOwner} />
        )}
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {projectData.collaborators.length > 0 ? (
            projectData.collaborators.map((c) => (
              <Collaborator
                key={c}
                collab={c}
                project_id={projectData.id}
                reload={reload}
                isOwner={isOwner}
              />
            ))
          ) : (
            <SuiTypography variant="h6" fontWeight="medium">
              None
            </SuiTypography>
          )}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Collaborators;
