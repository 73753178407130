/* eslint-disable */
import { useEffect, useState } from "react";
// react-router-dom components
import { Link, useHistory } from "react-router-dom";

import Tour from "reactour";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Soft UI Dashboard PRO React components
import SuiBadge from "components/SuiBadge";
// Data
import dataTableData from "./data/dataTableData";
import DataTable from "./DataTable";

import { URL_PROJECTS } from "../../../api/myslr";
import axios from "../../../api/index";
import { useAuth } from "../../../auth-context/auth.context";

// ProductsList page components
import ProjectCell from "./components/ProjectCell";
import ActionCell from "./components/ActionCell";
import Icon from "@mui/material/Icon";
// Notifications
import SuiSnackbar from "components/SuiSnackbar";
import SuiAlert from "components/SuiAlert";

import Moment from "moment";

function ProjectsList() {
  const { user } = useAuth();
  const { setUser } = useAuth();
  const history = useHistory();
  const [data, setData] = useState({
    columns: [
      /*  { Header: "id", accessor: "id", width: "10%" }, */
      { Header: "project name", accessor: "project_name", width: "20%" },
      { Header: "description", accessor: "project_description", width: "25%" },
      { Header: "collaborators", accessor: "collaborators", width: "20%" },
      { Header: "created at", accessor: "created_at", width: "20%" },
      /* {
        Header: "status",
        accessor: "status",
        Cell: ({ value }) => (value === "complete" ? complete : analyzing),
      }, */
      { Header: "actions", accessor: "actions", width: "10%" },
    ],
    rows: [],
  });
  const [error, setError] = useState(null);
  const [update, setUpdate] = useState();
  const [reload, setReload] = useState(false);

  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [isTourOpen, setIsTourOpen] = useState(false);

  const helpButton = (
    <SuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      backgroundColor="white"
      boxShadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      customClass="cursor-pointer"
      onClick={() => setIsTourOpen(true)}
    >
      <Icon className=" text-dark" fontSize="default">
        help
      </Icon>
    </SuiBox>
  );

  const steps = [
    {
      selector: "#heading",
      content: "Start here creating a new project.",
    },
    {
      selector: "#table",
      content: "You will find all projects in this table.",
    },
  ];

  const renderSuccessSB = (
    <SuiSnackbar
      type="success"
      icon="check"
      title="Success!"
      content="Project deleted!"
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  // Badges
  const analyzing = (
    <SuiBadge
      variant="contained"
      color="error"
      size="extra-small"
      badgeContent="Analyzing"
      container
    />
  );
  const complete = (
    <SuiBadge
      variant="contained"
      color="success"
      size="extra-small"
      badgeContent="Complete"
      container
    />
  );

  const handleNotification = () => {
    handleFetch();
    openSuccessSB();
  };

  const handleFetch = () => {
    user &&
      user.token &&
      axios
        .get(URL_PROJECTS, { headers: { Authorization: `${user.token}` } })
        .then((response) => {
          let refilled = response.data.results.map((v) => ({
            ...v,
            project_name: (
              <Link to={`/myslr/project-page/${v.id}`} className="decoration-none">
                <SuiTypography
                  textColor={user.email === v.owner.email ? "secondary" : "success"}
                  variant="h6"
                >
                  {v.project_name}
                </SuiTypography>
              </Link>
            ),
            project_description: (
              <SuiTypography
                textColor={user.email === v.owner.email ? "secondary" : "success"}
                variant="p"
              >
                {v.project_description}
              </SuiTypography>
            ),
            collaborators: v.collaborators[0]
              ? v.collaborators.map(function (item, i) {
                  return <li key={i}>{item.email}</li>;
                })
              : "None",
            actions: (
              <ActionCell
                id={v.id}
                reload={handleNotification}
                own={user.email === v.owner.email}
              />
            ),
            // status: "complete",
            //created_at: new Date(v.created_at).toISOString().split("T")[1],
            created_at: Moment(v.created_at).format("YYYY/MM/DD - hh:mm"),
          }));
          setData((prevState) => ({
            ...prevState,
            rows: refilled,
          }));
        })
        .catch((_error) => {
          if (_error.response.status == 403) {
            setUser(null);
            localStorage.removeItem("user");
            return history.push(`/authentication/sign-in/cover`);
          }
          setError(_error);
        });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  if (error) return `Error: ${error.message}`;
  if (!data) return "No post!";

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox my={3}>
        <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography variant="h5" fontWeight="medium">
                All Projects
              </SuiTypography>
              <SuiTypography variant="button" fontWeight="regular" textColor="text">
                Listed in this page are all your current research projects
              </SuiTypography>
            </SuiBox>
            <Stack spacing={1} direction="row" id="heading">
              <Link to="/myslr/new-project" className="decoration-none">
                <SuiButton variant="gradient" buttonColor="info" size="small">
                  + new project
                </SuiButton>
              </Link>
              {/* <SuiButton variant="outlined" buttonColor="info" size="small">
                import
              </SuiButton>
              <SuiButton variant="outlined" buttonColor="info" size="small">
                export
              </SuiButton> */}
            </Stack>
          </SuiBox>
          {data.rows[0] ? (
            <SuiBox my={3} id="table">
              <DataTable
                table={data}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [5, 10, 15, 20],
                }}
                canSearch
              />
            </SuiBox>
          ) : (
            <SuiTypography variant="h4" align="center" fontWeight="bold" gutterBottom>
              Welcome! Start here creating a new research project.
            </SuiTypography>
          )}
        </Card>
      </SuiBox>
      {renderSuccessSB}
      <Tour steps={steps} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} />
      {helpButton}
      <Footer />
    </DashboardLayout>
  );
}

export default ProjectsList;
