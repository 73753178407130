/* eslint-disable */
import { useState, useEffect } from "react";
// react-router-dom components
import { useHistory } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

import { Recovery } from "../../../../api/auth";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved6 from "assets/images/curved-images/curved6.jpg";

function Cover() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [error, setError] = useState(undefined);
  const [buttonText, setButtonText] = useState("Reset");

  const Redirect = () => {
    const timer = setTimeout(() => {
      return history.push("/authentication/sign-in/cover");
    }, 3000);
    return () => clearTimeout(timer);
  };

  const Reset = async (event) => {
    if (event) {
      event.preventDefault();
    }

    setButtonText("Sending reset");
    try {
      const response = await Recovery({
        email,
      });
      if (response.status === 200) {
        setIsRedirecting(true);
        Redirect();
      }
      if (response.data && response.data.success === false) {
        // console.log(response.data);
        setButtonText("Reset");
        return setError(response);
      }
    } catch (err) {
      // console.log(err);
      setButtonText("Reset");
      if (err.response) {
        // console.log(err.response.data["email"][0]);
        return setError(err.response.data["email"][0]);
      }
      return setError("There has been an error.");
    }
  };

  return (
    <CoverLayout
      title="Reset Password"
      description="You will receive an e-mail in maximum 60 seconds"
      image={curved6}
      top={30}
    >
      {isRedirecting ? (
        <SuiTypography component="label" variant="p" fontWeight="bold">
          Reset has been sent! You will be redirected to login page.
        </SuiTypography>
      ) : (
        <SuiBox component="form" role="form">
          <SuiBox mb={2} lineHeight={1.25}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Email
              </SuiTypography>
            </SuiBox>
            <SuiInput
              defaultValue={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setError(undefined);
              }}
              type="email"
              placeholder="Email"
            />
            {error && (
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textColor="error"
              >
                {error}
              </SuiTypography>
            )}
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton onClick={Reset} variant="gradient" buttonColor="info" fullWidth>
              {buttonText}
            </SuiButton>
          </SuiBox>
        </SuiBox>
      )}
    </CoverLayout>
  );
}

export default Cover;
