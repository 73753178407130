/* eslint-disable */
// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// sweetalert2 components
import Swal from "sweetalert2";

import { URL_PROJECTS } from "api/myslr";
import axios from "api/index";
import { useAuth } from "auth-context/auth.context";

function Collaborator({ collab, project_id, noGutter, reload, isOwner }) {
  const { user } = useAuth();

  const removeUser = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Remove collaborator",
        text: "Do you want to remove collaborator?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: false,
      })
      .then((result) => {
        if (result.value) {
          axios
            .post(
              `${URL_PROJECTS}/${project_id}/removecollab`,
              { email: collab.email },
              {
                headers: { Authorization: `${user.token}` },
              }
            )
            .then((response) => {
              if (!response) {
                throw new Error(response.statusText);
              }
              reload();
              return response;
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          newSwal.fire("Cancelled", "User safe!", "error");
        }
      });
  };

  return (
    <SuiBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <SuiBox lineHeight={1.125}>
        <SuiTypography display="block" variant="button" fontWeight="medium">
          {collab.username}
        </SuiTypography>
        <SuiTypography variant="caption" fontWeight="regular" textColor="text">
          {collab.email}
        </SuiTypography>
      </SuiBox>
      {isOwner && (
        <SuiBox display="flex" alignItems="center">
          <div onClick={removeUser}>
            <SuiBox
              display="flex"
              alignItems="center"
              lineHeight={1}
              ml={3}
              customClass="cursor-pointer"
            >
              <Icon fontSize="small">delete_forever</Icon>
              <SuiTypography variant="button" fontWeight="bold"></SuiTypography>
            </SuiBox>
          </div>
        </SuiBox>
      )}
    </SuiBox>
  );
}

export default Collaborator;
