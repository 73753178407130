/* eslint-disable */
import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import TeamCards from "./components/TeamCards";

// Overview page components
import Header from "./components/Header";

import { Divider } from "@mui/material";

// Images
import image1 from "assets/team/ammirato.jpg";
import image2 from "assets/team/rogano.jpg";
import image3 from "assets/team/felicetti.jpg";
import image4 from "assets/team/linzalone.jpg";
import image5 from "assets/team/corvello.jpg";

function TeamPage() {
  const [members, setMembers] = useState([image1, image2, image3, image4, image5]);

  return (
    <DashboardLayout>
      <Header />

      {/* SECTION 01 */}

      <div id="01">
        <SuiBox pt={5}></SuiBox>
        <TeamCards members={members} />
      </div>

      <Footer />
    </DashboardLayout>
  );
}

export default TeamPage;
