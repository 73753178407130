/* eslint-disable */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "auth-context/auth.context";
import axios from "api/index";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../components/DashboardNavbar";
import Footer from "examples/Footer";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

import PaperLocationSelection from "./PaperLocationSelection";
import PapersAnalysis from "./PapersAnalysis";
import ResultsSynthesis from "./ResultsSynthesis";

import { URL_QUERYSTRINGS } from "api/myslr";

function QuerystringPage({ querystring_id }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [tab2enabled, setTab2Enabled] = useState(false);
  const [tab3enabled, setTab3Enabled] = useState(false);
  const { user } = useAuth();
  const [error, setError] = useState(null);
  const [dataset, setDataset] = useState(null);
  const [querystring, setQuerystring] = useState(null);
  const [project, setProject] = useState(null);
  const [rsId, setRsId] = useState(null);

  const { tab, id } = useParams();

  const handleTab2 = (value) => setTab2Enabled(value);
  const handleTab3 = (value) => setTab3Enabled(value);

  const handleFetchSources = () => {
    user != null &&
      axios
        .get(`${URL_QUERYSTRINGS}/${id}`, { headers: { Authorization: `${user.token}` } })
        .then((response) => {
          setDataset(response.data.default_dataset);
          setQuerystring(response.data);
          setTab2Enabled(response.data.confirmed);
        })
        .catch((_error) => {
          setError(_error);
        });
  };

  useEffect(() => {
    id && handleFetchSources();
  }, [id]);

  useEffect(() => {
    tab && setTabValue(Number(tab));
  }, [tab]);

  function setResult(rs_id) {
    setRsId(rs_id);
    setTabValue(2);
  }

  function getTabContent(tabIndex) {
    switch (tabIndex) {
      case 0:
        return (
          <PaperLocationSelection querystring_id={querystring_id} handleTab={handleFetchSources} />
        );
      case 1:
        return (
          <PapersAnalysis querystring_id={querystring_id} handleTab={handleTab3} goTo={setResult} />
        );
      case 2:
        return <ResultsSynthesis querystring_id={querystring_id} runset_id={rsId} />;
      default:
        return null;
    }
  }

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    user != null && (
      <DashboardLayout>
        {querystring && (
          <DashboardNavbar
            querystring={querystring}
            project={querystring.project_name}
            querystringID={querystring.id}
            projectID={querystring.project_id}
          />
        )}
        <SuiBox position="relative">
          <Grid item xs={12} md={12} lg={12} className="ml-auto">
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                className="bg-transparent"
              >
                <Tab label="1. Papers location and selection" icon={<Cube />} />
                <Tab label="2. Papers analysis" icon={<Cube />} disabled={!tab2enabled} />
                <Tab label="3. Results synthesis" icon={<Document />} disabled={!tab3enabled} />
              </Tabs>
            </AppBar>
          </Grid>
          {getTabContent(tabValue)}
        </SuiBox>
        <Footer />
      </DashboardLayout>
    )
  );
}

export default QuerystringPage;
