/* eslint-disable */
// sweetalert2 components
import Swal from "sweetalert2";
import SuiButton from "components/SuiButton";

// Sweet Alerts page components
import { URL_PROJECTS } from "api/myslr";
import axios from "api/index";
import { useAuth } from "auth-context/auth.context";

function AddCollaborator({ project_id, reload }) {
  const { user } = useAuth();
  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
    });
    newSwal
      .fire({
        title: "Submit collaborator email",
        input: "email",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Invite",
        showLoaderOnConfirm: true,
        preConfirm: (email) =>
          axios
            .post(
              `${URL_PROJECTS}/${project_id}/addcollab`,
              { email: email },
              {
                headers: { Authorization: `${user.token}` },
              }
            )
            .then((response) => {
              if (!response) {
                throw new Error(response.statusText);
              }
              reload();
              return response;
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            }),
        allowOutsideClick: () => !Swal.isLoading(),
      })
      .then((result) => {
        let exists = false;
        let msg;
        if (result.value) {
          if (result.value.data.length > 0) {
            msg = result.value.data[0].email;
            exists = true;
          } else {
            msg = "Not found!";
          }
        }
        if (result.isConfirmed) {
          exists
            ? Swal.fire({
                title: `Invitation will be sent to "${msg}"`,
                icon: "success",
              })
            : Swal.fire({
                title: `The user is not registered yet, we will send an email to join mySLR`,
                icon: "success",
              });
        }
      });
  };

  return (
    <SuiButton variant="outlined" buttonColor="info" size="small" onClick={showAlert}>
      + Invite new
    </SuiButton>
  );
  {
    /* <Template title="A success message" action={showAlert} />; */
  }
}

export default AddCollaborator;
