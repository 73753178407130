/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
// @mui material components
import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette, boxShadows, functions, borders }) => {
  const { dark, white, info, primary, secondary, transparent: transparentColor } = palette;
  const { regular } = boxShadows;
  const { rgba, pxToRem } = functions;
  const { borderRadius } = borders;

  console.log(palette);

  return {
    defaultNavbar: {
      display: "flex",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: "9999",
      justifyContent: "space-around",
      alignItems: "center",
      backgroundColor: ({ transparent }) =>
        transparent ? "transparentColor.main" : rgba(secondary.main, 0.6),
      color: ({ light }) => (light ? white.main : dark.main),
      boxShadow: ({ transparent }) => (transparent ? "none" : regular),
      backdropFilter: ({ transparent }) =>
        transparent ? "none" : `saturate(200%) blur(${pxToRem(30)})`,
      borderRadius: 10,
      margin: `${pxToRem(4)} ${pxToRem(10)}`,
      width: `calc(100% - ${pxToRem(20)})`,
    },
    mobileNavbar: {
      display: "flex",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: "9999",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: ({ transparent }) =>
        transparent ? transparentColor.main : rgba(dark.main, 0.8),
      color: ({ light }) => (light ? white.main : dark.main),
      boxShadow: ({ transparent }) => (transparent ? "none" : regular),
      backdropFilter: ({ transparent }) =>
        transparent ? "none" : `saturate(200%) blur(${pxToRem(30)})`,
      borderRadius: 10,
      margin: `${pxToRem(2)} ${pxToRem(2)}`,
      width: `calc(100% - ${pxToRem(4)})`,
    },
  };
});
