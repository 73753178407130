/* eslint-disable */
import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useHistory, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiDatePicker from "components/SuiDatePicker";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { URL_PROJECTS } from "../../../api/myslr";
import axios from "../../../api/index";
import { useAuth } from "../../../auth-context/auth.context";

// sweetalert2 components
import Swal from "sweetalert2";

function EditProject() {
  const { id } = useParams();
  const { user } = useAuth();
  const history = useHistory();
  const [projectData, setProjectData] = useState();
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");

  const [error, setError] = useState(null);

  const handleFetch = () => {
    axios
      .get(`${URL_PROJECTS}/${id}`, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        setProjectName(() => response.data.project_name);
        setProjectDescription(() => response.data.project_description);
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  const handlePost = () => {
    let postData = new Object();
    let name = projectName.replace(/&nbsp;/g, " ");
    let description = projectDescription.replace(/&nbsp;/g, " ");
    postData.project_name = name.replace(/(<([^>]+)>)/gi, "");
    postData.project_description = description.replace(/(<([^>]+)>)/gi, "");
    axios
      .patch(`${URL_PROJECTS}/${id}`, postData, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        if (response.status == 200) {
          Swal.fire("Success!", "Project modified", "success");
          history.push("/myslr/projects-list");
        } else {
          Swal.fire("Error!", "We can't edit this project, check your data again", "error");
        }
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Edit Project
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Change project attributes
                </SuiTypography>
                <Divider />
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Project Name
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor
                    placeholder="Project name...."
                    value={projectName}
                    onChange={setProjectName}
                  />
                </SuiBox>
                <SuiBox mt={3} mb={2}></SuiBox>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Project Description
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      This is what others team members will read about the project, so make it good!
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor
                    placeholder="Project description...."
                    value={projectDescription}
                    onChange={setProjectDescription}
                  />
                </SuiBox>
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <Link to="/myslr/projects-list" className="decoration-none">
                    <SuiBox mr={1}>
                      <SuiButton buttonColor="light">cancel</SuiButton>
                    </SuiBox>
                  </Link>
                  <div onClick={handlePost}>
                    <SuiButton variant="gradient" buttonColor="info">
                      Save
                    </SuiButton>
                  </div>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditProject;
