/* eslint-disable */
import { useState, useEffect } from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Collapse from "@mui/material/Collapse";
import { Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiEditor from "components/SuiEditor";

// Soft UI Dashboard PRO React components
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

import Separator from "../components/Separator";

import { useAuth } from "auth-context/auth.context";
import { URL_PAPERS, URL_DATASETS } from "api/myslr";
import axios from "api/index";

function PaperCollapse({
  paperData,
  dataset,
  open,
  index,
  collapse,
  setCollapse,
  switchUsed,
  switchConfirmed,
  isOwner,
  owner,
  update,
  ...rest
}) {
  const { user } = useAuth();
  const { borderWidth, borderColor } = borders;
  const [error, setError] = useState(null);
  const [visualUsed, setVisualUsed] = useState(paperData.used);
  const [visualChecked, setVisualChecked] = useState(paperData.confirmed);
  const [notes, setNotes] = useState(paperData.notes);
  const [isEditing, setIsEditing] = useState(false);

  const handleSwitch = (id, used) => {
    let data = { used: !visualUsed, paper: paperData.id };
    setVisualUsed(!visualUsed);
    axios
      .post(`${URL_DATASETS}/${dataset}/switch_paper`, data, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        if (response.status == 202) {
          // keep warning
          switchUsed(false);
          update();
        } else if (response.status == 204) {
          // No content
          switchUsed(true);
          update();
        }
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const handleConfirmed = (id, used) => {
    let data = { used: !visualChecked, paper: paperData.id };
    setVisualChecked(!visualChecked);
    axios
      .post(`${URL_DATASETS}/${dataset}/switch_confirmed`, data, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        if (response.status == 202) {
          // keep warning
          switchConfirmed(false);
          update();
        } else if (response.status == 204) {
          // No content
          switchConfirmed(true);
          update();
        }
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const saveNotes = () => {
    let data = { notes: notes, paper: paperData.id };
    axios
      .post(`${URL_DATASETS}/${dataset}/set_notes`, data, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        if (response.status == 202) {
          // keep warning
          setIsEditing(false);
          update();
        } else if (response.status == 204) {
          // No content
          setIsEditing(false);
          update();
        }
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const handleConfirm = (id, used) => {
    let data = { used: used, paper: paperData.id };
    setVisualUsed(used);
    axios
      .post(`${URL_DATASETS}/${dataset}/confirm_paper`, data, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        if (response.status == 202) {
          // keep warning
          switchUsed(true);
          update();
        } else {
          // No content
          console.log("ERROR 403");
        }
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const handleRefuse = (id) => {
    let data = { paper: paperData.id };
    axios
      .post(`${URL_DATASETS}/${dataset}/refuse_paper`, data, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        if (response.status == 202) {
          // keep warning
          switchUsed(true);
          update();
        } else {
          // No content
          console.log("ERROR 403");
        }
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  useEffect(() => {
    paperData.conf.map((c) => {
      if (c.collaborator.email === user.email) {
        setVisualUsed(c.used);
      }
    });
  }, []);

  return (
    <SuiBox mb={1}>
      <SuiBox
        {...rest}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        customClass="cursor-pointer"
      >
        <SuiTypography
          variant="h6"
          textColor={open ? "dark" : "text"}
          customClass="user-select-none"
        >
          {paperData.conf.length > 0 ? (
            <SuiButton variant="outlined" buttonColor={"warning"} size="small" iconOnly circular>
              <Icon className=" font-bold">priority_high_icon</Icon>
            </SuiButton>
          ) : (
            <SuiButton
              variant="outlined"
              buttonColor={visualUsed ? "success" : "error"}
              size="small"
              iconOnly
              circular
            >
              <Icon className=" font-bold">{visualUsed ? "done " : "close "}</Icon>
            </SuiButton>
          )}
          {"  "}
          <Switch
            checked={visualUsed}
            onChange={() => handleSwitch(paperData.id, paperData.used)}
          />
          {"    "}
          <span onClick={() => (collapse === index ? setCollapse(false) : setCollapse(index))}>
            {paperData.title}
          </span>
        </SuiTypography>

        <SuiBox display="flex" justifyContent="space-evenly" alignItems="center" ml={2}>
          {notes && notes.replace(/(<([^>]+)>)/gi, "").length > 0 ? (
            <Icon className="" color="info">
              comment_icon
            </Icon>
          ) : null}
          <SuiBox ml={2}>
            <Checkbox
              checked={visualChecked}
              onChange={() => handleConfirmed(paperData.id, paperData.used)}
              // sx={{
              //   "& .MuiSvgIcon-root": { fontSize: 28 },
              // }}
            />
          </SuiBox>
        </SuiBox>

        {/* <SuiBox
          color={open ? "dark" : "text"}
          onClick={() => (collapse === index ? setCollapse(false) : setCollapse(index))}
        >
          <Icon className=" font-bold" fontSize="small">
            {open ? "remove" : "add"}
          </Icon>
        </SuiBox> */}
      </SuiBox>
      {collapse === index && (
        <Collapse timeout={200} in={open}>
          <SuiBox p={2} lineHeight={1} mb={5}>
            <SuiTypography variant="button" textColor="dark" opacity={0.9} fontWeight="regular">
              {paperData.conf.length > 0 && (
                <Grid
                  container
                  spacing={1}
                  sx={{ border: 2, borderColor: "warning.main", borderRadius: "8px" }}
                  mb={1}
                  mt={0.2}
                >
                  {!isOwner && (
                    <Grid item xs={12} md={12}>
                      <SuiTypography
                        component="p"
                        variant="caption"
                        fontWeight="regular"
                        textColor="error"
                      >
                        You don't have the permission to resolve conflicts on this project
                      </SuiTypography>
                    </Grid>
                  )}
                  {paperData.conf.map((c) => (
                    <Grid item xs={4} md={4}>
                      <SuiBox mb={1}>
                        <SuiTypography
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          textColor="text"
                        >
                          {c.collaborator.username}:{" "}
                          <SuiButton
                            variant="outlined"
                            buttonColor={c.used ? "success" : "error"}
                            size="small"
                            iconOnly
                            circular
                          >
                            <Icon className=" font-bold">{c.used ? "done " : "close "}</Icon>
                          </SuiButton>{" "}
                          {isOwner && (
                            <span onClick={() => handleConfirm(paperData.id, c.used)}>
                              <SuiButton variant="outlined" buttonColor="success" size="small">
                                Accept
                              </SuiButton>
                            </span>
                          )}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                  ))}
                  {isOwner ? (
                    <Grid item xs={4} md={4}>
                      <SuiButton
                        variant="outlined"
                        buttonColor="error"
                        size="small"
                        onClick={() => handleRefuse(paperData.id)}
                      >
                        Ignore
                      </SuiButton>
                    </Grid>
                  ) : (
                    <Grid item xs={4} md={4}>
                      <SuiTypography
                        component="p"
                        variant="button"
                        fontWeight="regular"
                        textColor="text"
                      >
                        Owner ({owner.username}):{" "}
                        <SuiButton
                          variant="outlined"
                          buttonColor={paperData.used ? "success" : "error"}
                          size="small"
                          iconOnly
                          circular
                        >
                          <Icon className=" font-bold">{paperData.used ? "done " : "close "}</Icon>
                        </SuiButton>
                      </SuiTypography>
                    </Grid>
                  )}
                </Grid>
              )}

              <Grid container spacing={2}>
                <Grid item xs={8} md={8}>
                  <SuiBox mb={2}>
                    <SuiTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      textColor="text"
                    >
                      AUTHORS KEYWORDS: {paperData.keywords}
                    </SuiTypography>
                    <SuiTypography variant="button" fontWeight="regular" textColor="text">
                      JOURNAL: <b>{paperData.journal}</b>
                    </SuiTypography>
                    <SuiTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      textColor="text"
                    >
                      LANGUAGE:{" "}
                      <span className="font-bold">
                        {paperData.language == 0 ? "-" : paperData.language}
                      </span>
                    </SuiTypography>
                  </SuiBox>
                </Grid>
                <Grid item xs={4} md={4}>
                  <SuiBox mb={2}>
                    <SuiTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      textColor="text"
                    >
                      OPENACCESS: {paperData.openaccess == 0 ? " NO" : paperData.openaccess}{" "}
                      <span className="font-bold">
                        <a href={paperData.link} target="_blank">
                          (View at publisher)
                        </a>
                      </span>
                    </SuiTypography>
                    <SuiTypography
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      textColor="text"
                    >
                      AUTHORS: {paperData.authors}
                    </SuiTypography>
                  </SuiBox>
                </Grid>
              </Grid>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <SuiBox>
                  <SuiTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    textColor="text"
                  >
                    DOI:{" "}
                    <span className="font-bold">{paperData.doi == 0 ? "-" : paperData.doi}</span>{" "}
                  </SuiTypography>
                  <SuiTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    textColor="text"
                  >
                    ISSN:{" "}
                    <span className="font-bold">{paperData.issn == 0 ? "-" : paperData.issn}</span>
                  </SuiTypography>
                </SuiBox>
                <SuiBox>
                  <SuiTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    textColor="text"
                  >
                    YEAR:{" "}
                    <span className="font-bold">{paperData.year == 0 ? "-" : paperData.year}</span>
                  </SuiTypography>
                  <SuiTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    textColor="text"
                  >
                    TYPE:{" "}
                    <span className="font-bold">{paperData.type == 0 ? "-" : paperData.type}</span>
                  </SuiTypography>
                </SuiBox>
                <SuiBox>
                  <table
                    style={{ borderRadius: 4, border: "solid 1px black", borderColor: "black" }}
                  >
                    <tr style={{ padding: "4px", textAlign: "center" }}>
                      <td colspan="2" style={{ padding: "4px" }}>
                        CITATIONS ({paperData.citations})
                      </td>
                    </tr>
                    {paperData.scopus_citations != null && (
                      <tr>
                        <th style={{ padding: "4px", textAlign: "left" }}>Scopus</th>
                        <th style={{ padding: "4px" }}>{paperData.scopus_citations}</th>
                      </tr>
                    )}
                    {paperData.wos_citations != null && (
                      <tr>
                        <th style={{ padding: "4px", textAlign: "left" }}>Web of Science</th>
                        <th style={{ padding: "4px" }}>{paperData.wos_citations}</th>
                      </tr>
                    )}
                    {paperData.pubmed_citations != null && (
                      <tr>
                        <th style={{ padding: "4px", textAlign: "left" }}>MEDLINE PubMed</th>
                        <th style={{ padding: "4px" }}>{paperData.pubmed_citations}</th>
                      </tr>
                    )}
                  </table>
                  {/* <SuiTypography component="p" variant="h6" fontWeight="regular" textColor="text">
                    {paperData.scopus_citations && (
                      <span className="font-bold">
                        {"Scopus: " + paperData.scopus_citations + " "}
                      </span>
                    )}
                    {paperData.scopus_citations && (
                      <span className="font-bold">{"WoS: " + paperData.wos_citations}</span>
                    )}
                  </SuiTypography> 
                  <SuiTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    textColor="text"
                  >
                    WoS: <span className="font-bold">{paperData.wos_citations}</span>
                  </SuiTypography>*/}
                </SuiBox>
                <SuiBox>
                  <table
                    style={{ borderRadius: 4, border: "solid 1px black", borderColor: "black" }}
                  >
                    <tr style={{ padding: "4px", textAlign: "center" }}>
                      <td style={{ padding: "4px" }}>SOURCES</td>
                    </tr>
                    {paperData.paper_source_db.split(";").map((item) => {
                      let name = "";
                      switch (item) {
                        case "scopus":
                          name = "Scopus";
                          break;
                        case "wos":
                          name = "Web of Science";
                          break;
                        case "pubmed":
                          name = "MEDLINE PubMed";
                          break;
                        default:
                          "none";
                      }
                      return (
                        <tr>
                          <th style={{ padding: "4px", textAlign: "left" }}>{name}</th>
                        </tr>
                      );
                    })}
                  </table>
                  {/*  <SuiTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    textColor="text"
                  >
                    Database sources
                  </SuiTypography>
                  <SuiTypography
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    textColor="text"
                  >
                    <span className="font-bold">{paperData.paper_source_db}</span>
                  </SuiTypography> */}
                </SuiBox>
              </SuiBox>
              <Separator text={"Abstract"} />
              {paperData.abstract}
              <Separator text={"Notes"} />

              {isEditing ? (
                <>
                  <SuiEditor placeholder="Insert notes..." value={notes} onChange={setNotes} />
                  <SuiBox display="flex" justifyContent="center" mt={2}>
                    <div onClick={() => saveNotes()}>
                      <SuiButton variant="gradient" buttonColor="info">
                        Save
                      </SuiButton>
                    </div>
                  </SuiBox>
                </>
              ) : (
                <>
                  {notes && notes.replace(/(<([^>]+)>)/gi, "").length > 0 ? (
                    <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="regular"
                        textColor="text"
                      >
                        <div dangerouslySetInnerHTML={{ __html: notes }} />
                      </SuiTypography>
                    </SuiBox>
                  ) : null}
                  <SuiBox display="flex" justifyContent="center" mt={2}>
                    <div onClick={() => setIsEditing(true)}>
                      <SuiTypography
                        variant="body1"
                        textColor="secondary"
                        customClass="cursor-pointer line-height-0"
                      >
                        {notes && notes.replace(/(<([^>]+)>)/gi, "").length > 0 ? (
                          <SuiButton variant="text" size="small" buttonColor="info">
                            <Icon className="">edit</Icon> Edit notes
                          </SuiButton>
                        ) : (
                          <SuiButton variant="outlined" size="small" buttonColor="info">
                            <Icon className="">add</Icon> Add notes
                          </SuiButton>
                        )}
                      </SuiTypography>
                    </div>
                  </SuiBox>
                </>
              )}
            </SuiTypography>
          </SuiBox>
        </Collapse>
      )}
    </SuiBox>
  );
}

export default PaperCollapse;
