/* eslint-disable */
import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DefaultItem from "examples/Items/DefaultItem";
import { Divider } from "@mui/material";

function SourceCard({ data, handleDelete }) {
  const [sourceName, setSourceName] = useState("DB");

  useEffect(() => {
    if (data.sourcefile_db == "scopus") {
      setSourceName("Scopus");
    } else if (data.sourcefile_db == "pubmed") {
      setSourceName("MEDLINE PubMed");
    } else {
      setSourceName("Web of Science");
    }
  }, []);

  return (
    <SuiBox pt={2} px={2} lineHeight={1}>
      <SuiBox pt={2} px={2} lineHeight={1}>
        <SuiTypography variant="h6" fontWeight="medium">
          {sourceName}
        </SuiTypography>
        <SuiTypography variant="button" textColor="text" fontWeight="medium">
          {new Date(data.uploaded_at).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <DefaultItem
          icon="library_books"
          title="Imported papers"
          description={data.sourcefile_total_papers}
        />
        <SuiBox mt={3.5}>
          <DefaultItem
            icon="query_stats"
            title="Query used"
            description={data.sourcefile_query_used}
          />
        </SuiBox>
        {/* <Divider />
        <div onClick={handleDelete}>
          <SuiBox display="flex" justifyContent="center" alignItems="center" mx="auto">
            <SuiButton variant="gradient" buttonColor="error" size="small">
              delete
            </SuiButton>
          </SuiBox>
        </div> */}
      </SuiBox>
    </SuiBox>
  );
}

export default SourceCard;
