/* eslint-disable */
// react-countup components
import CountUp from "react-countup";
import { Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { Icon } from "@mui/material";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import Separator from "../../../components/Separator";

function OutlinedCounterCard({ color, count, title, prefix, suffix }) {
  const { secondary } = colors;
  const { borderWidth } = borders;

  return (
    <SuiBox
      borderRadius="md"
      border={`${borderWidth[1]} dotted ${secondary.main}`}
      textAlign="center"
      m={1}
    >
      <Grid container spacing={1}>
        <Grid item xs={6} lg={6}>
          <SuiTypography
            variant="p"
            textColor={color}
            fontWeight="medium"
            textTransform="capitalize"
          >
            {title}:
          </SuiTypography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <SuiTypography variant="p" fontWeight="medium">
            {Math.round(count * 10) / 10}
          </SuiTypography>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

// Setting default values for the props of OutlinedCounterCard
OutlinedCounterCard.defaultProps = {
  color: "info",
  prefix: "",
  suffix: "",
};

// Typechecking props for the BlogCard
OutlinedCounterCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default OutlinedCounterCard;
