/* eslint-disable no-console */
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";

import { useAuth } from "../../../../auth-context/auth.context";
import { Login } from "../../../../api/auth";

function Basic() {
  const history = useHistory();
  const { setUser } = useAuth();
  const { user } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [buttonText, setButtonText] = useState("Sign in");

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  useEffect(() => {
    if (user) {
      setButtonText("Go Back");
    }
  }, []);

  const setProfile = async (response) => {
    // eslint-disable-next-line no-shadow
    let user = { ...response.data.user };
    user.token = response.data.token;
    user = JSON.stringify(user);
    setUser(user);
    localStorage.setItem("user", user);
    return history.push("/dashboard");
  };

  const login = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (user && user.token) {
      return history.push("/dashboard");
    }
    if (email === "") {
      return setError("You must enter your email.");
    }
    if (password === "") {
      return setError("You must enter your password");
    }
    setButtonText("Signing in");
    try {
      const response = await Login({
        email,
        password,
      });
      if (response.data && response.data.success === false) {
        setButtonText("Sign in");
        return setError(response.data.msg);
      }
      return setProfile(response);
    } catch (err) {
      // console.log(err);
      setButtonText("Sign in");
      if (err.response) {
        return setError(err.response.data.msg);
      }
      return setError("There has been an error.");
    }
  };

  return (
    <BasicLayout title="Welcome!" description="Systematic Literature Reviews ok" image={curved9}>
      <Card>
        <SuiBox p={3} mb={1} textAlign="center">
          <SuiTypography variant="h5" fontWeight="medium">
            {user && user.token ? "You are already signed in" : "Sign in"}
          </SuiTypography>
        </SuiBox>

        <SuiBox mb={2}>
          <Socials />
        </SuiBox>
        <SuiBox p={3}>
          <SuiBox component="form" role="form">
            {user && user.token ? null : (
              <>
                <SuiBox mb={2}>
                  <SuiInput
                    defaultValue={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                      setError(undefined);
                    }}
                    type="email"
                    placeholder="Email"
                  />
                </SuiBox>
                <SuiBox mb={2}>
                  <SuiInput
                    defaultValue={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                      setError(undefined);
                    }}
                    type="password"
                    placeholder="Password"
                  />
                </SuiBox>
                <SuiBox display="flex" alignItems="center">
                  <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetRememberMe}
                    customClass="cursor-pointer user-select-none"
                  >
                    &nbsp;&nbsp;Remember me
                  </SuiTypography>
                </SuiBox>
                <SuiBox mt={2} mb={2} textAlign="center">
                  <h6
                    style={{
                      fontSize: ".7em",
                      color: "red",
                      textAlign: "center",
                      fontWeight: 400,
                      transition: ".2s all",
                    }}
                  >
                    {error}
                  </h6>
                </SuiBox>
              </>
            )}
            <SuiBox mt={4} mb={1}>
              <SuiButton onClick={login} variant="gradient" buttonColor="info" fullWidth>
                {buttonText}
              </SuiButton>
            </SuiBox>
            <Separator />
            <SuiBox mt={1} mb={3}>
              <SuiButton
                component={Link}
                to="/authentication/sign-up/basic"
                variant="gradient"
                buttonColor="dark"
                fullWidth
              >
                sign up
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
