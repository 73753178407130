/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useAuth } from "auth-context/auth.context";

import {
  URL_QUERYSTRINGS,
  URL_PAPERS,
  URL_PAPERS_OPTIONS,
  URL_PAPERS_TYPE_OPTIONS,
  URL_DATASETS,
} from "api/myslr";
import axios from "api/index";

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";
import { Icon } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";
import OutlinedCounterCard from "./components/OutlinedCounterCard";
import SuiProgress from "components/SuiProgress";

// Pricing page components
import PaperCollapse from "../PaperCollapse";
import Separator from "../components/Separator";
import Pagination from "@mui/material/Pagination";
import Datasets from "../components/Datasets";
import Slider from "@mui/material/Slider";
import styles from "../components/SliderStyle/styles";

// sweetalert2 components
import Swal from "sweetalert2";

const RenderPaperItem = React.memo((data) => {
  return (
    <PaperCollapse
      paperData={data.data}
      dataset={data.selectedDataset}
      switchUsed={data.switchUsed}
      switchConfirmed={data.switchConfirmed}
      open={data.collapse === data.index}
      setCollapse={data.setCollapse}
      collapse={data.collapse}
      index={data.index}
      isOwner={data.isOwner}
      owner={data.owner}
      update={data.updateDatasets}
    ></PaperCollapse>
  );
});

function Paper({ querystring, reload, handleTab }) {
  const { user } = useAuth();
  const classes = styles();
  const [collapse, setCollapse] = useState(false);
  const [error, setError] = useState(null);
  const [datasets, setDatasets] = useState([]);
  const [defaultDataset, setDefaultDataset] = useState();
  const [datasetStats, setDatasetsStats] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [hasPapers, setHasPapers] = useState(false);
  const [isOwner, setIsOwner] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [papersData, setPapersData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });
  const [filters, setFilters] = useState({
    openaccess: "",
    type: "",
    source: "",
    used: "",
    ordering: "",
    confirmed: "",
  });
  const [filterOptions, setFilterOptions] = useState({
    openaccessOptions: [
      { value: "Open Access", label: "Open Access" },
      { value: "Commercial", label: "Commercial" },
    ],
    typeOptions: [
      { value: "article", label: "article" },
      { value: "note", label: "note" },
      { value: "conference", label: "conference" },
      { value: "Book Chapter", label: "Book Chapter" },
    ],
    sourceOptions: [
      { value: "scopus", label: "Scopus" },
      { value: "wos", label: "Web of Science" },
      { value: "pubmed", label: "MEDLINE PubMed" },
    ],
    usedOptions: [
      { value: 1, label: "Selected" },
      { value: 0, label: "Excluded" },
      { value: 2, label: "Conflicts" },
    ],
    statusOptions: [
      { value: 1, label: "Confirmed" },
      { value: 0, label: "Not confirmed" },
    ],
    orderingOptions: [
      { value: "-year", label: "Year - desc" },
      { value: "year", label: "Year - asc" },
      { value: "-citations", label: "Citations - desc" },
      { value: "citations", label: "Citations - asc" },
      { value: "-title", label: "Title - desc" },
      { value: "title", label: "Title - asc" },
      { value: "-journal", label: "Journal - desc" },
      { value: "journal", label: "Journal - asc" },
    ],
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const entries = [10, 25, 50, 100];
  /*  const [rowsPerPage, setRowsPerPage] = useState(10); */

  const [value, setValue] = useState([0, 3000]);
  const [valueGlobal, setValueGlobal] = useState([3000, 0]);
  var min_y = 3000;
  var max_y = 0;

  function valuetext(value) {
    return `${value}`;
  }

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);

  const handleYearChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleYearTmp = (event, newValue) => {
    // console.log(newValue);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const setSelected = (id) => {
    setSelectedDataset(id);
  };

  const setDefault = (id) => {
    setDefaultDataset(id);
    // handleTab(true);
    reload();
  };

  /* const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }; */

  const handleLimitTo = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    let data = {
      source: filters.source ? filters.source["value"] : "",
      type: filters.type ? filters.type["value"] : "",
      year_start: value[0],
      year_end: value[1],
    };

    newSwal
      .fire({
        title: "Are you sure?",
        text: "All visible papers will be marked as selected, the others will be marked as excluded. You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, just do it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          axios
            .post(`${URL_DATASETS}/${selectedDataset}/limit_papers`, data, {
              headers: { Authorization: `${user.token}` },
            })
            .then((response) => {
              handleFetch();
              updateDatasets();
            })
            .catch((_error) => {
              setError(_error);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          newSwal.fire("Cancelled", "Nothing done!", "error");
        }
      });
  };

  const handleExclude = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    let data = {
      source: filters.source ? filters.source["value"] : "",
      type: filters.type ? filters.type["value"] : "",
      year_start: value[0],
      year_end: value[1],
    };

    newSwal
      .fire({
        title: "Are you sure?",
        text: "All visible papers will be marked as excluded. You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, just do it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          axios
            .post(`${URL_DATASETS}/${selectedDataset}/exclude_papers`, data, {
              headers: { Authorization: `${user.token}` },
            })
            .then((response) => {
              handleFetch();
              updateDatasets();
            })
            .catch((_error) => {
              setError(_error);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          newSwal.fire("Cancelled", "Nothing done!", "error");
        }
      });
  };

  // const handleFilterOpenChange = (option) => {
  //   if (option == null) {
  //     setFilters((prevState) => ({
  //       ...prevState,
  //       openaccess: "",
  //     }));
  //   } else {
  //     setFilters((prevState) => ({
  //       ...prevState,
  //       openaccess: { value: option.value, label: option.value },
  //     }));
  //   }
  //   setPage(1);
  // };

  const handleFilteTypeChange = (option) => {
    let choices = [];
    option.map((o) => {
      choices.push(o.value);
    });
    if (option == null) {
      setFilters((prevState) => ({
        ...prevState,
        type: "",
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        type: { value: choices.join(","), label: option.value },
      }));
    }
    setPage(1);
  };

  const handleFilterOrderChange = (option) => {
    let choices = [];
    option.map((o) => {
      choices.push(o.value);
    });
    if (option == null) {
      setFilters((prevState) => ({
        ...prevState,
        ordering: "",
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        ordering: { value: choices.join(","), label: option.value },
      }));
    }
    setPage(1);
  };

  const handleFilterUsedChange = (option) => {
    if (option == null) {
      setFilters((prevState) => ({
        ...prevState,
        used: "",
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        used: option.value,
      }));
    }
    setPage(1);
  };

  const handleFilterConfirmedChange = (option) => {
    if (option == null) {
      setFilters((prevState) => ({
        ...prevState,
        confirmed: "",
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        confirmed: option.value,
      }));
    }
    setPage(1);
  };

  const handleFilterSourceChange = (option) => {
    if (option == null) {
      setFilters((prevState) => ({
        ...prevState,
        source: "",
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        source: option,
      }));
    }
    setPage(1);
  };

  const switchUsed = React.useCallback(
    (fetch) => {
      if (fetch) {
        handleFetch();
      }
    },
    [selectedDataset, pageSize, page, filters]
  );

  const switchConfirmed = React.useCallback(
    (fetch) => {
      if (fetch) {
        handleFetch();
      }
    },
    [selectedDataset, pageSize, page, filters]
  );

  const updateDatasets = React.useCallback(() => {
    axios
      .get(`${URL_DATASETS}?dataset_querystring=${querystring.id}`, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        setDatasets(response.data);
      })
      .catch((_error) => {
        setError(_error);
      });
  }, []);

  const fetchDatasets = () => {
    axios
      .get(`${URL_DATASETS}?dataset_querystring=${querystring.id}`, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        setDatasets(response.data);
        if (response.data.length > 0) {
          setHasPapers(true);
          setSelectedDataset(querystring.default_dataset);
        }
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const handleFetch = () => {
    setIsFetching(true);
    axios
      .get(
        `${URL_PAPERS}?datasets_id=${selectedDataset}&ordering=${
          filters.ordering ? filters.ordering["value"] : "-citations"
        }&page=${page}&limit=${pageSize}&openaccess=${
          filters.openaccess ? filters.openaccess["value"] : ""
        }&type__in=${filters.type ? filters.type["value"] : ""}&search=${
          filters.source ? filters.source["value"] : ""
        }&used=${filters.used}&confirmed=${filters.confirmed}&year_start=${value[0]}&year_end=${
          value[1]
        }`,
        {
          headers: { Authorization: `${user.token}` },
        }
      )
      .then((response) => {
        //console.log("RESPONSE: ", response.data.results);
        let rich_results = [];
        response.data.results.map((item) => {
          let ds = item.datasets.find((d) => d.dataset_version === selectedDataset);
          item["used"] = ds.used;
          item["confirmed"] = ds.confirmed;
          item["notes"] = ds.notes;
          let cs = item.conflicts.filter((c) => c.dataset_version === selectedDataset);
          item["conf"] = cs;
          rich_results.push(item);
        });
        setPapersData({
          results: rich_results,
          count: response.data.count,
          totalePages: response.data.total_pages,
          next: response.data.next,
          previous: response.data.previous,
        });
        setIsFetching(false);
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const fetchOptions = () => {
    axios
      .get(`${URL_PAPERS_OPTIONS}?paper_querystring=${querystring.id}`, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        let data = [];
        response.data.map((item, index) => {
          data.push({
            value: item["openaccess"],
            label: item["openaccess"],
          });
        });
        setFilterOptions((prevState) => ({
          ...prevState,
          openaccessOptions: data,
        }));
      })
      .catch((_error) => {
        setError(_error);
      });

    axios
      .get(
        `${URL_PAPERS_TYPE_OPTIONS}?datasets_id=${selectedDataset}&search=${
          filters.source ? filters.source["value"] : ""
        }`,
        {
          headers: { Authorization: `${user.token}` },
        }
      )
      .then((response) => {
        let types = [];
        response.data.map((item, index) => {
          types.push({
            value: item["type"],
            label: item["type"],
          });
        });
        setFilterOptions((prevState) => ({
          ...prevState,
          typeOptions: types,
        }));
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  useEffect(() => {
    if (hasPapers) {
      if (selectedDataset) {
        handleFetch();
        fetchOptions();
      }
    }
  }, [page, filters, selectedDataset, value, pageSize]);

  useEffect(() => {
    let result = datasets.find((e) => e.id === selectedDataset);
    setDatasetsStats(result);
    // console.log(result);
  }, [datasets, selectedDataset]);

  useEffect(() => {
    datasets[0] &&
      datasets.map((d) => {
        // setValue(null);
        let statsLabels = [];

        d.papers_year_stats_total.map((item) => {
          if (item.year > 0) {
            statsLabels.push(item.year);
          } else {
            statsLabels.push(1980);
          }
        });

        let min = Math.min.apply(null, statsLabels);
        let max = Math.max.apply(null, statsLabels);

        if (min < min_y) {
          min_y = min;
        }

        if (max > max_y) {
          max_y = max;
        }

        setValue([min_y, max_y]);
        setValueGlobal([min_y, max_y]);
      });
  }, [selectedDataset]);

  useEffect(() => {
    fetchDatasets();
    if (querystring.owner.email === user.email) {
      setIsOwner(true);
    } else {
      setIsOwner(false);
    }
  }, []);

  return (
    <SuiBox p={1}>
      <SuiBox mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} lg={12}>
            {hasPapers ? (
              <Datasets
                datasets={datasets}
                selectDataset={setSelected}
                selectDefault={setDefault}
                querystring={querystring}
                refetch={fetchDatasets}
                reload={reload}
              />
            ) : (
              <SuiTypography variant="h4" align="center" fontWeight="bold" gutterBottom>
                Please import papers before visit this section
              </SuiTypography>
            )}
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox p={1}>
        <Grid container justifyContent="center" columns={{ xs: 6, md: 12 }}>
          {hasPapers && datasetStats && (
            <>
              <Grid item xs={12} md={12}>
                {/* <SuiTypography variant="h4" align="center" fontWeight="bold" gutterBottom>
                Papers: {papersData.count}{" "}
              </SuiTypography> */}

                <SuiBox mb={1}>
                  <SuiTypography variant="body2" align="center" textColor="text">
                    Section to include or exclude papers for analysis
                  </SuiTypography>
                </SuiBox>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3} lg={3}>
                  <OutlinedCounterCard
                    count={
                      datasetStats.total_papers -
                      datasetStats.excluded_papers -
                      datasetStats.conflicts
                    }
                    title="Selected"
                    color="success"
                  />
                </Grid>
                <Grid item xs={3} lg={3}>
                  <OutlinedCounterCard
                    count={datasetStats.excluded_papers}
                    title="Excluded"
                    color="error"
                  />
                </Grid>
                <Grid item xs={3} lg={3}>
                  <OutlinedCounterCard
                    count={datasetStats.conflicts}
                    title="Conflicts"
                    color="warning"
                  />
                </Grid>
                <Grid item xs={3} lg={3}>
                  <OutlinedCounterCard
                    count={datasetStats.confirmed_papers}
                    title="Confirmed"
                    color="info"
                  />
                  <Box sx={{ width: "100%" }} ml={1} mr={1}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ width: "100%", mr: 1 }}>
                        <SuiProgress
                          value={(datasetStats.confirmed_papers / datasetStats.total_papers) * 100}
                          variant="contained"
                          color={
                            datasetStats.confirmed_papers === datasetStats.total_papers
                              ? "success"
                              : "warning"
                          }
                          noLabel
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <SuiTypography variant="caption">{`${Math.round(
                          (datasetStats.confirmed_papers / datasetStats.total_papers) * 100
                        )}%`}</SuiTypography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <Grid container>
          {hasPapers && (
            <>
              <Grid container spacing={1} justifyContent={"space-between"}>
                {/* <Grid item xs={3}>
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Open Access
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  value={filters.openaccess}
                  options={filterOptions.openaccessOptions}
                  isClearable={true}
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={handleFilterOpenChange}
                />
              </Grid> */}

                <Grid item xs={2}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Year
                    </SuiTypography>
                  </SuiBox>
                  {value[0] && (
                    <Slider
                      defaultValue={value}
                      key={`slider-${value}`}
                      min={valueGlobal[0]}
                      max={valueGlobal[1]}
                      onChangeCommitted={handleYearChange}
                      onChange={handleYearTmp}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      className={classes.automotiveMonitor_slider}
                      step={1}
                    />
                  )}
                  <SuiBox lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      {value[0]}
                    </SuiTypography>
                    {" - "}
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      {value[1]}
                    </SuiTypography>
                  </SuiBox>
                </Grid>

                <Grid item xs={2}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Source database
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    defaultValue={filters.source}
                    options={filterOptions.sourceOptions}
                    isClearable={true}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleFilterSourceChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Document type
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    defaultValue={filters.type}
                    options={filterOptions.typeOptions}
                    isClearable={true}
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleFilteTypeChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Selection status
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    defaultValue={filters.used}
                    options={filterOptions.usedOptions}
                    isClearable={true}
                    onChange={handleFilterUsedChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Ordering
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    defaultValue={filters.order}
                    options={filterOptions.orderingOptions}
                    isClearable={true}
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleFilterOrderChange}
                  />
                </Grid>

                <Grid item xs={2}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Confirmed status
                    </SuiTypography>
                  </SuiBox>

                  <SuiSelect
                    defaultValue={filters.confirmed}
                    options={filterOptions.statusOptions}
                    isClearable={true}
                    onChange={handleFilterConfirmedChange}
                  />
                </Grid>

                {/* <Grid item xs={1}>
                  <SuiBox mb={1} lineHeight={0}>
                    <div onClick={handleLimitTo}>
                      <SuiButton variant="gradient" buttonColor="info" size="small" fullWidth>
                        limit
                      </SuiButton>
                    </div>
                  </SuiBox>
                  <div onClick={handleExclude}>
                    <SuiButton variant="gradient" buttonColor="error" size="small" fullWidth>
                      exclude
                    </SuiButton>
                  </div>
                </Grid> */}
              </Grid>

              <Grid container justifyContent="flex-end">
                <SuiBox mt={1} ml={2} lineHeight={1}>
                  <div onClick={handleExclude}>
                    <SuiButton variant="gradient" buttonColor="error" size="small" fullWidth>
                      exclude
                    </SuiButton>
                  </div>
                </SuiBox>
                <SuiBox mt={1} ml={2} lineHeight={1}>
                  <div onClick={handleLimitTo}>
                    <SuiButton variant="gradient" buttonColor="info" size="small" fullWidth>
                      limit
                    </SuiButton>
                  </div>
                </SuiBox>
              </Grid>

              <Grid container spacing={1} p={2}>
                <Grid item xs={2} md={2}>
                  <SuiBox display="flex" alignItems="center">
                    <SuiSelect
                      defaultValue={{ value: pageSize, label: pageSize }}
                      options={entries.map((entry) => ({ value: entry, label: entry }))}
                      onChange={setEntriesPerPage}
                      size="small"
                    />
                    <SuiTypography variant="caption" textColor="secondary">
                      &nbsp;&nbsp;entries per page
                    </SuiTypography>
                  </SuiBox>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Separator text={"PAPERS (" + papersData.count + ")"} />
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end">
                <SuiTypography variant="button" textColor="info">
                  CONFIRMED
                </SuiTypography>
              </Grid>

              {isFetching
                ? null
                : papersData.results.map((item, index) => (
                    <Grid item xs={12} md={12}>
                      <RenderPaperItem
                        data={item}
                        index={item.id}
                        key={item.id}
                        isOwner={isOwner}
                        collapse={collapse}
                        selectedDataset={selectedDataset}
                        switchUsed={switchUsed}
                        switchConfirmed={switchConfirmed}
                        setCollapse={setCollapse}
                        owner={querystring.owner}
                        updateDatasets={updateDatasets}
                      />
                    </Grid>
                  ))}

              <Pagination
                shape="rounded"
                count={papersData.totalePages}
                page={page}
                onChange={handleChange}
              />
            </>
          )}
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default Paper;
