/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO Material are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
/* eslint-disable */
// Soft UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import Automotive from "layouts/dashboards/automotive";
import SmartHome from "layouts/dashboards/smart-home";
import VRDefault from "layouts/dashboards/virtual-reality/vr-default";
import VRInfo from "layouts/dashboards/virtual-reality/vr-info";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import Reports from "layouts/pages/users/reports";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Security from "layouts/pages/account/security";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
// import NewProject from "layouts/pages/projects/new-project";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import SweetAlerts from "layouts/pages/sweet-alerts";
import Notifications from "layouts/pages/notifications";
import PricingPage from "layouts/pages/pricing-page";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import Analytics from "layouts/applications/analytics";
import Overview from "layouts/ecommerce/overview";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import ProductsList from "layouts/ecommerce/products/products-list";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import Referral from "layouts/ecommerce/referral";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import SignUpCover from "layouts/authentication/sign-up/cover";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import SignOut from "layouts/authentication/sign-out";
import ResetBasic from "layouts/authentication/reset-password/basic";
import ResetCover from "layouts/authentication/reset-password/cover";
import ResetIllustration from "layouts/authentication/reset-password/illustration";
import LockBasic from "layouts/authentication/lock/basic";
import LockCover from "layouts/authentication/lock/cover";
import LockIllustration from "layouts/authentication/lock/illustration";
import VerificationBasic from "layouts/authentication/2-step-verification/basic";
import VerificationCover from "layouts/authentication/2-step-verification/cover";
import VerificationIllustration from "layouts/authentication/2-step-verification/illustration";
import Error403 from "layouts/authentication/error/403";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";

// mySLR Layouts
import Homepage from "layouts/myslr/home";
import Landing from "layouts/myslr/whatisslr";
import HowToUse from "layouts/myslr/howtouse";
import TeamPage from "layouts/myslr/team-page";
import ProjectsList from "layouts/myslr/projects-list";
import NewProject from "layouts/myslr/new-project";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import SettingsIcon from "examples/Icons/Settings";
import Basket from "examples/Icons/Basket";
import Home from "examples/Icons/Home";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CreditCard from "examples/Icons/CreditCard";

const routes = [
  {
    type: "collapse",
    name: "Homepage",
    key: "homepage",
    route: "/homepage",
    component: Homepage,
    icon: <Home size="12px" />,
    noCollapse: true,
  },
  { type: "title", title: "Main", key: "title-projects" },
  // {
  //   name: "Home",
  //   key: "homepage",
  //   route: "/homepage",
  //   component: Homepage,
  // },
  {
    type: "collapse",
    name: "Research projects",
    key: "myslr",
    icon: <Office size="16px" />,
    collapse: [
      {
        name: "All projects",
        key: "all-projects",
        route: "/myslr/projects-list",
        component: ProjectsList,
        protected: true,
      },
      {
        name: "New project",
        key: "new-project",
        route: "/myslr/new-project",
        component: NewProject,
        protected: true,
      },
    ],
  },

  // { type: "title", title: "Dashboards", key: "title-dashboards" },
  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <Shop size="12px" />,
  //   collapse: [
  //     {
  //       name: "Default",
  //       key: "default",
  //       route: "/dashboards/default",
  //       component: Default,
  //       protected: true,
  //     },
  //     {
  //       name: "Automotive",
  //       key: "automotive",
  //       route: "/dashboards/automotive",
  //       component: Automotive,
  //       protected: true,
  //     },
  //     {
  //       name: "Smart Home",
  //       key: "smart-home",
  //       route: "/dashboards/smart-home",
  //       component: SmartHome,
  //       protected: true,
  //     },
  //     {
  //       name: "Virtual Reality",
  //       key: "virtual-reality",
  //       collapse: [
  //         {
  //           name: "VR Default",
  //           key: "vr-default",
  //           route: "/dashboards/virtual-reality/default",
  //           component: VRDefault,
  //           protected: true,
  //         },
  //         {
  //           name: "VR Info",
  //           key: "vr-info",
  //           route: "/dashboards/virtual-reality/info",
  //           component: VRInfo,
  //           protected: true,
  //         },
  //       ],
  //     },
  //     { name: "CRM", key: "crm", route: "/dashboards/crm", component: CRM, protected: true },
  //   ],
  // },

  // { type: "title", title: "Pages", key: "title-pages" },
  // {
  //   type: "collapse",
  //   name: "Pages",
  //   key: "pages",
  //   icon: <Office size="12px" />,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "profile",
  //       collapse: [
  //         {
  //           name: "Profile Overview",
  //           key: "profile-overview",
  //           route: "/pages/profile/profile-overview",
  //           component: ProfileOverview,
  //           protected: true,
  //         },
  //         {
  //           name: "Teams",
  //           key: "teams",
  //           route: "/pages/profile/teams",
  //           component: Teams,
  //           protected: true,
  //         },
  //         {
  //           name: "All Projects",
  //           key: "all-projects",
  //           route: "/pages/profile/all-projects",
  //           component: AllProjects,
  //           protected: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       key: "users",
  //       collapse: [
  //         {
  //           name: "Reports",
  //           key: "reports",
  //           route: "/pages/users/reports",
  //           component: Reports,
  //           protected: true,
  //         },
  //         {
  //           name: "New User",
  //           key: "new-user",
  //           route: "/pages/users/new-user",
  //           component: NewUser,
  //           protected: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Account",
  //       key: "account",
  //       collapse: [
  //         {
  //           name: "Settings",
  //           key: "settings",
  //           route: "/pages/account/settings",
  //           component: Settings,
  //           protected: true,
  //         },
  //         {
  //           name: "Billing",
  //           key: "billing",
  //           route: "/pages/account/billing",
  //           component: Billing,
  //           protected: true,
  //         },
  //         {
  //           name: "Invoice",
  //           key: "invoice",
  //           route: "/pages/account/invoice",
  //           component: Invoice,
  //           protected: true,
  //         },
  //         {
  //           name: "Security",
  //           key: "security",
  //           route: "/pages/account/security",
  //           component: Security,
  //           protected: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Projects",
  //       key: "projects",
  //       collapse: [
  //         {
  //           name: "General",
  //           key: "general",
  //           route: "/pages/projects/general",
  //           component: General,
  //           protected: true,
  //         },
  //         {
  //           name: "Timeline",
  //           key: "timeline",
  //           route: "/pages/projects/timeline",
  //           component: Timeline,
  //           protected: true,
  //         },
  //         {
  //           name: "New Project",
  //           key: "new-project",
  //           route: "/pages/projects/new-project",
  //           component: NewProject,
  //           protected: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Pricing Page",
  //       key: "pricing-page",
  //       route: "/pages/pricing-page",
  //       component: PricingPage,
  //       protected: true,
  //     },
  //     { name: "RTL", key: "rtl", route: "/pages/rtl", component: RTL, protected: true },
  //     {
  //       name: "Widgets",
  //       key: "widgets",
  //       route: "/pages/widgets",
  //       component: Widgets,
  //       protected: true,
  //     },
  //     { name: "Charts", key: "charts", route: "/pages/charts", component: Charts, protected: true },
  //     {
  //       name: "Sweet Alerts",
  //       key: "sweet-alerts",
  //       route: "/pages/sweet-alerts",
  //       component: SweetAlerts,
  //       protected: true,
  //     },
  //     {
  //       name: "Notfications",
  //       key: "notifications",
  //       route: "/pages/notifications",
  //       component: Notifications,
  //       protected: true,
  //     },
  //   ],
  // },

  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications",
  //   icon: <SettingsIcon size="12px" />,
  //   collapse: [
  //     {
  //       name: "Kanban",
  //       key: "kanban",
  //       route: "/applications/kanban",
  //       component: Kanban,
  //       protected: true,
  //     },
  //     {
  //       name: "Wizard",
  //       key: "wizard",
  //       route: "/applications/wizard",
  //       component: Wizard,
  //       protected: true,
  //     },
  //     {
  //       name: "Data Tables",
  //       key: "data-tables",
  //       route: "/applications/data-tables",
  //       component: DataTables,
  //       protected: true,
  //     },
  //     {
  //       name: "Calendar",
  //       key: "calendar",
  //       route: "/applications/calendar",
  //       component: Calendar,
  //       protected: true,
  //     },
  //     {
  //       name: "Analytics",
  //       key: "analytics",
  //       route: "/applications/analytics",
  //       component: Analytics,
  //       protected: true,
  //     },
  //   ],
  // },

  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Basket size="12px" />,
  //   collapse: [
  //     {
  //       name: "Overview",
  //       key: "overview",
  //       route: "/ecommerce/overview",
  //       component: Overview,
  //       protected: true,
  //     },
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: NewProduct,
  //           protected: true,
  //         },
  //         {
  //           name: "Edit Product",
  //           key: "edit-product",
  //           route: "/ecommerce/products/edit-product",
  //           component: EditProduct,
  //           protected: true,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: ProductPage,
  //           protected: true,
  //         },
  //         {
  //           name: "Products List",
  //           key: "products-list",
  //           route: "/ecommerce/products/products-list",
  //           component: ProductsList,
  //           protected: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Orders",
  //       key: "orders",
  //       collapse: [
  //         {
  //           name: "Order List",
  //           key: "order-list",
  //           route: "/ecommerce/orders/order-list",
  //           component: OrderList,
  //           protected: true,
  //         },
  //         {
  //           name: "Order Details",
  //           key: "order-details",
  //           route: "/ecommerce/orders/order-details",
  //           component: OrderDetails,
  //           protected: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Referral",
  //       key: "referral",
  //       route: "/ecommerce/referral",
  //       component: Referral,
  //       protected: true,
  //     },
  //   ],
  // },

  {
    type: "collapse",
    name: "Profile",
    key: "authentication",
    icon: <Document size="12px" />,
    collapse: [
      // {
      //   name: "Sign In",
      //   key: "sign-in",
      //   collapse: [
      //     {
      //       name: "Basic",
      //       key: "basic",
      //       route: "/authentication/sign-in/basic",
      //       component: SignInBasic,
      //     },
      //     {
      //       name: "Cover",
      //       key: "cover",
      //       route: "/authentication/sign-in/cover",
      //       component: SignInCover,
      //     },
      //     {
      //       name: "Illustration",
      //       key: "illustration",
      //       route: "/authentication/sign-in/illustration",
      //       component: SignInIllustration,
      //     },
      //   ],
      // },
      // {
      //   name: "Sign Up",
      //   key: "sign-up",
      //   collapse: [
      //     {
      //       name: "Basic",
      //       key: "basic",
      //       route: "/authentication/sign-up/basic",
      //       component: SignUpBasic,
      //     },
      //     {
      //       name: "Cover",
      //       key: "cover",
      //       route: "/authentication/sign-up/cover",
      //       component: SignUpCover,
      //     },
      //     {
      //       name: "Illustration",
      //       key: "illustration",
      //       route: "/authentication/sign-up/illustration",
      //       component: SignUpIllustration,
      //     },
      //   ],
      // },

      {
        name: "Sign Out",
        key: "sign-out",
        route: "/authentication/sign-out",
        component: SignOut,
      },

      // {
      //   name: "Reset Password",
      //   key: "reset-password",
      //   collapse: [
      //     {
      //       name: "Basic",
      //       key: "basic",
      //       route: "/authentication/reset-password/basic",
      //       component: ResetBasic,
      //     },
      //     {
      //       name: "Cover",
      //       key: "cover",
      //       route: "/authentication/reset-password/cover",
      //       component: ResetCover,
      //     },
      //     {
      //       name: "Illustration",
      //       key: "illustration",
      //       route: "/authentication/reset-password/illustration",
      //       component: ResetIllustration,
      //     },
      //   ],
      // },
      // {
      //   name: "Lock",
      //   key: "lock",
      //   collapse: [
      //     {
      //       name: "Basic",
      //       key: "basic",
      //       route: "/authentication/lock/basic",
      //       component: LockBasic,
      //     },
      //     {
      //       name: "Cover",
      //       key: "cover",
      //       route: "/authentication/lock/cover",
      //       component: LockCover,
      //     },
      //     {
      //       name: "Illustration",
      //       key: "illustration",
      //       route: "/authentication/lock/illustration",
      //       component: LockIllustration,
      //     },
      //   ],
      // },
      // {
      //   name: "2-Step Verification",
      //   key: "2-step-verification",
      //   collapse: [
      //     {
      //       name: "Basic",
      //       key: "basic",
      //       route: "/authentication/verification/basic",
      //       component: VerificationBasic,
      //     },
      //     {
      //       name: "Cover",
      //       key: "cover",
      //       route: "/authentication/verification/cover",
      //       component: VerificationCover,
      //     },
      //     {
      //       name: "Illustration",
      //       key: "illustration",
      //       route: "/authentication/verification/illustration",
      //       component: VerificationIllustration,
      //     },
      //   ],
      // },
      // {
      //   name: "Error",
      //   key: "error",
      //   collapse: [
      //     {
      //       name: "Error 403",
      //       key: "error-403",
      //       route: "/authentication/error/403",
      //       component: Error403,
      //     },
      //     {
      //       name: "Error 404",
      //       key: "error-404",
      //       route: "/authentication/error/404",
      //       component: Error404,
      //     },
      //     {
      //       name: "Error 500",
      //       key: "error-500",
      //       route: "/authentication/error/500",
      //       component: Error500,
      //     },
      //   ],
      // },
    ],
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Help", key: "title-docs" },
  {
    type: "collapse",
    name: "MySLR Docs",
    key: "basic",
    icon: <SpaceShip size="12px" />,
    collapse: [
      {
        name: "What is a SLR",
        key: "whatis",
        route: "/myslr/what-is-slr",
        component: Landing,
      },
      {
        name: "How to Use",
        key: "howtouse",
        route: "/myslr/how-to-use",
        component: HowToUse,
      },
      {
        name: "Research Team",
        key: "howtouse",
        route: "/myslr/team-page",
        component: TeamPage,
      },
      // {
      //   name: "Research Team",
      //   key: "team",
      //   collapse: [
      //     {
      //       name: "Unical",
      //       key: "unical",
      //       href: "https://www.unical.it/",
      //     },
      //     {
      //       name: "Dimeg",
      //       key: "dimeg",
      //       href: "https://www2.unical.it/portale/strutture/dipartimenti_240/dimeg/",
      //     },
      //   ],
      // },
    ],
  },

  /*   
  {
    type: "collapse",
    name: "LIVE Support",
    key: "support",
    route: "https://appseed.us/support",
    icon: <CreditCard size="12px" />,
    noCollapse: true,
  },
  */
];

export default routes;
