/* eslint-disable */
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "auth-context/auth.context";
import axios from "api/index";

import { URL_QUERYSTRINGS, URL_DATASETS, URL_DATASETS_KEYWORDS } from "api/myslr";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import OutlinedCounterCard from "./components/OutlinedCounterCard";

import ComplexReportsDoughnutChart from "./components/SourceChart";
import YearBarChart from "./components/YearBarChart";

// Image
import scopus from "assets/images/small-logos/scopus.png";
import wos from "assets/images/small-logos/wos.png";
import pubmed from "assets/images/small-logos/pubmed.jpg";
import DataTable from "./DataTable";
// Wordcloud
import WordCloud from "react-d3-cloud";
//import wordCloudData from "./data/wordcloud.json";
// Data
import ActionCell from "./components/ActionCell";
import { TailSpin, MutatingDots } from "react-loader-spinner";

function Summary({ dataset_id, querystring_id }) {
  const { user } = useAuth();
  const [error, setError] = useState(null);
  const [dataset, setDataset] = useState(null);
  const [summaryData, setSummaryData] = useState({
    totalPapers: 0,
    excludedPapers: 0,
    sources: [],
  });
  const [maxSize, setMaxSize] = useState(3000);
  const [wordcloudData, setWordcloudData] = useState([]);

  const [sourceChartData, setSourceChartData] = useState({
    images: [],
    labels: [],
    datasets: {
      label: "",
      backgroundColors: [],
      data: [],
    },
  });

  const [yearStatsData, setYearStatsData] = useState({
    labels: [],
    datasets: [
      {
        label: "Paper",
        color: "info",
        data: [],
      },
    ],
    load: false,
  });

  const [keywordsData, setKeywordsData] = useState({
    columns: [
      { Header: "keyword", accessor: "text", width: "25%" },
      { Header: "value", accessor: "value", width: "40%" },
      { Header: "actions", accessor: "actions", width: "10%" },
    ],
    rows: [],
  });

  const [timerCheck, setTimerCheck] = useState(0);

  /* const maxSize = (data) => {
    if (data.length > 0) return Math.max(...data.map((b) => b.value));
    else return 0;
  }; */

  //const fontSize = useCallback((word) => (Math.log2(word.value) * word.value) / 200, []);
  const fontSize = useCallback((word) => (word.value / maxSize) * 100, [maxSize]);
  //const fontSize = useCallback((word) => (word.value / maxSize(wordCloudData)) * 50, []);

  const onWordClick = useCallback((word) => {
    // console.log(`onWordClick: ${word}`);
  }, []);
  const onWordMouseOver = useCallback((word) => {
    // console.log(word);
  }, []);
  const onWordMouseOut = useCallback((word) => {
    // console.log(`onWordMouseOut: ${word}`);
  }, []);

  const colors = ["primary", "info", "success", "primary", "error", "secondary"];

  const handleFetch = () => {
    axios
      .get(`${URL_DATASETS}/${dataset_id}`, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        let chartData = yearStatsData;
        let statsLabels = [];
        let statsData = [];
        response.data.papers_year_stats.map((item) => {
          statsLabels.push(item.year);
          statsData.push(item.total_count);
        });
        chartData.labels = statsLabels;
        chartData.datasets[0].data = statsData;
        chartData.datasets[0].label = "Papers";
        chartData.datasets[0].color = "info";
        chartData.load = true;

        setYearStatsData(chartData);

        setSummaryData((prevState) => ({
          ...prevState,
          totalPapers: response.data.total_papers,
          excludedPapers: response.data.excluded_papers,
        }));
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const handleFetchSources = () => {
    axios
      .get(`${URL_QUERYSTRINGS}/${querystring_id}`, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        setSummaryData((prevState) => ({
          ...prevState,
          sources: response.data.sourcefiles,
        }));
        setDataset(response.data.default_dataset);

        let data = sourceChartData.datasets;
        let counter = 0;
        let colors_temp = [];
        let sources = [];
        let images = [];
        let papers = [];
        response.data.sourcefiles.map((item) => {
          sources.push(item.sourcefile_db);
          if (item.sourcefile_db === "wos") {
            images.push(wos);
          } else if (item.sourcefile_db === "scopus") {
            images.push(scopus);
          } else {
            images.push(pubmed);
          }

          papers.push(item.sourcefile_total_papers);
          colors_temp.push(colors[counter]);
          counter++;
        });
        data.backgroundColors = colors_temp;
        data.data = papers;
        data.label = "Source Databases";

        setSourceChartData((prevState) => ({
          ...prevState,
          images: images,
          labels: sources,
          datasets: data,
        }));
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const handleFetchKeywords = () => {
    axios
      .get(`${URL_DATASETS_KEYWORDS}?dataset_id=${dataset_id}`, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        let all_keywords = [];
        if (response.data.length === 0) {
          setTimerCheck(timer);
        }
        response.data.map((item) => {
          let single = {};
          single["id"] = item["id"];
          single["text"] = item["key"];
          item.datasets.map((d) => {
            if (d.dataset === dataset) single["value"] = d["value"];
          });
          all_keywords.push(single);
        });
        setWordcloudData(all_keywords);
        let refilled = all_keywords.map((v) => ({
          ...v,
          actions: (
            <ActionCell id={v.id} text={v.text} dataset={dataset_id} reload={handleFetchKeywords} />
          ),
        }));
        setKeywordsData((prevState) => ({
          ...prevState,
          rows: refilled,
        }));
        setMaxSize(Math.max(...all_keywords.map((b) => b.value)));
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  useEffect(() => {
    handleFetchSources();
  }, []);

  useEffect(() => {
    if (dataset !== null) {
      handleFetch();
      handleFetchKeywords();
    }
  }, [dataset]);

  let timer;

  useEffect(() => {
    if (dataset !== null && wordcloudData.length === 0) {
      timer = setTimeout(() => {
        handleFetchKeywords();
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [timerCheck, dataset]);

  // useEffect(() => {
  //   console.log(wordcloudData);
  // }, [wordcloudData]);

  return (
    <>
      {dataset !== null ? (
        <>
          <SuiBox my={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <OutlinedCounterCard
                  count={summaryData.totalPapers}
                  title="Unique papers"
                  color="info"
                  tooltip="Initial sample of papers. It is the set of papers resulting from the union of the two sources samples after eliminating duplications based on the DOI code"
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <OutlinedCounterCard
                  count={summaryData.totalPapers - summaryData.excludedPapers}
                  title="Selected papers"
                  color="success"
                  tooltip="It is the definitive sample for analysis. This sample is given by the initial sample minus the manually"
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <OutlinedCounterCard
                  count={summaryData.excludedPapers}
                  title="Excluded papers"
                  color="error"
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <OutlinedCounterCard
                  count={
                    ((summaryData.totalPapers - summaryData.excludedPapers) /
                      summaryData.totalPapers) *
                    100
                  }
                  suffix="%"
                  title="Selected"
                />
              </Grid>
            </Grid>
          </SuiBox>
          <SuiBox my={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Card>
                  <ComplexReportsDoughnutChart
                    title="Sources"
                    chart={sourceChartData}
                    tooltip="Papers imported from scientific databases"
                    /* action={{
                      type: "internal",
                      route: "/",
                      color: "secondary",
                      label: "see all referrals",
                    }} */
                  />
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                {wordcloudData.length !== 0 ? (
                  <Card>
                    <SuiBox mb={1}>
                      <SuiTypography variant="h6" textAlign="center">
                        Words Cloud
                      </SuiTypography>
                    </SuiBox>
                    <WordCloud
                      data={wordcloudData}
                      // text={(d) => d.text}
                      height={window.innerHeight / 4}
                      /*
                      width={window.innerWidth} */
                      /* width={800}
                      height={480} */
                      //fontSize={fontSize}
                      fontSize={fontSize}
                      rotate={0}
                      padding={0.5}
                      onWordClick={onWordClick}
                      onWordMouseOver={onWordMouseOver}
                      onWordMouseOut={onWordMouseOut}
                    />
                  </Card>
                ) : (
                  <Card>
                    <SuiBox mb={1}>
                      <SuiTypography variant="h6" textAlign="center">
                        Words Cloud
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <MutatingDots height={150} width={150} />
                    </SuiBox>
                  </Card>
                )}
              </Grid>
            </Grid>
          </SuiBox>
          <SuiBox my={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                {yearStatsData.load && (
                  <YearBarChart title="Papers pubblication over time" chart={yearStatsData} />
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                {wordcloudData.length !== 0 ? (
                  <Card>
                    <SuiBox mb={1}>
                      <SuiTypography variant="h6" textAlign="center">
                        Most relevant terms
                      </SuiTypography>
                    </SuiBox>
                    <DataTable
                      table={keywordsData}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      isSorted={false}
                    />
                  </Card>
                ) : (
                  <Card textAlign="center">
                    <SuiBox mb={1}>
                      <SuiTypography variant="h6" textAlign="center">
                        Most relevant terms
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <MutatingDots height={150} width={150} />
                    </SuiBox>
                  </Card>
                )}
              </Grid>
            </Grid>
          </SuiBox>
        </>
      ) : (
        <SuiTypography variant="h4" align="center" fontWeight="bold" gutterBottom>
          Please select a default dataset in previous section.
        </SuiTypography>
      )}
    </>
  );
}

export default Summary;
