/* eslint-disable */

import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";

import { Divider } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";
// import Footer from "layouts/myslr/home/components/Footer";
import { useAuth } from "../../../../auth-context/auth.context";
import { Login } from "../../../../api/auth";

function Cover() {
  const history = useHistory();
  const { setUser } = useAuth();
  const { user } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [buttonText, setButtonText] = useState("Sign in");

  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const setProfile = async (response) => {
    // eslint-disable-next-line no-shadow
    let user = { ...response.data.user };
    user.token = response.data.token;
    user = JSON.stringify(user);
    setUser(user);
    localStorage.setItem("user", user);
    return history.push("/myslr/projects-list");
  };

  const login = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (user && user.token) {
      return history.push("/myslr/projects-list");
    }
    if (email === "") {
      return setError("You must enter your email.");
    }
    if (password === "") {
      return setError("You must enter your password");
    }
    setButtonText("Signing in");
    try {
      const response = await Login({
        email,
        password,
      });
      if (response.data && response.data.success === false) {
        setButtonText("Sign in");
        return setError(response.data.msg);
      }
      return setProfile(response);
    } catch (err) {
      // console.log(err);
      setButtonText("Sign in");
      if (err.response) {
        return setError(err.response.data.msg);
      }
      return setError("There has been an error.");
    }
  };

  useEffect(() => {
    if (user) {
      return history.push("/myslr/projects-list");
      setButtonText("Go Back");
    }
  }, []);

  return (
    <CoverLayout
      title="Welcome back"
      // eslint-disable-next-line react/jsx-curly-brace-presence
      description={`${
        user && user.token
          ? "You are already signed in"
          : "Enter your email and password to sign in"
      }`}
      image={curved9}
    >
      <SuiBox component="form" role="form">
        {user && user.token ? null : (
          <>
            <SuiBox mb={2} lineHeight={1.25}>
              <SuiBox mb={1} ml={0.5}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Email
                </SuiTypography>
              </SuiBox>
              <SuiInput
                defaultValue={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setError(undefined);
                }}
                type="email"
                placeholder="Email"
              />
            </SuiBox>
            <SuiBox mb={2} lineHeight={1.25}>
              <SuiBox mb={1} ml={0.5}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Password
                </SuiTypography>
              </SuiBox>
              <SuiInput
                defaultValue={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  setError(undefined);
                }}
                type="password"
                placeholder="Password"
              />
            </SuiBox>
            <SuiBox display="flex" alignItems="center">
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <SuiTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetRememberMe}
                customClass="cursor-pointer user-select-none"
              >
                &nbsp;&nbsp;Remember me
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={2} mb={2} textAlign="center">
              <h6
                style={{
                  fontSize: ".7em",
                  color: "red",
                  textAlign: "center",
                  fontWeight: 400,
                  transition: ".2s all",
                }}
              >
                {error}
              </h6>
            </SuiBox>
          </>
        )}
        <SuiBox mt={4} mb={1}>
          <SuiButton onClick={login} variant="gradient" buttonColor="info" fullWidth>
            {buttonText}
          </SuiButton>
        </SuiBox>
        <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" textColor="text" fontWeight="regular">
            <SuiTypography
              component={Link}
              to="/authentication/reset-password/cover"
              variant="button"
              textColor="info"
              fontWeight="medium"
              textGradient
            >
              Reset password
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" textColor="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SuiTypography
              component={Link}
              to="/authentication/sign-up/cover"
              variant="button"
              textColor="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <>
        <Divider />
        <Box justifyContent="center">
          <SuiTypography variant="caption" fontWeight="bold">
            TERMS AND CONDITIONS
          </SuiTypography>
        </Box>
        <SuiBox>
          <SuiTypography variant="body1" fontSize={14}>
            MySLR is a proprietary platform that can be used freely. We ask each user:
          </SuiTypography>
        </SuiBox>
        <SuiBox pl={2}>
          <ul>
            <li>
              <SuiTypography variant="body1" fontSize={14}>
                To register a new account on Myslr platform by specifying the affiliation and the
                research goals.
              </SuiTypography>
            </li>
          </ul>
        </SuiBox>
        <SuiBox mb={2} pl={2}>
          <ul>
            <li>
              <SuiTypography variant="body1" fontSize={14}>
                To mention explicitly the use of MySLR when publishing results, using the following
                reference:
              </SuiTypography>
              <SuiTypography variant="body1" fontSize={14} fontStyle="italic">
                S. Ammirato, A.M. Felicetti, D. Rogano, R. Linzalone, V. Corvello (2022) –
                “Digitalizing the Systematic Literature Review process: the MySLR platform”,
                Knowledge Management Research & Practice, DOI: 10.1080/14778238.2022.2041375; Vol.:
                ahead-of-print, n°: ahead-of-print
              </SuiTypography>
            </li>
          </ul>
        </SuiBox>
        <Divider />
        <SuiTypography variant="caption" fontWeight="bold">
          DISCLAIMER
        </SuiTypography>
        <SuiBox>
          <SuiTypography variant="body1" fontSize={12} fontStyle="italic">
            MySLR is provided free of charge and "AS IS" without any warranty of any kind. The
            implied warranties of merchantability, fitness for a particular purpose and
            non-infringment are expressly disclaimed. In no event will the authors (Salvatore
            Ammirato and Daniele Rogano) or his employer (University of Calabria) be liable to any
            party for any damages for any use of the platform.
          </SuiTypography>
        </SuiBox>
      </>
    </CoverLayout>
  );
}

export default Cover;
