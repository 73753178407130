/* eslint-disable */
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Pricing page components
import ContentCollapse from "../ContentCollapse";

import workflow from "assets/images/workflow.png";
import { Divider } from "@mui/material";
import Lottie from "lottie-react";
import workflows from "assets/lotties/workflows.json";
import collaborative from "assets/lotties/collaborative.json";
import slr from "assets/lotties/slr.json";
import algorithm from "assets/lotties/algorithm.json";
import platform from "assets/lotties/platform.json";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <SuiBox mt={0}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <SuiTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
            Systematic Literature Review made easy
          </SuiTypography>
          <SuiBox mb={2}>
            <SuiTypography variant="body2" align="center" textColor="text">
              MySLR is an online platform that supports researchers in performing Systematic
              Literature Reviews (SLRs).<br></br>
              Main advantages from using MySLR for conducting SLRs can be summarized as follows:
            </SuiTypography>
          </SuiBox>
        </Grid>
        <Grid item xs={12} md={10}>
          <ContentCollapse
            title="1. Clear research protocol and workflow control"
            open={collapse === 1}
            onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
          >
            <SuiTypography display="block" variant="body2" fontWeight="regular">
              The intrinsic complexity of the SLR research protocol often leads scholars to carry
              out the phases of the SLR and to assess the quality of primary studies in different
              ways, thus losing sight of the real systematization of the procedure, which should be
              the main advantage in using an SLR. It is not rare that outcomes of different SLRs
              conducted on the same topic with identical aims can be divergent, controversial, or
              misleading. Thus, the goal of creating complete, correct, and reliable knowledge as an
              expected result of a SLR can be problematic to achieve.
            </SuiTypography>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6}>
                <Lottie animationData={workflows} loop={true} />
              </Grid>
            </Grid>
            <SuiTypography variant="body2" fontWeight="regular">
              MySLR, that was developed starting from an analysis of the steps that make up the SLR
              process, implements a clear protocol (see Fig. 1), supports the researcher along each
              step of a SLR and enable the taking control of all steps:
            </SuiTypography>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={8} className="mx-auto">
                <Box justifyContent="center">
                  <SuiBox
                    component="img"
                    id={0}
                    src={workflow}
                    alt="SLR workflow"
                    borderRadius="lg"
                    boxShadow="regular"
                    width="100%"
                    maxWidth="45rem"
                    px={{ xs: 1, sm: 0 }}
                    mt={2}
                    mb={0}
                  />
                </Box>
                <SuiTypography variant="caption" fontWeight="regular">
                  Figure 1. The generic SLR process workflow supported by MySLR
                </SuiTypography>
              </Grid>
            </Grid>
          </ContentCollapse>
          <ContentCollapse
            title="2. Effective support for Collaborative work"
            open={collapse === 2}
            onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
          >
            <SuiTypography variant="body2" fontWeight="regular">
              With MySLR the researcher can invite other colleagues to (remote) collaborate in
              different phases of the SLR process. MySLR supports collaborative work and, at the
              same time, gives the research the full control of the collaboration results.
            </SuiTypography>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6}>
                <Lottie animationData={collaborative} loop={true} />
              </Grid>
            </Grid>
          </ContentCollapse>
          <ContentCollapse
            title="3. Excellent SLR process performance"
            open={collapse === 3}
            onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
          >
            <SuiTypography variant="body2" fontWeight="regular">
              The practice of SLRs can be problematic:
            </SuiTypography>

            <Grid container item xs={12} lg={11} mb={2} className="mx-auto">
              <SuiTypography variant="body2" fontWeight="regular">
                <ul>
                  <li>
                    The access and acquisition of sources across different repositories is
                    changeling.
                  </li>
                  <li>
                    The number of sources to be analysed can reach hundreds or thousands. To ensure
                    high quality in the review process, it is necessary to analyse all selected
                    contributions which makes it difficult and time-consuming even for an
                    experienced researcher; the time required to carry out an SLR is generally more
                    than six months.
                  </li>
                </ul>
              </SuiTypography>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                  <Lottie animationData={slr} loop={true} />
                </Grid>
              </Grid>
            </Grid>

            <SuiTypography variant="body2" fontWeight="regular">
              With MySLR the performances of the review process are greatly improved:
              <br></br>[TIME] MySLR reduce the overall duration of a SLR to a fraction of time (from
              6-8 months to few days).
              <br></br>[QUALITY] MySLR is able to analyze all the sources in few seconds and without
              errors due to the human intervention.
              <br></br>[FLEXIBILITY] With MySLR the researcher can create different queries for each
              research projects, so that there are different sets of papers among whom choice the
              definitive. Moreover, once the definitive set of paper has been chosen, MySLR enable
              the analysis of different subsets of papers. At any moment, the researcher can go back
              to previous steps and modify the values of parameters to refine results. Moreover,
              with MySLR the researcher can run different topic models by setting different
              parameters; for each of them results are calculated in few seconds and clearly
              visualized.
            </SuiTypography>
          </ContentCollapse>
          <ContentCollapse
            title="4. Original and innovative machine learning algorithm for latent knowledge
                extraction"
            open={collapse === 4}
            onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
          >
            <SuiTypography variant="body2" fontWeight="regular">
              The topic models adopted by MySLR are based on the analysis of texts (in relation to
              abstract, title and keywords of a paper). MySLR clusters papers around topics using
              the LDA - Latent Dirichlet Allocation - and the LSI - Latent Semantic Indexing - that
              are generative statistical models that explain the texts (sets of words) through
              unobserved groups (the candidate topics):
            </SuiTypography>

            <Grid container item xs={12} lg={11} mb={2} className="mx-auto">
              <SuiTypography variant="body2" fontWeight="regular">
                <ol>
                  <li>A text (document) is made of words.</li>
                  <li>
                    The presence of a word in a document is directly linkable to one of the
                    document's topics.
                  </li>
                  <li>Each document will contain a small number of topics</li>
                </ol>
              </SuiTypography>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={12} md={6}>
                <Lottie animationData={algorithm} loop={true} />
              </Grid>
            </Grid>

            <SuiTypography variant="body2" fontWeight="regular">
              The main diffused techniques to support systematic reviews of literature, such as
              co-citation and bibliographic coupling, are based on the analysis of citations.
              However, these approaches have several limitations. First, these techniques assume
              that the more co-citations two documents receive, the more likely they are
              semantically related. Furthermore, these techniques penalize emerging issues, for
              which a relevant citation basis is not yet available. <br></br>MySLR gives an
              alternative perspective on the links between articles, based on the actual content of
              the paper (rather than on aspects whose meaning is subject to interpretation such as
              citations).
            </SuiTypography>
          </ContentCollapse>
          <ContentCollapse
            title="5. An innovative digital platform"
            open={collapse === 5}
            onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
          >
            <SuiTypography variant="body2" fontWeight="regular">
              Digital tools to support the work of researchers in doing their SLRs do exist. Anyway,
              such tools are limited since they hide some crucial phases to researchers or,
              sometimes, they are not functioning. In some cases, the use of the such tools is
              particular complex and no effective support is often available for users. <br></br>
              Moreover, while in the past much attention has been paid to tools supporting the
              analysis of bibliometric networks (such as co-authorship, bibliographic coupling, and
              co-citation networks), much less attention has been paid to the design of software
              tools that collaboratively support researchers throughout the entire SLR process.
              <br></br>
              MySLR is web based and, fist of its kind, digitalize the whole SLR process. The user
              does not need to install anything on its device nor using the computational capacity
              of its device, he just needs a device connected to the Internet.
            </SuiTypography>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6}>
                <Lottie animationData={platform} loop={true} />
              </Grid>
            </Grid>
          </ContentCollapse>
          <Divider />
          <SuiTypography variant="body2" fontWeight="regular">
            In the end, the main features of the research protocol implemented by MySLR reflect the
            key characteristics of a SLR as defined in the famous Cochrane Handbook for Systematic
            Reviews of Interventions, published in 2019 by John Wiley & Sons:
          </SuiTypography>
          <Grid container item xs={12} lg={11} mb={2} className="mx-auto">
            <SuiTypography variant="body2" fontWeight="regular">
              <ul>
                <li>
                  A clearly stated set of objectives with pre-defined eligibility criteria for
                  inclusion of studies.{" "}
                </li>
                <li>An explicit, reproducible methodology.</li>
                <li>
                  A systematic search that attempts to identity all studies that meet the
                  eligibility criteria
                </li>
                <li>An assessment of the validity of the findings of the included studies, and </li>
                <li>
                  A systematic presentation, and synthesis, of the characteristics and findings of
                  the included studies.
                </li>
              </ul>
            </SuiTypography>
          </Grid>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Faq;
