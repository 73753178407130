/* eslint-disable */
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiBadge from "components/SuiBadge";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";

function ProjectInfo({ projectData }) {
  const frameOptions = [
    { value: "aluminium", label: "Aluminium" },
    { value: "carbon", label: "Carbon" },
    { value: "steel", label: "Steel" },
    { value: "wood", label: "Wood" },
  ];

  const colorOptions = [
    { value: "black", label: "black" },
    { value: "blue", label: "blue" },
    { value: "gray", label: "gray" },
    { value: "pink", label: "pink" },
    { value: "red", label: "red" },
    { value: "white", label: "white" },
  ];

  return (
    <SuiBox>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <SuiBox mb={1}>
            <SuiTypography variant="h3" fontWeight="bold">
              {projectData ? projectData.project_name : null}
            </SuiTypography>
          </SuiBox>
          {/*           <SuiBox mb={1}>
            <SuiTypography variant="h5" fontWeight="medium">
              {projectData ? projectData.project_description : null}
            </SuiTypography>
          </SuiBox> */}
          {/*           <SuiBadge variant="contained" color="success" badgeContent="in stock" container />
           */}
          <SuiBox mt={3} mb={1} ml={0.5}>
            <SuiTypography variant="caption" fontWeight="bold">
              Description
            </SuiTypography>
          </SuiBox>
          <SuiBox mb={1}>
            <SuiTypography variant="body2" textColor="text" verticalAlign="middle">
              {projectData ? projectData.project_description : null}
            </SuiTypography>
          </SuiBox>
        </Grid>
        {/* <Grid item xs={4}>
          <SuiBox mt={3} mb={1} ml={0.5}>
            <SuiTypography variant="caption" fontWeight="bold">
              Most relevant terms
            </SuiTypography>
          </SuiBox>
          <SuiBox component="ul" m={0} pl={4} mb={2}>
            <SuiBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
              <SuiTypography variant="body2" textColor="text" verticalAlign="middle">
                Industry 4.0
              </SuiTypography>
            </SuiBox>
            <SuiBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
              <SuiTypography variant="body2" textColor="text" verticalAlign="middle">
                HRM
              </SuiTypography>
            </SuiBox>
            <SuiBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
              <SuiTypography variant="body2" textColor="text" verticalAlign="middle">
                Smart Factories
              </SuiTypography>
            </SuiBox>
            <SuiBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
              <SuiTypography variant="body2" textColor="text" verticalAlign="middle">
                Human Resource Management
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Grid> */}
      </Grid>
    </SuiBox>
  );
}

export default ProjectInfo;
