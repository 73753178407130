/* eslint-disable */
import { useParams, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

// ProductPage page components
import QuerystringInfo from "./components/QuerystringInfo";
import { URL_QUERYSTRINGS, URL_DATASETS } from "api/myslr";
import axios from "api/index";
import { useAuth } from "auth-context/auth.context";

// sweetalert2 components
import Swal from "sweetalert2";

// Images
import badge from "assets/pics/badge-1.png";

// Data
import ActionCell from "./components/ActionCell";
import SourcefilesTab from "./components/SourcefilesTabs";
import Paper from "./Paper";
import Summary from "./Summary";
import { Divider } from "@mui/material";

function getSteps() {
  //return ["1.1 General", "1.2 Sources", "1.3 Papers", "1.4 Summary"];
  return ["1.1 Sources", "1.2 Papers", "1.3 Summary"];
}

// const GeneralInfo = (data) => {
//   var querystringData = data.querystringData;
//   return (
//     <SuiBox py={3}>
//       <Card className="overflow-visible">
//         <SuiBox p={3}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} lg={12}>
//               {querystringData ? <QuerystringInfo querystringData={querystringData} /> : null}
//             </Grid>
//           </Grid>
//         </SuiBox>
//       </Card>
//     </SuiBox>
//   );
// };

const SourceFiles = ({ querystringData, reload }) => {
  // var querystringData = data.querystringData;
  return (
    <SuiBox mt={8} mb={1}>
      {/*       <SuiBox mb={1} ml={1}>
        <SuiTypography variant="h5" fontWeight="medium" align="center">
          Source files
        </SuiTypography>
      </SuiBox> */}
      <SourcefilesTab querystring={querystringData} />
    </SuiBox>
  );
};

const getStepContent = (stepIndex, data, handleFetch, handleTab) => {
  switch (stepIndex) {
    case 0:
      return <SourceFiles querystringData={data} />;
    case 1:
      return <Paper querystring={data} reload={handleFetch} handleTab={handleTab} />;
    case 2:
      return (
        <Summary dataset_id={data.default_dataset} querystring_id={data.id} reload={handleFetch} />
      );
    default:
      return null;
  }
};

function PaperLocationSelection({ handleTab }) {
  const { id } = useParams();
  const { user } = useAuth();
  const history = useHistory();
  const [querystringData, setQuerystringData] = useState();
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [confirmButton, setConfirmButton] = useState(true);
  const steps = getSteps();

  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleBadgeShow = () => {
    Swal.fire({
      title: "Good job!",
      text: "You completed the Papers location and selection phase!",
      imageUrl: badge,
      imageWidth: 200,
      imageAlt: "Custom image",
    });
  };

  const startAnalysis = () => {
    handleBadgeShow();
    setConfirmButton(false);
    axios
      .post(`${URL_DATASETS}/${querystringData.default_dataset}/test_model`, null, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          // console.log(response);
        } else {
          // console.log(response.status);
        }
      })
      .catch((_error) => {
        // console.log(_error);
      });

    let postData = new Object();
    postData.confirmed = true;
    axios
      .patch(`${URL_QUERYSTRINGS}/${id}`, postData, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        if (response.status == 200) {
          // console.log("updated");
        } else {
          // console.log("error");
        }
      })
      .catch((_error) => {
        setError(_error);
      });
    handleTab(true);
  };

  const testTrigrams = () => {
    axios
      .post(`${URL_DATASETS}/${querystringData.default_dataset}/test_model2`, null, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          // console.log(response);
        } else {
          // console.log(response.status);
        }
      })
      .catch((_error) => {
        // console.log(_error);
      });
  };

  const handleFetch = () => {
    user != null &&
      axios
        .get(`${URL_QUERYSTRINGS}/${id}`, { headers: { Authorization: `${user.token}` } })
        .then((response) => {
          setQuerystringData(response.data);
        })
        .catch((_error) => {
          setError(_error);
          switch (_error.response.status) {
            case 403:
              history.push("/authentication/error/403");
            default:
              break;
          }
        });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Card className="overflow-visible">
        <SuiBox p={2}>
          <SuiBox>
            {getStepContent(activeStep, querystringData, handleFetch, handleTab)}
            <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
              {activeStep === 0 ? (
                <SuiBox />
              ) : (
                <SuiButton variant="gradient" buttonColor="secondary" onClick={handleBack}>
                  back
                </SuiButton>
              )}
              {isLastStep ? (
                !querystringData.confirmed &&
                confirmButton && (
                  <SuiButton
                    variant="gradient"
                    buttonColor="success"
                    onClick={isLastStep && startAnalysis}
                  >
                    dataset confirm
                  </SuiButton>
                )
              ) : (
                <SuiButton
                  variant="gradient"
                  buttonColor="dark"
                  onClick={!isLastStep && handleNext}
                >
                  next
                </SuiButton>
              )}
              {/* <SuiButton variant="gradient" buttonColor="success" onClick={testTrigrams}>
                TEST
              </SuiButton> */}
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </>
  );
}

export default PaperLocationSelection;
