/* eslint-disable */
import { useState, useEffect } from "react";

import { URL_QUERYSTRINGS } from "api/myslr";
import axios from "api/index";
import { useAuth } from "auth-context/auth.context";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiAlert from "components/SuiAlert";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

import ScopusBox from "../ScopusBox";
import WosBox from "../WosBox";
import OtherSourcesBox from "../OtherSourcesBox";
import { Divider } from "@mui/material";

function SourcefilesTab({ querystring }) {
  const { user } = useAuth();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [querystringData, setQuerystringData] = useState();
  const [error, setError] = useState(null);
  const [isQueryOwner, setIsQueryOwner] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [qsStats, setQsStats] = useState({
    dbCount: 0,
    papersNum: 0,
  });

  const handleFetch = () => {
    axios
      .get(`${URL_QUERYSTRINGS}/${querystring.id}`, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        if (user.email === response.data.owner.email) {
          setIsQueryOwner(true);
        }
        setQuerystringData(response.data);
        let papersnum = 0;
        let dbcount = 0;
        response.data.sourcefiles.map((item) => {
          papersnum = papersnum + item.sourcefile_total_papers;
          dbcount++;
        });
        setQsStats({
          dbCount: dbcount,
          papersNum: papersnum,
        });
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  function getTabContent(tabIndex) {
    switch (tabIndex) {
      case 0:
        return (
          <ScopusBox
            querystring_id={querystring.id}
            reload={handleFetch}
            isQueryOwner={isQueryOwner}
          />
        );
      case 1:
        return (
          <WosBox
            querystring_id={querystring.id}
            reload={handleFetch}
            isQueryOwner={isQueryOwner}
          />
        );
      case 2:
        return (
          <OtherSourcesBox
            querystring_id={querystring.id}
            reload={handleFetch}
            isQueryOwner={isQueryOwner}
          />
        );
      default:
        return null;
    }
  }

  /* const fetchDbData = () => {
    let papersnum = 0;
    let dbcount = 0;
    querystringData.sourcefiles.map((item) => {
      papersnum = papersnum + item.sourcefile_total_papers;
      dbcount++;
    });
    setQsStats({
      dbCount: dbcount,
      papersNum: papersnum,
    });
  }; */

  /*  useEffect(() => {
    handleFetch();
  }, []); */

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <SuiBox position="relative">
      <Grid item xs={12} md={12} lg={12} className="ml-auto">
        <AppBar position="static">
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
            className="bg-transparent"
          >
            <Tab label="Scopus" icon={<Cube />} />
            <Tab label="Web of Science" icon={<Cube />} />
            <Tab label="PubMed" icon={<Document />} />
          </Tabs>
        </AppBar>
      </Grid>
      {querystring && getTabContent(tabValue)}
      <Divider />
      <SuiBox display="flex" justifyContent="center" alignItems="center" mx="auto" mr={2} ml={2}>
        <SuiTypography variant="h5" fontWeight="medium">
          {qsStats.papersNum} papers retrieved over {qsStats.dbCount} databases (with duplicates)
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

export default SourcefilesTab;
