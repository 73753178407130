/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Logout } from "../../../api/auth";
import { useAuth } from "../../../auth-context/auth.context";

function SignOut() {
  const history = useHistory();
  const { setUser } = useAuth();
  const { user } = useAuth();
  const paths = ["basic", "cover", "illustration"];

  const handleLogout = async () => {
    await Logout(user);
    await setUser(null);
    localStorage.removeItem("user");
    return history.push(`/authentication/sign-in/cover`);
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return null;
}

export default SignOut;
