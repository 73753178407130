/* eslint-disable */
import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

import { URL_QUERYSTRINGS, URL_DATASETS } from "api/myslr";
import axios from "api/index";
import { useAuth } from "auth-context/auth.context";

// sweetalert2 components
import Swal from "sweetalert2";
import Separator from "../Separator";

import * as XLSX from "xlsx";

function Datasets({ datasets, selectDataset, selectDefault, querystring, refetch, reload }) {
  const { user } = useAuth();
  const { borderWidth } = borders;
  const [selected, setSelected] = useState(selectDataset);
  const [isOwner, setIsOwner] = useState(false);
  const [defaultDs, setDefaultDs] = useState();
  const [error, setError] = useState();

  const handleSetDefault = () => {
    setDefaultDs(selected);
    selectDefault(selected);
    updateDefault();
    reload();
  };

  const handleExportFile = () => {
    // console.log("Default DS: ", defaultDs);

    axios
      .get(`${URL_DATASETS}/${selected}/export_papers`, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          /* convert state to workbook */
          console.log("DATA: ", response);
          const worksheet = XLSX.utils.json_to_sheet(response.data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "papers");
          //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
          //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
          XLSX.writeFile(workbook, "mySLR_dataset.xlsx");
        } else {
          // console.log(response.status);
        }
      })
      .catch((_error) => {
        // console.log(_error);
      });
  };

  const handleDeleteDs = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          if (selected === defaultDs) {
            newSwal.fire(
              "Error!",
              "To delete this dataset you should first select another dataset as default",
              "error"
            );
          } else {
            axios
              .delete(`${URL_DATASETS}/${selected}`, {
                headers: { Authorization: `${user.token}` },
              })
              .then((response) => {
                if (response.status == 204) {
                  refetch();
                  setSelected(defaultDs);
                } else {
                  newSwal.fire("Error!", "The dataset can't be deleted", "error");
                }
              })
              .catch((_error) => {
                setError(_error);
              });
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          newSwal.fire("Cancelled", "Dataset safe!", "error");
        }
      });
  };

  const updateDefault = () => {
    let postData = new Object();
    postData.default_dataset = selected;
    postData.confirmed = false;

    axios
      .patch(`${URL_QUERYSTRINGS}/${querystring.id}`, postData, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          extractKeywords();
          Swal.fire("Success!", "Default dataset changed", "success");
        } else {
          Swal.fire(
            "Error!",
            "We can't set this dataset as default, check your data again",
            "error"
          );
        }
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  useEffect(() => {
    setDefaultDs(querystring.default_dataset);
    if (querystring.default_dataset !== null) {
      setSelected(querystring.default_dataset);
      selectDataset(querystring.default_dataset);
    } else {
      /*  selectDataset(datasets[0].id);
      setSelected(datasets[0].id); */
    }
    // console.log("DATASET: ", datasets[0]);

    if (querystring.owner.email === user.email) {
      setIsOwner(true);
    }
  }, [datasets]);

  const showAlert = async () => {
    let options = {};
    let choosen;
    let newVersion = 0;
    datasets.map((item) => {
      options[item.id] = `V${item.version}`;
      if (item.version > newVersion) newVersion = item.version;
    });
    const { value: dataset } = await Swal.fire({
      title: "Select a dataset to duplicate selection",
      input: "select",
      inputOptions: {
        Datasets: options,
      },
      inputPlaceholder: "Select dataset",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose a dataset first!";
        }
      },
    });

    if (dataset) {
      newVersion = newVersion + 1;
      handleNewDataset(dataset, newVersion);
    }
  };

  const extractKeywords = () => {
    axios
      .post(`${URL_DATASETS}/${selected}/extract_keywords`, null, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        if (response.status == 200) {
        } else {
          // console.log(response.status);
        }
      })
      .catch((_error) => {
        // console.log(_error);
      });
  };

  const handleNewDataset = (dataset_id, version) => {
    let postData = new Object();
    postData.version = version;

    axios
      .post(`${URL_DATASETS}/${dataset_id}/duplicate`, postData, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          refetch();
          Swal.fire("Success!", "New dataset created", "success");
        } else {
          Swal.fire("Error!", "We can't create a new dataset, check your data again", "error");
        }
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const renderVersions = datasets.map(({ id, version, excluded_papers, total_papers }) => (
    <Grid key={id} item xs={1} sm={1} md={1} lg={1} sx={{ flex: "0 0 100%" }}>
      <div
        onClick={() => {
          selectDataset(id);
          setSelected(id);
        }}
      >
        <SuiBox
          borderRadius="10%"
          width="3rem"
          height="3rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="white"
          mx="auto"
          backgroundColor={defaultDs === id ? "warning" : "white"}
          border={
            selected === id
              ? `${borderWidth[4]} solid ${colors["info"].main}`
              : `${borderWidth[1]} solid ${colors["warning"].main}`
          }
          customClass="cursor-pointer"
        >
          {/* <SuiAvatar src={image} alt={name} /> */}
          <SuiTypography variant="h4" textColor="text" fontWeight="regular">
            v{version}
          </SuiTypography>
        </SuiBox>
      </div>
      <SuiBox mt={0.75} textAlign="center" lineHeight={1}>
        <SuiTypography variant="button" textColor="text" fontWeight="regular">
          {total_papers - excluded_papers}
        </SuiTypography>
      </SuiBox>
    </Grid>
  ));

  return (
    <SuiBox>
      <SuiTypography variant="h6" textColor="text" fontWeight="regular" align="center">
        A list of <b>{datasets[0].total_papers}</b> unique papers (no duplication) will be used for
        analysis
      </SuiTypography>

      {isOwner && (
        <SuiBox width="100%" pt={1} pb={1} px={1}>
          <Separator text={"datasets"} />
          <Grid container justifyContent="flex-start" wrap="nowrap">
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <div onClick={() => showAlert(20, 2)}>
                <SuiBox
                  backgroundColor="info"
                  borderRadius="10%"
                  width="3rem"
                  height="3rem"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                  mx="auto"
                  backgroundGradient
                  customClass="cursor-pointer"
                >
                  <Icon className="" fontSize="small">
                    add
                  </Icon>
                </SuiBox>
              </div>
              <SuiBox mt={0.75} textAlign="center" lineHeight={1}>
                <SuiTypography variant="button" textColor="text" fontWeight="regular">
                  Add selection
                </SuiTypography>
              </SuiBox>
            </Grid>
            {renderVersions}
            {selected && selected != defaultDs && (
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mx="auto"
                  mr={2}
                  ml={2}
                >
                  <SuiButton
                    variant="contained"
                    buttonColor="success"
                    size="small"
                    onClick={handleSetDefault}
                  >
                    Select
                  </SuiButton>
                </SuiBox>
              </Grid>
            )}
            {selected && selected != defaultDs && datasets.length > 1 && (
              <Grid item xs={2} sm={2} md={1} lg={1}>
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mx="auto"
                  mr={2}
                  ml={2}
                >
                  <SuiButton
                    variant="contained"
                    buttonColor="error"
                    size="small"
                    onClick={handleDeleteDs}
                  >
                    Delete
                  </SuiButton>
                </SuiBox>
              </Grid>
            )}

            {selected && selected == defaultDs && (
              <Grid item xs={3} sm={3} md={2} lg={2}>
                <SuiBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mx="auto"
                  mr={2}
                  ml={2}
                >
                  <SuiButton
                    variant="contained"
                    buttonColor="info"
                    size="medium"
                    onClick={handleExportFile}
                  >
                    <Icon fontSize="large" color="inherit">
                      toc
                    </Icon>
                    Export dataset
                  </SuiButton>
                </SuiBox>
              </Grid>
            )}
          </Grid>
        </SuiBox>
      )}
    </SuiBox>
  );
}

export default Datasets;
