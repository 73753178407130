/* eslint-disable no-console */
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Images
import rocket from "assets/images/illustrations/rocket-white.png";

import { Register } from "../../../../api/auth";

function Illustration() {
  const history = useHistory();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonText, setButtonText] = useState("Sign up");
  const [error, setError] = useState(undefined);

  const [agreement, setAgreemnet] = useState(true);

  const handleSetAgremment = () => setAgreemnet(!agreement);

  const register = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (name === "") {
      return setError("You must enter your name.");
    }
    if (email === "") {
      return setError("You must enter your email.");
    }
    if (password === "") {
      return setError("You must enter a password.");
    }
    try {
      setButtonText("Signing up");
      const response = await Register({
        username: name,
        email,
        password,
      });
      if (response.data && response.data.success === false) {
        setButtonText("Sign up");
        return setError(response.data.msg);
      }
      return history.push("/authentication/sign-in/illustration");
    } catch (err) {
      setButtonText("Sign up");
      if (err.response) {
        return setError(err.response.data.msg);
      }
      return setError("There has been an error.");
    }
  };

  return (
    <IllustrationLayout
      title="Sign Up"
      description="Enter your email and password to register"
      illustration={{
        image: rocket,
        title: "Your journey starts here",
        description:
          "Just as it takes a company to sustain a product, it takes a community to sustain a protocol.",
      }}
    >
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiInput
            onChange={(event) => {
              setName(event.target.value);
              setError(undefined);
            }}
            placeholder="Name"
            size="large"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiInput
            onChange={(event) => {
              setEmail(event.target.value);
              setError(undefined);
            }}
            type="email"
            placeholder="Email"
            size="large"
          />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiInput
            onChange={(event) => {
              setPassword(event.target.value);
              setError(undefined);
            }}
            type="password"
            placeholder="Password"
            size="large"
          />
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <Checkbox checked={agreement} onChange={handleSetAgremment} />
          <SuiTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetAgremment}
            customClass="cursor-pointer user-select-none"
          >
            &nbsp;&nbsp;I agree the&nbsp;
          </SuiTypography>
          <SuiTypography component="a" href="#" variant="button" fontWeight="bold" textGradient>
            Terms and Conditions
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={2} mb={2} textAlign="center">
          <h6
            style={{
              fontSize: ".7em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            {error}
          </h6>
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton
            onClick={register}
            variant="gradient"
            buttonColor="info"
            size="large"
            fullWidth
          >
            {buttonText}
          </SuiButton>
        </SuiBox>
        <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" textColor="text" fontWeight="regular">
            Already have an account?&nbsp;
            <SuiTypography
              component={Link}
              to="/authentication/sign-in/illustration"
              variant="button"
              textColor="info"
              fontWeight="bold"
              textGradient
            >
              Sign in
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </IllustrationLayout>
  );
}

export default Illustration;
