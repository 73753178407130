/* eslint-disable */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React components
import borders from "assets/theme/base/borders";

function ContentCollapse({ title, open, children, ...rest }) {
  const { borderWidth, borderColor } = borders;

  return (
    <SuiBox mb={2}>
      <SuiBox
        {...rest}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        customClass="cursor-pointer"
      >
        <SuiTypography
          variant="h5"
          textColor={open ? "dark" : "text"}
          customClass="user-select-none"
        >
          {title}
        </SuiTypography>
        <SuiBox color={open ? "dark" : "text"}>
          <Icon className=" font-bold" fontSize="small">
            {open ? "remove" : "add"}
          </Icon>
        </SuiBox>
      </SuiBox>
      <Collapse timeout={400} in={open}>
        <SuiBox p={2} lineHeight={1}>
          <SuiTypography variant="button" textColor="text" opacity={0.8} fontWeight="regular">
            {children}
          </SuiTypography>
        </SuiBox>
      </Collapse>
    </SuiBox>
  );
}

// Typechecking props for the FaqCollapse
ContentCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContentCollapse;
