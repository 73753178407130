/* eslint-disable */
// react-router-dom components
import { Link } from "react-router-dom";
import { useState } from "react";

import { blue, green } from "@mui/material/colors";
import SvgIcon from "@mui/material/SvgIcon";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SchoolIcon from "@mui/icons-material/School";
import GoogleIcon from "@mui/icons-material/Google";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
import SuiButton from "components/SuiButton";

import Rg from "assets/team/rg.png";
import gscholar from "assets/team/gscholar.png";

function DefaultPricingCard({ badge, bio, social, member }) {
  // const renderSpecifications = specifications.map(({ label, long }) => (
  //   <SuiBox key={label} display="flex" alignItems="center" p={1}>
  //     <SuiTypography variant="body2" textColor="text">
  //       {label}
  //     </SuiTypography>
  //   </SuiBox>
  // ));
  const [open, setOpen] = useState(false);

  return (
    <Card>
      <SuiBox pt={3} pb={2} px={2} textAlign="center">
        {/* <SuiBadge
          variant="caption"
          color={badge.color}
          badgeContent={social.mail}
          circular
          container
        /> */}
        <SuiBox
          component="img"
          src={member}
          alt="Product Image"
          boxShadow="lg"
          borderRadius="lg"
          width="100%"
        />

        <SuiBox my={0}>
          <SuiTypography variant="h4">{badge.label}</SuiTypography>
          <SuiTypography variant="caption">{social.department}</SuiTypography>
          <br></br>
          <SuiTypography variant="caption">{social.afference}</SuiTypography>
        </SuiBox>

        <SuiTypography variant="button">{social.mail}</SuiTypography>
      </SuiBox>
      <SuiBox pb={3} px={3}>
        <SuiBox display="flex" alignItems="center" p={1}>
          <SuiTypography variant="body2" textColor="text" textAlign="justify">
            {bio.label}{" "}
            {open ? (
              <>
                {bio.long}
                <SuiButton
                  onClick={() => {
                    setOpen(false);
                  }}
                  variant="caption"
                >
                  close
                </SuiButton>
              </>
            ) : (
              <SuiButton
                onClick={() => {
                  setOpen(true);
                }}
                variant="caption"
              >
                read more
              </SuiButton>
            )}
          </SuiTypography>
        </SuiBox>
        {/* {renderSpecifications} */}
        <hr></hr>
        <br></br>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={2} lg={2}>
            <a href={social.school} target="_blank" rel="noreferrer">
              {/* <SchoolIcon fontSize="large" color="info" /> */}
              <img src={Rg} style={{ width: "34px", height: "34px" }} />
            </a>
          </Grid>
          <Grid item xs={2} lg={2}>
            <a href={social.academy} target="_blank" rel="noreferrer">
              {/* <GoogleIcon fontSize="large" color="info" /> */}
              <img src={gscholar} style={{ width: "34px", height: "34px" }} />
            </a>
          </Grid>

          <Grid item xs={2} lg={2}>
            <a href={social.linkedin} target="_blank" rel="noreferrer">
              <LinkedInIcon fontSize="large" sx={{ color: blue[700] }} />
            </a>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
};

export default DefaultPricingCard;
