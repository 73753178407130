/* eslint-disable */
import { useState } from "react";
import { useHistory } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import { URL_QUERYSTRINGS } from "../../../../../api/myslr";
import axios from "../../../../../api/index";
import { useAuth } from "../../../../../auth-context/auth.context";

// sweetalert2 components
import Swal from "sweetalert2";

function ActionCell({ id, reload, own }) {
  const { user } = useAuth();
  // const history = useHistory();
  const history = createHistory();
  const [error, setError] = useState(null);

  const handleDelete = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this. You will lose all progress of the research string",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          axios
            .delete(`${URL_QUERYSTRINGS}/${id}`, { headers: { Authorization: `${user.token}` } })
            .then((response) => {
              if (response.status == 204) {
                // newSwal.fire("Success!", "Project deleted", "success");
                reload();
                // history.go(0);
              } else {
                newSwal.fire("Error!", "We can't delete your query research", "error");
              }
            })
            .catch((_error) => {
              setError(_error);
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          newSwal.fire("Cancelled", "Query Research safe!", "error");
        }
      });
  };

  const handleView = () => {
    <Link to="/myslr/querystring-page" className="decoration-none"></Link>;
  };

  return (
    <SuiBox display="flex" alignItems="center">
      <Link to={`/myslr/querystring-page/${id}`} className="decoration-none">
        <SuiTypography
          variant="body1"
          textColor="secondary"
          customClass="cursor-pointer line-height-0"
        >
          <Tooltip title="Preview querystring" placement="top">
            <Icon className="">visibility</Icon>
          </Tooltip>
        </SuiTypography>
      </Link>
      {own && (
        <Link to={`/myslr/edit-querystring/${id}`} className="decoration-none">
          <SuiBox mx={2}>
            <SuiTypography
              variant="body1"
              textColor="secondary"
              customClass="cursor-pointer line-height-0"
            >
              <Tooltip title={id} placement="top">
                <Icon className="">edit</Icon>
              </Tooltip>
            </SuiTypography>
          </SuiBox>
        </Link>
      )}
      {own && (
        <div key={id} onClick={handleDelete}>
          <SuiTypography
            variant="body1"
            textColor="secondary"
            customClass="cursor-pointer line-height-0"
          >
            <Tooltip title="Delete project" placement="left">
              <Icon className="">delete</Icon>
            </Tooltip>
          </SuiTypography>
        </div>
      )}
    </SuiBox>
  );
}

export default ActionCell;
