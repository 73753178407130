/* eslint-disable */

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

import { TailSpin } from "react-loader-spinner";

function StatusCell({ icon, color, status }) {
  return (
    <SuiBox display="flex" alignItems="center">
      <SuiBox mr={1}>
        <SuiButton
          variant="outlined"
          buttonColor={status ? "success" : "info"}
          size="small"
          iconOnly
          circular
        >
          {status ? (
            <Icon className=" font-bold">{"done"}</Icon>
          ) : (
            <TailSpin height={20} width={20} />
          )}
        </SuiButton>
      </SuiBox>
      {/* <SuiTypography
        variant="caption"
        fontWeight="medium"
        textColor="text"
        customClass="line-height-0"
      >
        {status ? "Complete" : "Working"}
      </SuiTypography> */}
    </SuiBox>
  );
}

export default StatusCell;
