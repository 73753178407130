/* eslint-disable */
import { useParams, Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../components/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "./DataTable";

import NoQuerystring from "./components/NoQuerystring";

// ProductPage page components
// import ProductImages from "layouts/ecommerce/products/product-page/components/ProductImages";
import ProjectInfo from "./components/ProjectInfo";
import { URL_PROJECTS } from "api/myslr";
import axios from "api/index";
import { useAuth } from "auth-context/auth.context";
// Data
import ActionCell from "./components/ActionCell";

// Notifications
import SuiSnackbar from "components/SuiSnackbar";

import Collaborators from "./components/Collaborators";

function ProjectPage() {
  const { id } = useParams();
  const { user } = useAuth();
  const [projectData, setProjectData] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [querystringsData, setQuerystringsData] = useState({
    columns: [
      { Header: "name", accessor: "querystring_name", width: "15%" },
      { Header: "description", accessor: "querystring_description", width: "35%" },
      { Header: "created at", accessor: "created_at", width: "20%" },
      { Header: "owner", accessor: "owner", width: "10%" },
      { Header: "actions", accessor: "actions", width: "10%" },
    ],
    rows: [],
  });
  const [error, setError] = useState(null);

  const [successSB, setSuccessSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const history = useHistory();

  const renderSuccessSB = (
    <SuiSnackbar
      type="success"
      icon="check"
      title="Success!"
      content="Querystring deleted!"
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const handleNotification = () => {
    handleFetch();
    openSuccessSB();
  };

  const handleFetch = () => {
    axios
      .get(`${URL_PROJECTS}/${id}`, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        console.log(response.data);
        if (user.email === response.data.owner.email) {
          setIsOwner(true);
        }
        setProjectData(() => response.data);
        let refilled = response.data.querystrings.map((v) => ({
          ...v,
          querystring_name: (
            <Link to={`/myslr/querystring-page/${v.id}`} className="decoration-none">
              <SuiTypography
                textColor={user.email === v.owner.email ? "secondary" : "success"}
                variant="h6"
              >
                {v.querystring_name}
              </SuiTypography>
            </Link>
          ),
          querystring_description: (
            <SuiTypography
              textColor={user.email === v.owner.email ? "secondary" : "success"}
              variant="p"
            >
              {v.querystring_description}
            </SuiTypography>
          ),
          created_at: new Date(v.created_at).toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }),
          owner: v.owner.username,
          actions: (
            <ActionCell id={v.id} reload={handleNotification} own={user.email === v.owner.email} />
          ),
        }));
        setQuerystringsData((prevState) => ({
          ...prevState,
          rows: refilled,
        }));
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const reload = () => {
    handleFetch();
  };

  useEffect(() => {
    handleFetch();
  }, []);

  useEffect(() => {
    if (error != null) {
      switch (error.response.status) {
        case 403:
          history.push("/authentication/error/403");
        case 404:
          history.push("/authentication/error/404");
        default:
          break;
      }
    }
  }, [error]);

  return (
    <DashboardLayout>
      {projectData && <DashboardNavbar project={projectData.project_name} />}
      <SuiBox py={3}>
        <Card className="overflow-visible">
          <SuiBox p={3}>
            <SuiBox mb={3}>
              <SuiTypography variant="h5" fontWeight="medium">
                Project Details
              </SuiTypography>
            </SuiBox>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                {projectData ? <ProjectInfo projectData={projectData} /> : null}
              </Grid>
              <Grid item xs={12} lg={5} className="mx-auto">
                {projectData && (
                  <Collaborators projectData={projectData} reload={reload} isOwner={isOwner} />
                )}
              </Grid>
            </Grid>

            <SuiBox mt={8} mb={2}>
              <SuiBox mb={1} ml={2}>
                <SuiTypography variant="h5" fontWeight="medium">
                  Queries
                </SuiTypography>
              </SuiBox>
              {querystringsData.rows[0] ? (
                <DataTable
                  table={querystringsData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                />
              ) : (
                <NoQuerystring />
              )}
            </SuiBox>
            <Stack spacing={1} direction="column" alignItems="center">
              {projectData ? (
                <Link to={`/myslr/new-querystring/${projectData.id}`} className="decoration-none">
                  <SuiButton variant="gradient" buttonColor="info" size="small">
                    + new query string
                  </SuiButton>
                </Link>
              ) : null}
            </Stack>
          </SuiBox>
        </Card>
      </SuiBox>
      {renderSuccessSB}
      <Footer />
    </DashboardLayout>
  );
}
/* 
ProjectPage.propTypes = {
  // image: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  // checked: PropTypes.bool,
}; */

export default ProjectPage;
