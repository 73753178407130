/* eslint-disable */
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";

import axios from "api/index";
import { useAuth } from "auth-context/auth.context";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiAlert from "components/SuiAlert";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "./components/SuiTypography";
import Separator from "./components/Separator";
import Slider from "@mui/material/Slider";
// Data
import { Divider, Typography } from "@mui/material";

import API_SERVER from "config/constant";

import {
  URL_QUERYSTRINGS,
  URL_DATASETS,
  URL_TOPICS_PAPERS,
  URL_TOPICS_PAPERS_EXPORT,
  URL_RUNSETS,
  URL_PAPERS_TYPE_OPTIONS,
  URL_PAPERS_OPTIONS,
} from "api/myslr";
import Topics from "./components/Topics";
import VerticalBarChart from "./components/VerticalBarChart";
import YearBarChart from "./components/YearBarChart";
import DataTable from "./PapersTable";
import Pagination from "@mui/material/Pagination";
import PaperCollapse from "./PaperCollapse";
import styles from "./components/SliderStyle/styles";
import { ContactsOutlined } from "@mui/icons-material";
import Modal from "react-modal";
import * as XLSX from "xlsx";

const RenderPaperItem = React.memo((data) => {
  return (
    <PaperCollapse
      paperData={data.data}
      dataset={data.selectedDataset}
      open={data.collapse === data.index}
      setCollapse={data.setCollapse}
      collapse={data.collapse}
      index={data.index}
      update={data.updateDatasets}
      rsid={data.rsid}
    ></PaperCollapse>
  );
});

function ResultsSynthesis({ runset_id }) {
  const { id } = useParams();
  const { user } = useAuth();
  const classes = styles();
  const [dataset, setDataset] = useState(null);
  const [runsets, setRunsets] = useState(null);
  const [topics, setTopics] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [runset, setRunset] = useState({
    algorithm: "",
    coherence: 0,
    perplexity: 0,
    complete: false,
    created_at: "2022-04-11T15:23:21.907310Z",
    id: null,
    model: null,
    runset_dataset: 0,
    logdata: null,
    topic_num: 0,
  });
  const [topicChartData, setTopicChartData] = useState({
    labels: ["Topic 1", "Topic 2", "Topic 3"],
    datasets: [
      {
        label: "Documents per dominant topic",
        color: ["info"],
        data: [15, 20, 12],
      },
      {
        label: "Probability sum per  topic",
        color: ["error"],
        data: [12, 21, 14],
      },
    ],
  });
  // const [chartLoad, setChartLoad] = useState(false);
  const [yearStatsData, setYearStatsData] = useState({
    labels: [],
    datasets: [],
    load: true,
  });
  const [backStatsData, setBackStatsData] = useState({
    labels: [],
    datasets: [],
    load: true,
  });
  const [value, setValue] = useState([]);
  const [valueGlobal, setValueGlobal] = useState([3000, 0]);
  var min_y = 3000;
  var max_y = 0;
  const [yearSlider, setYearSlider] = useState();

  const [papersData, setPapersData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });

  const [error, setError] = useState(null);
  const colors = [
    "#35C1E9",
    "#82d616",
    "#fbcf33",
    "#ea0606",
    "#344767",
    "#FB9EE5",
    "#2B50AA",
    "#CFBAE1",
    "#596F6B",
    "#FEF2B1",
    "#550B0E",
    "#320D3C",
    "#DBFCFF",
    "#87919F",
    "#D1F0B1",
    "#A19EBC",
  ];

  const [collapse, setCollapse] = useState(false);

  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const [filters, setFilters] = useState({
    openaccess: "",
    type: "",
    source: "",
    used: "",
    ordering: "",
  });
  const [filterOptions, setFilterOptions] = useState({
    typeOptions: [
      { value: "article", label: "article" },
      { value: "note", label: "note" },
      { value: "conference", label: "conference" },
      { value: "Book Chapter", label: "Book Chapter" },
    ],
    sourceOptions: [
      { value: "scopus", label: "Scopus" },
      { value: "wos", label: "Web of Science" },
      { value: "pubmed", label: "MEDLINE PubMed" },
    ],
    orderingOptions: [
      { value: "-year", label: "Year - desc" },
      { value: "year", label: "Year - asc" },
      { value: "-citations", label: "Citations - desc" },
      { value: "citations", label: "Citations - asc" },
      { value: "-title", label: "Title - desc" },
      { value: "title", label: "Title - asc" },
      { value: "-journal", label: "Journal - desc" },
      { value: "journal", label: "Journal - asc" },
    ],
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const entries = [10, 25, 50, 100];

  const [isLdaOpened, setIsLdaOpened] = useState(false);
  const [isTsneOpened, setIsTsneOpened] = useState(false);

  function valuetext(value) {
    return `${value}`;
  }

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);

  Modal.setAppElement(document.getElementById("main"));

  const customStyles = {
    content: {
      // top: "50%",
      // left: "50%",
      // right: "auto",
      borderRadius: 15,
      bottom: "auto",
      position: "absolute",
      // marginRight: "-50%",
      // transform: "translate(-50%, -50%)",
    },
    overlay: { zIndex: 99999, backgroundColor: "rgba(100, 100, 100, 0.75)" },
  };

  /*   const RenderPaperItem = (data) => {
    return (
      <PaperCollapse
        paperData={data.data}
        dataset={dataset}
        open={collapse === data.index}
        setCollapse={setCollapse}
        collapse={collapse}
        index={data.index}
        onClick={() => (collapse === data.index ? setCollapse(false) : setCollapse(data.index))}
      ></PaperCollapse>
    );
  }; */

  const handleLdaOpen = () => {
    setIsLdaOpened(true);
  };

  const handleTsneOpen = () => {
    setIsTsneOpened(true);
  };

  const handleYearChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleYearChartChange = (event, newValue) => {
    setYearSlider(newValue);
    let newchart = {};
    let newDatasets = JSON.parse(JSON.stringify(backStatsData));

    if (backStatsData) {
      newchart.labels = newDatasets.labels.slice(newValue - valueGlobal[1] - 1);
      let oth = newDatasets.datasets.map((d) => {
        let tmp = d.data.slice(newValue - valueGlobal[1] - 1);
        d.data = tmp;
        return d;
      });
      newchart.datasets = oth;
      newchart.load = true;
      setYearStatsData(newchart);
    }
  };

  const handleYearTmp = (event, newValue) => {
    // console.log(newValue);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleFilterOrderChange = (option) => {
    let choices = [];
    option.map((o) => {
      choices.push(o.value);
    });
    if (option == null) {
      setFilters((prevState) => ({
        ...prevState,
        ordering: "",
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        ordering: { value: choices.join(","), label: option.value },
      }));
    }
    setPage(1);
  };

  const getAlgorithm = (alg) => {
    if (alg == "gensim_lda") {
      return "LDA";
    } else if (alg == "gensim_lsi") {
      return "LSI";
    } else {
      return "";
    }
  };

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  const handleExportFile = () => {
    axios
      .get(`${URL_RUNSETS}/${runset.id}/export_papers_topic`, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          /* convert state to workbook */
          //console.log("DATA: ", response);
          const worksheet = XLSX.utils.json_to_sheet(response.data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "topic_papers");
          XLSX.writeFile(workbook, "mySLR_dataset_topic.xlsx");
        } else {
          //console.log(response.status);
        }
        //console.log(test);
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const selectResult = (i) => {
    setRunset(runsets.filter((x) => x.id === i)[0]);
    console.log("QUALE: ", runsets.filter((x) => x.id === i)[0]);
    setSelectedTopic(null);
    closeMenu();
  };

  const handleFetchDefaultDataset = () => {
    axios
      .get(`${URL_QUERYSTRINGS}/${id}`, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        setDataset(response.data.default_dataset);
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const handleFetchResults = () => {
    axios
      .get(`${URL_DATASETS}/${dataset}`, { headers: { Authorization: `${user.token}` } })
      .then((response) => {
        setRunsets(response.data.runsets);
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const handleFetchTopics = (runset) => {
    axios
      .get(`${URL_RUNSETS}/${runset}`, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        setTopics(response.data.topics);
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const handleFetch = () => {
    axios
      .get(
        `${URL_TOPICS_PAPERS}?topic_id=${selectedTopic}&ordering=${
          filters.ordering ? filters.ordering["value"] : ""
        }&page=${page}&limit=${pageSize}&type__in=${
          filters.type ? filters.type["value"] : ""
        }&search=${filters.source ? filters.source["value"] : ""}&year_start=${value[0]}&year_end=${
          value[1]
        }`,
        {
          headers: { Authorization: `${user.token}` },
        }
      )
      .then((response) => {
        let refilled = response.data.results.map((v) => ({
          ...v,
          _title: (
            <SuiTypography variant="caption" gutterBottom={true}>
              {v.title}
            </SuiTypography>
          ),
        }));

        setPapersData({
          /* results: {
            columns: [
              { Header: "title", accessor: "title", width: "25%" },
              { Header: "journal", accessor: "journal", width: "15%" },
              { Header: "year", accessor: "year", width: "10%" },
              { Header: "authors", accessor: "authors", width: "15%" },
            ],
            rows: refilled,
          }, */
          results: refilled,
          count: response.data.count,
          totalePages: response.data.total_pages,
          next: response.data.next,
          previous: response.data.previous,
        });
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const fetchOptions = () => {
    axios
      .get(`${URL_PAPERS_OPTIONS}?paper_querystring=${id}`, {
        headers: { Authorization: `${user.token}` },
      })
      .then((response) => {
        let data = [];
        response.data.map((item, index) => {
          data.push({
            value: item["openaccess"],
            label: item["openaccess"],
          });
        });
        setFilterOptions((prevState) => ({
          ...prevState,
          openaccessOptions: data,
        }));
      })
      .catch((_error) => {
        setError(_error);
      });

    axios
      .get(
        `${URL_PAPERS_TYPE_OPTIONS}?datasets_id=${dataset}&search=${
          filters.source ? filters.source["value"] : ""
        }`,
        {
          headers: { Authorization: `${user.token}` },
        }
      )
      .then((response) => {
        let types = [];
        response.data.map((item, index) => {
          types.push({
            value: item["type"],
            label: item["type"],
          });
        });
        setFilterOptions((prevState) => ({
          ...prevState,
          typeOptions: types,
        }));
      })
      .catch((_error) => {
        setError(_error);
      });
  };

  const handleFilterSourceChange = (option) => {
    if (option == null) {
      setFilters((prevState) => ({
        ...prevState,
        source: "",
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        source: option,
      }));
    }
    setPage(1);
  };

  const handleFilteTypeChange = (option) => {
    let choices = [];
    option.map((o) => {
      choices.push(o.value);
    });
    if (option == null) {
      setFilters((prevState) => ({
        ...prevState,
        type: "",
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        type: { value: choices.join(","), label: option.value },
      }));
    }
    setPage(1);
  };

  useEffect(() => {
    selectedTopic && handleFetch();
    dataset && fetchOptions();
  }, [selectedTopic, page, filters, value, pageSize]);

  useEffect(() => {
    dataset && handleFetchResults();
  }, [dataset]);

  useEffect(() => {
    runset_id && runsets && selectResult(runset_id);
    console.log("RUNSET", runset_id);
  }, [runsets]);

  useEffect(() => {
    //console.log();
    // runsets && selectResult(runsets[0].id);
  }, [runsets]);

  useEffect(() => {
    runset.complete && handleFetchTopics(runset.id);
  }, [runset]);

  useEffect(() => {
    let labels = [];
    let docsArray = [];
    let probsArray = [];
    let topicNum = 0;
    let chartData = {
      labels: [],
      datasets: [],
      load: false,
    };

    topics &&
      topics.map((t) => {
        setValue(null);
        let statsLabels = [];

        t.year_stats.map((item) => {
          if (item.year > 0) {
            statsLabels.push(item.year);
          } else {
            statsLabels.push(1980);
          }
        });

        let min = Math.min.apply(null, statsLabels);
        let max = Math.max.apply(null, statsLabels);

        if (min < min_y) {
          min_y = min;
        }

        if (max > max_y) {
          max_y = max;
        }

        setValue([min_y, max_y]);
        setValueGlobal([min_y, max_y]);
        setYearSlider(min_y);
      });

    topics &&
      topics.map((t) => {
        topicNum += 1;
        labels.push("Topic " + topicNum);
        docsArray.push(t.dominant_topic_papers);
        probsArray.push(Number(t.prob_topic_papers));

        let statsLabels = [];
        let statsData = [];
        let dataset = {
          label: "Topic " + topicNum,
          color: colors[topicNum - 1],
          data: [],
          stack: "Stack " + topicNum,
        };

        var year_range = range(min_y, max_y);
        statsLabels = [];

        year_range.map((y) => {
          if (t.year_stats.some((item) => item.year === y)) {
            statsData.push(t.year_stats.find((el) => el.year === y).total_count);
            statsLabels.push(y);
          } else {
            statsData.push(0);
            statsLabels.push(y);
          }
        });

        dataset.data = statsData;
        chartData.labels = statsLabels;
        chartData.datasets.push(dataset);
        chartData.load = true;

        setYearStatsData(chartData);
        setBackStatsData(chartData);
      });

    let dataset1 = {
      label: "Documents",
      color: ["info"],
      data: docsArray,
    };
    let dataset2 = {
      label: "Documents weight",
      color: ["error"],
      data: probsArray,
    };
    let datasets = [dataset1, dataset2];
    setTopicChartData({
      labels,
      datasets,
    });
  }, [topics]);

  useEffect(() => {
    handleFetchDefaultDataset();
  }, []);

  useEffect(() => {}, [papersData]);

  const selectTopic = (i) => {
    setSelectedTopic(i);
  };

  const renderMenuRunsets = (
    <Menu
      anchorEl={menu}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      {runsets &&
        runsets.map((r) => {
          return (
            <MenuItem onClick={() => selectResult(r.id)}>
              {"Gensim " + getAlgorithm(r.algorithm) + " " + r.topic_num + " topics"}
            </MenuItem>
          );
        })}
    </Menu>
  );

  return (
    <SuiBox py={3}>
      <div id="main">
        <Divider />
        <SuiBox display="flex" justifyContent="flex-end" mb={3} ml={2}>
          {/* <SuiBox mr={3}>
          <SuiButton variant="outlined" buttonColor="secondary">
            export&nbsp;&nbsp;
            <Icon className="">folder</Icon>
          </SuiButton>
        </SuiBox> */}
          {runset.complete && (
            <>
              <SuiBox mr={3}>
                <SuiButton variant="outlined" buttonColor="secondary" onClick={handleLdaOpen}>
                  Interactive LDA Explorer&nbsp;&nbsp;
                  <Icon className="">file</Icon>
                </SuiButton>
              </SuiBox>
              <SuiBox mr={3}>
                <SuiButton variant="outlined" buttonColor="secondary" onClick={handleTsneOpen}>
                  t-SNE Visualization&nbsp;&nbsp;
                  <Icon className="">file</Icon>
                </SuiButton>
              </SuiBox>
            </>
          )}
          <SuiButton variant="gradient" buttonColor="dark" onClick={openMenu}>
            Results&nbsp;
            <Icon className="">expand_more</Icon>
          </SuiButton>
          {renderMenuRunsets}
        </SuiBox>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              {runset.perplexity < 0 ? (
                <MiniStatisticsCard
                  title={{ text: "Perplexity", fontWeight: "medium" }}
                  count={runset.perplexity}
                  percentage={{
                    color: "success",
                    text: "LOG",
                  }}
                  icon={{ color: "dark", component: "numbers" }}
                />
              ) : (
                <MiniStatisticsCard
                  title={{ text: "Perplexity", fontWeight: "medium" }}
                  // count={runset.perplexity}
                  percentage={{
                    color: "warning",
                    text: "NOT AVAILABLE FOR LSI",
                  }}
                  icon={{ color: "dark", component: "numbers" }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "Coherence value", fontWeight: "medium" }}
                count={runset.coherence}
                percentage={{
                  color: "success",
                  text: "u_mass",
                }}
                icon={{ color: "dark", component: "article" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "best topic number", fontWeight: "medium" }}
                count={runset.topic_num}
                //percentage={{ color: "success", text: "-2%" }}
                icon={{ color: "dark", component: "settings_suggest" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "Modeling Algorithm", fontWeight: "medium" }}
                count="Gensim"
                percentage={{ color: "success", text: getAlgorithm(runset.algorithm) }}
                icon={{ color: "dark", component: "functions" }}
              />
            </Grid>
          </Grid>
        </SuiBox>
        <Modal
          isOpen={isLdaOpened}
          //onAfterOpen={afterOpenModal}
          onRequestClose={() => setIsLdaOpened(false)}
          style={customStyles}
        >
          <div id="main">
            <iframe
              src={`https://my.danielerogano.it/static/runset/${runset.id}_lda.html`}
              width={"100%"}
              height={800}
              // scrolling={"no"}
              marginheight={"0"}
              marginwidth={"0"}
              style={{ width: "100%" }}
            ></iframe>
          </div>
          <button onClick={() => setIsLdaOpened(false)}>Close</button>
        </Modal>
        <Modal
          isOpen={isTsneOpened}
          //onAfterOpen={afterOpenModal}
          onRequestClose={() => setIsTsneOpened(false)}
          style={customStyles}
        >
          <div id="main">
            <iframe
              src={`https://my.danielerogano.it/static/runset/${runset.id}_tsne.html`}
              width={"100%"}
              height={800}
              // scrolling={"no"}
              marginheight={"0"}
              marginwidth={"0"}
              style={{ width: "100%" }}
            ></iframe>
          </div>
          <button onClick={() => setIsTsneOpened(false)}>Close</button>
        </Modal>
        <Divider />
        {!runset.complete && (
          <>
            <SuiBox mb={3}>
              <SuiAlert color="light">
                <SuiTypography variant="h5" fontWeight="medium">
                  Your results are not ready yet. When your results are ready to view, an email will
                  be sent to your email address
                </SuiTypography>
              </SuiAlert>
              {/* <Grid container spacing={3}>
              <Typography variant={"p"}>
                Results not ready yet, please come back to this page in a few seconds
              </Typography>
            </Grid> */}
            </SuiBox>
          </>
        )}
        {topics && runset.complete && (
          <>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <VerticalBarChart title="Topics documents" chart={topicChartData} />
                </Grid>
                <Grid item xs={12} md={6}>
                  {yearStatsData.load && (
                    <YearBarChart
                      title="Papers per topic over time"
                      chart={yearStatsData}
                      height={400}
                    />
                  )}
                  {value.length > 0 && yearSlider && (
                    <>
                      <Slider
                        key={`slider-${yearSlider}`}
                        defaultValue={yearSlider}
                        min={valueGlobal[0]}
                        max={valueGlobal[1]}
                        onChangeCommitted={handleYearChartChange}
                        onChange={handleYearTmp}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        className={classes.yearChart_Slider}
                        step={1}
                      />
                      <SuiBox lineHeight={0}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          From: {yearSlider}
                        </SuiTypography>
                      </SuiBox>
                    </>
                  )}
                </Grid>
              </Grid>
            </SuiBox>
            <Divider />
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={4}>
                <SuiBox display="flex" justifyContent="center" alignItems="center" mx="auto" mb={2}>
                  <SuiButton
                    variant="contained"
                    buttonColor="dark"
                    size="small"
                    onClick={handleExportFile}
                  >
                    <Icon color="inherit">download_icon</Icon>
                    <Typography paddingLeft={2} fontSize={12}>
                      Export dataset per topic
                    </Typography>
                  </SuiButton>
                </SuiBox>
              </Grid>
            </Grid>
            <Topics topics={topics} selectTopic={selectTopic} runset_log={runset.logdata} />
          </>
        )}
        <Divider />
        {selectedTopic && papersData && (
          <>
            <SuiBox pt={1} pb={1} px={2} backgroundColor={"white"} borderRadius={15}>
              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item xs={3}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Year
                    </SuiTypography>
                  </SuiBox>
                  <Slider
                    defaultValue={value}
                    min={valueGlobal[0]}
                    max={valueGlobal[1]}
                    onChangeCommitted={handleYearChange}
                    onChange={handleYearTmp}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    className={classes.automotiveMonitor_slider}
                    step={1}
                  />
                  <SuiBox lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      {value[0]}
                    </SuiTypography>
                    {" - "}
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      {value[1]}
                    </SuiTypography>
                  </SuiBox>
                </Grid>

                <Grid item xs={3}>
                  <SuiBox mb={1} ml={1} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Source database
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    defaultValue={filters.source}
                    options={filterOptions.sourceOptions}
                    isClearable={true}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleFilterSourceChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <SuiBox mb={1} ml={1} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Document type
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    defaultValue={filters.type}
                    options={filterOptions.typeOptions}
                    isClearable={true}
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleFilteTypeChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Ordering
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    defaultValue={filters.order}
                    options={filterOptions.orderingOptions}
                    isClearable={true}
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleFilterOrderChange}
                  />
                </Grid>

                {/* <Grid item xs={2}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Options
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mx="auto"
                    mr={2}
                    ml={2}
                  >
                    <SuiButton
                      variant="contained"
                      buttonColor="info"
                      size="medium"
                      onClick={handleExportFile}
                    >
                      <Icon fontSize="large" color="inherit">
                        toc
                      </Icon>
                      Export dataset
                    </SuiButton>
                  </SuiBox>
                </Grid> */}
              </Grid>

              <Grid container spacing={1} p={2}>
                <Grid item xs={2} md={2}>
                  <SuiBox display="flex" alignItems="center">
                    <SuiSelect
                      defaultValue={{ value: pageSize, label: pageSize }}
                      options={entries.map((entry) => ({ value: entry, label: entry }))}
                      onChange={setEntriesPerPage}
                      size="small"
                    />
                    <SuiTypography variant="caption" textColor="secondary">
                      &nbsp;&nbsp;entries per page
                    </SuiTypography>
                  </SuiBox>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Separator text={"PAPERS (" + papersData.count + ")"} />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                {papersData.results &&
                  runset.id &&
                  papersData.results.map((item, index) => (
                    <RenderPaperItem
                      data={item}
                      index={item.id}
                      key={item.id}
                      collapse={collapse}
                      setCollapse={setCollapse}
                      rsid={runset.id}
                    ></RenderPaperItem>
                  ))}
              </Grid>
              <Pagination
                shape="rounded"
                count={papersData.totalePages}
                page={page}
                onChange={handleChange}
              />
              <Divider />
              {/* <DataTable
            table={papersData.results}
            entriesPerPage={false}
            showTotalEntries={false}
            isSorted={false}
          />
          <Pagination
            shape="rounded"
            count={papersData.totalePages}
            page={page}
            onChange={handleChange}
          /> */}
            </SuiBox>
          </>
        )}
      </div>
    </SuiBox>
  );
}

export default ResultsSynthesis;
