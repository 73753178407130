/* eslint-disable no-console */
/* eslint-disable */
import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../../auth-context/auth.context";

function ProtectedRoute({ ...rest }) {
  const history = useHistory();
  const { user } = useAuth();
  const paths = ["basic", "cover", "illustration"];

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    if (!user || !user.token || user.token === "") {
      newSwal
        .fire({
          title: "Sign In First",
          text: "In order to enter MySLR you must be signed in",
          confirmButtonText: "Ok, let's go!",
          showCancelButton: false,
          allowOutsideClick: false,
          focusConfirm: false,
        })
        .then(() =>
          // history.push(`/authentication/sign-in/${paths[Math.floor(Math.random() * paths.length)]}`)
          history.push(`/authentication/sign-in/cover`)
        );
    }
  };

  useEffect(() => {
    showAlert();
  }, []);

  return <Route {...rest} />;
}

export default ProtectedRoute;
