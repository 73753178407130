/* eslint-disable */
import { useState, useRef } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Box from "@mui/material/Box";
import { spacing } from "@mui/system";

import ReactPlayer from "react-player";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Header from "./components/HeaderBig";
import Footer from "./components/Footer";
import PricingCards from "./components/PricingCards";
import TrustedBrands from "./components/TrustedBrands";
import ContentCollapse from "./components/ContentCollapse";
import Faq from "./components/Faq";
import heromain from "assets/images/landing-main.png";
import pubtrend from "assets/images/pubblication_trend.png";
import workflow from "assets/images/workflow.png";

// microbox images
import workflow_image from "assets/pics/workflow.png";
import automated from "assets/pics/automated.png";
import knowledge from "assets/pics/knowledge.png";
import python from "assets/pics/py.png";
import graphic from "assets/pics/ui.png";
import database from "assets/pics/databases.png";

import topics from "assets/pics/topics.png";
import word_importance from "assets/pics/word_importance.png";
import years_stat from "assets/pics/years_stat.png";

import video_import from "assets/landing/MySLR_file_import.mp4";
import video_selection from "assets/landing/MySLR_papers_selection.mp4";
import video_overview from "assets/landing/MySLR_overview.mp4";
import video_results from "assets/landing/MySLR_results.mp4";

import { Divider } from "@mui/material";

function Homepage() {
  const [collapse, setCollapse] = useState(false);

  return (
    <PageLayout>
      <Header />
      <Container>
        <div id="why">
          <SuiBox pt={10}></SuiBox>
          <Faq />
        </div>

        <Divider />

        <Grid container spacing={2} justifyContent="center" className="text-center">
          <Grid item xs={12} lg={2} className="mx-auto">
            <Card>
              <CardActionArea>
                <Box sx={{ display: "flex", alignItems: "center" }} justifyContent="center">
                  <CardMedia component="img" height="80" image={workflow_image} alt="database" />
                </Box>
                <CardContent>
                  <SuiTypography variant="h6" fontWeight="bold">
                    Workflow control
                  </SuiTypography>
                  <SuiTypography display="inline" variant="caption" fontWeight="regular">
                    An innovative and collaborative tool supporting research groups through the
                    entire SLR process
                  </SuiTypography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} lg={2} className="mx-auto">
            <Card>
              <CardActionArea>
                <Box sx={{ display: "flex", alignItems: "center" }} justifyContent="center">
                  <CardMedia component="img" height="80" image={automated} alt="database" />
                </Box>
                <CardContent>
                  <SuiTypography variant="h6" fontWeight="bold">
                    Automated Analysis
                  </SuiTypography>
                  <SuiTypography display="inline" variant="caption" fontWeight="regular">
                    An original and innovative machine learning algorithm for latent knowledge
                    extraction
                  </SuiTypography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} lg={2} className="mx-auto">
            <Card>
              <CardActionArea>
                <Box sx={{ display: "flex", alignItems: "center" }} justifyContent="center">
                  <CardMedia component="img" height="80" image={knowledge} alt="database" />
                </Box>
                <CardContent>
                  <SuiTypography variant="h6" fontWeight="bold">
                    Knowledge Visualization
                  </SuiTypography>
                  <SuiTypography display="inline" variant="caption" fontWeight="regular">
                    Offering scholars the opportunity to explore new insights surrounding a given
                    topic
                  </SuiTypography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} lg={2} className="mx-auto">
            <Card>
              <CardActionArea>
                <Box sx={{ display: "flex", alignItems: "center" }} justifyContent="center">
                  <CardMedia component="img" height="80" image={database} alt="database" />
                </Box>
                <CardContent>
                  <SuiTypography variant="h6" fontWeight="bold">
                    Scientific Databases
                  </SuiTypography>
                  <SuiTypography display="inline" variant="caption" fontWeight="regular">
                    MySLR support many scientific database, SCOPUS, Web of Science, PubMED, and
                    more...
                  </SuiTypography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} lg={2} className="mx-auto">
            <Card>
              <CardActionArea>
                <Box sx={{ display: "flex", alignItems: "center" }} justifyContent="center">
                  <CardMedia component="img" height="80" image={graphic} alt="graphic" />
                </Box>{" "}
                <CardContent>
                  <SuiTypography variant="h6" fontWeight="bold">
                    Intuitive Design
                  </SuiTypography>
                  <SuiTypography display="inline" variant="caption" fontWeight="regular">
                    MySLR is intuitively usable by researchers in several fields. No programming
                    skills are required
                  </SuiTypography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} lg={2} className="mx-auto">
            <Card>
              <CardActionArea>
                <Box sx={{ display: "flex", alignItems: "center" }} justifyContent="center">
                  <CardMedia component="img" height="80" image={python} alt="python" />
                </Box>{" "}
                <CardContent>
                  <SuiTypography variant="h6" fontWeight="bold">
                    Python Powered
                  </SuiTypography>
                  <SuiTypography display="inline" variant="caption" fontWeight="regular">
                    Python allows very powerful methods of summarizing, analyzing ang filtering
                    large amount of papers
                  </SuiTypography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>

        <Divider />
        <div id="features">
          <SuiBox pt={10}></SuiBox>
          <Grid container spacing={5} justifyContent="center" className="text-justify">
            <Grid item xs={12} lg={12} mb={10}>
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Features: A use case{" "}
                </SuiTypography>
              </SuiBox>
              <SuiBox mb={2}>
                <SuiTypography variant="body2" fontStyle="italic">
                  The results shown on this page refer to a demonstrative research project
                  concerning systematic literature reviews. The articles were selected from Scopus,
                  Web of Science and Pubmed through the search string "Systematic Literature Review
                  and topic modeling".
                </SuiTypography>
              </SuiBox>
            </Grid>
          </Grid>
        </div>

        <ContentCollapse
          title="Import papers from scientific databases"
          open={collapse === 1}
          onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
              <ReactPlayer
                url={video_import}
                playing={false}
                controls={true}
                loop={false}
                width="100%"
                height="100%"
              />
            </Grid>

            <Grid item xs={12} lg={6} className="mx-auto">
              <SuiTypography variant="h3" fontWeight="bold" ml={5}>
                Import papers from scientific databases
              </SuiTypography>
              <SuiTypography variant="body2" fontWeight="regular" ml={5}>
                Although the first version of MySLR has been specifically developed to be used for
                management studies, the platform now supports all the research areas covered by:
              </SuiTypography>
              <SuiTypography variant="body2" fontWeight="regular" ml={10} mb={10}>
                <ul>
                  <li>SCOPUS</li>
                  <li>Web of Science</li>
                  <li>MEDLINE PubMed</li>
                </ul>
              </SuiTypography>
            </Grid>
          </Grid>
        </ContentCollapse>

        <ContentCollapse
          title="Papers selection"
          open={collapse === 2}
          onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} className="mx-auto">
              <SuiTypography variant="h3" fontWeight="bold" ml={5}>
                Papers selection
              </SuiTypography>
              <SuiTypography variant="body2" fontWeight="regular" ml={5}>
                Quick and easy papers meta-data consultation to iclude in the subsequent analysis
                phases
              </SuiTypography>
              <SuiTypography variant="body2" fontWeight="regular" ml={10} mb={10}>
                <ul>
                  <li>Innovative</li>
                  <li>Collaborative</li>
                  <li>User-friendly</li>
                </ul>
              </SuiTypography>
            </Grid>
            <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
              <ReactPlayer
                url={video_selection}
                playing={false}
                controls={true}
                loop={false}
                width="100%"
                height="100%"
              />
            </Grid>
          </Grid>
        </ContentCollapse>

        <ContentCollapse
          title="Dataset statistics"
          open={collapse === 3}
          onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
              <ReactPlayer
                url={video_overview}
                playing={false}
                controls={true}
                loop={false}
                width="100%"
                height="100%"
              />
            </Grid>

            <Grid item xs={12} lg={6} className="mx-auto">
              <SuiTypography variant="h3" fontWeight="bold" ml={5}>
                Dataset statistics
              </SuiTypography>
              <SuiTypography variant="body2" fontWeight="regular" ml={5} mb={10}>
                Although the first version of MySLR has been specifically developed to be used for
                management studies, the platform now supports all the research areas covered by the
                SCOPUS, WEB OF SCIENCE and PUBMED databases.
              </SuiTypography>
            </Grid>
          </Grid>
        </ContentCollapse>

        <ContentCollapse
          title="Dataset statistics"
          open={collapse === 4}
          onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} className="mx-auto">
              <SuiTypography variant="h3" fontWeight="bold" ml={5}>
                Papers analysis and results
              </SuiTypography>
              <SuiTypography variant="body2" fontWeight="regular" ml={5}>
                t-distributed stochastic neighbor embedding allows visualizing high-dimensional data
                by giving each datapoint a location in a two or three-dimensional map after topic
                modeling
              </SuiTypography>
              <SuiTypography variant="body2" fontWeight="regular" ml={10} mb={10}>
                <ul>
                  <li>Interactive</li>
                  <li>Easy to read</li>
                </ul>
              </SuiTypography>
            </Grid>
            <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
              <ReactPlayer
                url={video_results}
                playing={false}
                controls={true}
                loop={false}
                width="100%"
                height="100%"
              />
            </Grid>
          </Grid>
        </ContentCollapse>
        <div id="results">
          <SuiBox pt={10}></SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} className="mx-auto">
              <Box justifyContent="center">
                <SuiTypography variant="h3" fontWeight="bold" ml={5}>
                  Topic Word Clouds
                </SuiTypography>
                <SuiTypography variant="body2" fontWeight="regular" ml={5} mb={5}>
                  Most representative text identified by the LDA procedure using words clouds with
                  the size of the words proportional to the weight in each topic
                </SuiTypography>
                <SuiBox
                  component="img"
                  id={0}
                  src={topics}
                  alt="small image 1"
                  borderRadius="lg"
                  boxShadow="regular"
                  width="100%"
                  customClass="cursor-pointer h-100 object-cover"
                  px={{ xs: 1, sm: 0 }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6} className="mx-auto">
              <Box justifyContent="center">
                <SuiTypography variant="h3" fontWeight="bold" ml={5}>
                  Keywords importance over Topics
                </SuiTypography>
                <SuiTypography variant="body2" fontWeight="regular" ml={5} mb={5}>
                  Weights of the keywords and how frequently words appear in the papers is also
                  represented to evaluate the coherence of the model
                </SuiTypography>
                <SuiBox
                  component="img"
                  id={0}
                  src={word_importance}
                  alt="small image 1"
                  borderRadius="lg"
                  boxShadow="regular"
                  width="100%"
                  customClass="cursor-pointer h-100 object-cover"
                  px={{ xs: 1, sm: 0 }}
                />
              </Box>
            </Grid>
          </Grid>
        </div>

        <Divider />

        <Grid container spacing={3}>
          <Grid item xs={12} lg={8} className="mx-auto">
            <Box justifyContent="center">
              <SuiTypography variant="h3" fontWeight="bold" ml={5}>
                Topic Research evolution over years
              </SuiTypography>
              <SuiTypography variant="body2" fontWeight="regular" ml={5} mb={5}>
                Evolution of scientific publications over the years for each topic identified by the
                methodology
              </SuiTypography>
              <SuiBox
                component="img"
                id={0}
                src={years_stat}
                alt="small image 1"
                borderRadius="lg"
                boxShadow="regular"
                width="100%"
                customClass="cursor-pointer h-100 object-cover"
                px={{ xs: 1, sm: 0 }}
              />
            </Box>
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} className="mx-auto">
            <Box justifyContent="center">
              <SuiTypography variant="h3" fontWeight="bold" ml={5}>
                LDA Topics interactive explorer
              </SuiTypography>
              <iframe
                src="https://myslr.unical.it/lda.html"
                width={"100%"}
                height={1000}
                // scrolling={"no"}
                marginHeight={"0"}
                marginWidth={"0"}
                style={{ width: "100%" }}
              ></iframe>
            </Box>
          </Grid>
        </Grid>

        <Divider />

        <div id="about">
          <SuiBox pt={10}></SuiBox>
          <Box justifyContent="center">
            <SuiTypography variant="h3" fontWeight="bold" ml={5}>
              About
            </SuiTypography>
          </Box>
          <SuiBox mb={2}>
            <SuiTypography variant="body2" fontStyle="normal">
              MySLR is a Cloud Platform for conduct Systematic Literature Reviews starting from raw
              digital papers metadata using unsupervised machine learning algorithms. It uses Topic
              Modeling (Latent Dirichlet Allocation - LDA, Latent Semantic Analysis - LSA) to
              discover the semantic structure of documents by learning from a corpus of training
              documents by examining statistical co-occurrence.
            </SuiTypography>
            <Divider />
            <SuiTypography variant="body2" fontWeight="bold">
              What's new in MySLR 1.0
            </SuiTypography>
            <SuiBox mb={2} pl={5}>
              <ul>
                <li>
                  <SuiTypography variant="body2" fontStyle="normal">
                    Support for Elsevier's Scopus database, Clarivate Analytics' Web of Science
                    database and MEDLINE PubMED database
                  </SuiTypography>
                </li>
                <li>
                  <SuiTypography variant="body2" fontStyle="normal">
                    Enable a review team to collaborate from remote and concurrently
                  </SuiTypography>
                </li>
                <li>
                  <SuiTypography variant="body2" fontStyle="normal">
                    Added the Latent Semantic Analysis for Topic Modeling
                  </SuiTypography>
                </li>
              </ul>
            </SuiBox>
          </SuiBox>
        </div>

        <div id="conditions">
          <Box justifyContent="center">
            <SuiTypography variant="h3" fontWeight="bold" ml={5}>
              Conditions of use
            </SuiTypography>
          </Box>
          <SuiBox mb={2}>
            <SuiTypography variant="body2" fontStyle="normal">
              MySLR is a proprietary platform that can be used freely. We ask each user:
            </SuiTypography>
          </SuiBox>
          <SuiBox mb={2} pl={5}>
            <ul>
              <li>
                <SuiTypography variant="body2" fontStyle="normal">
                  To register a new account on Myslr platform by specifying the affiliation and the
                  research goals.
                </SuiTypography>
              </li>
            </ul>
          </SuiBox>
          <SuiBox mb={2} pl={5}>
            <ul>
              <li>
                <SuiTypography variant="body2" fontStyle="normal">
                  To mention explicitly the use of MySLR when publishing results, using the
                  following reference:
                </SuiTypography>
                <SuiTypography variant="body2" fontStyle="italic">
                  <a href="https://www.tandfonline.com/doi/abs/10.1080/14778238.2022.2041375">
                    S. Ammirato, A.M. Felicetti, D. Rogano, R. Linzalone, V. Corvello (2022) –
                    “Digitalizing the Systematic Literature Review process: the MySLR platform”,
                    Knowledge Management Research & Practice, DOI: 10.1080/14778238.2022.2041375;
                    Vol.: ahead-of-print, n°: ahead-of-print
                  </a>
                </SuiTypography>
              </li>
            </ul>
          </SuiBox>
          <Divider />
          <SuiTypography variant="body2" fontWeight="bold">
            DISCLAIMER
          </SuiTypography>
          <SuiBox mb={2} pl={5}>
            <SuiTypography variant="body2" fontStyle="italic">
              MySLR is provided free of charge and "AS IS" without any warranty of any kind. The
              implied warranties of merchantability, fitness for a particular purpose and
              non-infringment are expressly disclaimed. In no event will the authors (Salvatore
              Ammirato and Daniele Rogano) or his employer (University of Calabria) be liable to any
              party for any damages for any use of the platform.
            </SuiTypography>
          </SuiBox>
        </div>

        <div id="contact">
          <Box justifyContent="center">
            <SuiTypography variant="h3" fontWeight="bold" ml={5}>
              Contact
            </SuiTypography>
          </Box>
          <SuiBox mb={2}>
            <SuiTypography variant="body2" fontStyle="normal">
              MySLR has been developed by{" "}
              <a href="mailto=salvatore.ammirato@unical.it">Salvatore Ammirato</a> and{" "}
              <a href="mailto=daniele.rogano@unical.it">Daniele Rogano</a>, University of Calabria,
              Italy. Please contact the authors if you have any questions about MySLR use.
            </SuiTypography>
            <SuiTypography variant="body2" fontStyle="normal"></SuiTypography>
          </SuiBox>
        </div>
      </Container>
      <SuiBox mt={8}>
        <Footer />
      </SuiBox>
    </PageLayout>
  );
}

export default Homepage;
