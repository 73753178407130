/* eslint-disable */
import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import ReactPlayer from "react-player";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "./components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import workflow from "assets/images/workflow.png";

import { Divider } from "@mui/material";

// videos
/*
import video_1 from "assets/howto/01.mp4";
import video_2 from "assets/howto/02.mp4";
import video_3 from "assets/howto/03.mp4";
import video_4 from "assets/howto/04.mp4";
import video_5 from "assets/howto/05.mp4";
import video_6 from "assets/howto/tutorial6.mp4";
import video_7 from "assets/howto/tutorial7.mp4";
import video_8 from "assets/howto/tutorial8.mp4";
import video_9 from "assets/howto/tutorial9.mp4";
import video_10 from "assets/howto/10.mp4";
import video_11 from "assets/howto/11.mp4";
import video_12 from "assets/howto/12.mp4";
import video_13 from "assets/howto/13.mp4";
import collaborator from "assets/howto/collaborator.mp4";
import conflict_manager from "assets/howto/conflict_manager.mp4";
import vis_collab from "assets/howto/vis_collab.mp4";
*/

import scopusTutorial from "assets/tutorial/scopus_import.mp4";
import wosTutorial from "assets/tutorial/wos_import.mp4";
import pubmedTutorial from "assets/tutorial/pubmed_import.mp4";

function HowToUse() {
  const [play1, setPlay1] = useState(false);
  const video_1 = "https://youtu.be/bWMUCRzzey0?si=yw2mzwg0SWVjgqtt";
  const video_2 = "https://youtu.be/lmB7o6Jym90?si=nvtRN3DSVNqNhVfz";
  const video_3 = "https://youtu.be/2eeNGEclz0E?si=DvomXJtNlGRQFbPg";
  const video_4 = "https://youtu.be/yl3zf5TVJL8?si=OmC_gdV8w58ZFUYe";
  const video_5 = "https://youtu.be/BsoT8NzFRyA?si=5bLg1Wf6RC9d93_h";
  const video_6 = "https://youtu.be/HJnrUq8RfTM?si=eaq4ynbMOSgU5oTz";
  const video_7 = "https://youtu.be/AKmgtMRTKyU?si=WgNQtyWwKCBbRt9d";
  const video_8 = "https://youtu.be/r-Xe3fYjf8U?si=Orwzy0pzBjJfKqw7";
  const video_9 = "https://youtu.be/uW5-qOXuJIw?si=k2K0ROz4HGH5kLNJ";
  const video_10 = "https://youtu.be/dcxs8Xe-6wA?si=h2ia7U5Q9AEUwruj";
  const video_11 = "https://youtu.be/A0YhUobptZY?si=4VKLYFYHSibSGnA9";
  const video_12 = "https://youtu.be/Png2Wjh4DpM?si=anLGjHayZNazQhuY";
  const video_13 = "https://youtu.be/6lKN1xtJSgc?si=FKr9kbTkkgajjI66";
  const collaborator = "https://youtu.be/uTlOZOG9yEA?si=7VFcdJW-wJlKyzfC";
  const conflict_manager = "https://youtu.be/kYXpdzvLTac?si=Uuun1or1d5sWYGb_";

  return (
    <DashboardLayout>
      <Header />

      {/* SECTION 01 */}

      <div id="01">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                1 - The first step is to create a new research project
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_1}
                  playing={play1}
                  onStart={() => setPlay1(true)}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 02 */}

      <div id="02">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                2 - You, as the research creator, are the project owner. Would you involve one or
                more collaborator to help you? You can invite them to join the project, whether they
                are registered or not to MySLR
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_2}
                  playing={false}
                  onStart={() => setPlay1(false)}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 03 */}

      <div id="03">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                3 - Each project can be made of different SLRs. This means to create different
                queries (one for each SLR) that will be used as to select papers from the
                investigated DBs. MySLR enables you to create the different queries. The first query
                should be created by the owner while collaborators could create others.
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_3}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 04 */}

      <div id="04">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                4 - PAPER LOCATION. Once a query is created, you must choose from which database you
                want to import data. MySLR enables you to import data (i.e.: title, abstract and
                keywords for each paper) from the most recognized scientific DBs (Scopus, Web Of
                Science, Pubmed) and create your personal DB by merging results of all the DBs you
                choose.
              </SuiTypography>
              <Grid container item xs={12} lg={11} mb={2} className="mx-auto">
                <SuiTypography variant="body" fontWeight="regular">
                  <ol>
                    <li>
                      First, you must put your query in the selected databases (according its rules)
                      and export results in a standard format
                    </li>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={4} className="mx-auto" mb={10}>
                        <ReactPlayer
                          url={scopusTutorial}
                          light={true}
                          playing={false}
                          controls={true}
                          loop={false}
                          width="100%"
                          height="100%"
                        />
                        <SuiTypography display="inline" variant="caption" fontWeight="regular">
                          ELSEVIER Scopus file export
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} lg={4} className="mx-auto" mb={10}>
                        <ReactPlayer
                          url={wosTutorial}
                          light={true}
                          playing={false}
                          controls={true}
                          loop={false}
                          width="100%"
                          height="100%"
                        />
                        <SuiTypography display="inline" variant="caption" fontWeight="regular">
                          CLARIVATE Web of Science file export
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} lg={4} className="mx-auto" mb={10}>
                        <ReactPlayer
                          url={pubmedTutorial}
                          light={true}
                          playing={false}
                          controls={true}
                          loop={false}
                          width="100%"
                          height="100%"
                        />
                        <SuiTypography display="inline" variant="caption" fontWeight="regular">
                          MEDLINE PubMED file export
                        </SuiTypography>
                      </Grid>
                    </Grid>
                    <li>Second, you must import the exported files on MySLR</li>
                  </ol>
                </SuiTypography>
              </Grid>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_4}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 05 */}

      <div id="05">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                5 - When the DBs are uploaded, MySLR first shows the number of all selected papers
                (with duplicates) second, it merges the results by eliminating duplications and
                shows the number of considered papers that constitutes the initial DB for further
                analyses.
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 06 */}

      <div id="06">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                6 - For each paper in the DB, MySLR gives you many bibliometric information (title,
                abstract, year of publication, publication name, number of citations, and many
                others) so that you can decide to select that paper for the final data set or to
                exclude it. In doing this activity, you can be helped by many filters.
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_5}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 07 */}

      <div id="07">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                7 - PAPER SELECTION. Now you are ready to select the papers constituting the final
                set for the subsequent analysis. By default, all the located papers are in the final
                set. After reading the information that MySLR show you for each paper, you can
                decide to select or exclude that paper. In case you need more information, for each
                paper the link “view at publisher” is active to download the full paper (subject to
                copyright).
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_6}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 08 */}

      <div id="08">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                8 - COLLABORATION. In the paper selection phase, you can be helped by collaborators
                which can suggest some papers to exclude or select. Those papers become “conflicts”
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={collaborator}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 09 */}

      <div id="09">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                9 - At any time and, mandatory, before confirming the final data set, the owner can
                resolve the conflicts
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={conflict_manager}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 10 */}

      <div id="10">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                10 - The owner can decide to create different DBs, each of them is a subset of the
                original one that is created by excluding papers with different personal criteria.
                At the end, the owner will choose the one to be analysed as final data set. By
                confirming the DB, first results will be available.
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_7}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 11 */}

      <div id="11">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                11 - Before launching the generative statistical models to explain the sampled
                papers, the owner should verify the list of most relevant terms found in the dataset
                to decide which of them eliminate (actually, add to the stop words list) since they
                will not constitute possible keywords for the analysis. Then the PAPER LOCATION AND
                SELECTION phase is finally concluded
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_8}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 12 */}

      <div id="12">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                12 - PAPER ANALYSIS. Now you must launch the LDA and LSI generative statistical
                models. These activities can be onerous from a computational viewpoint since can
                require some time. Anyway, you do not have to wait; MySLR will notify you with an
                email when results are ready.
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_9}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 13 */}

      <div id="13">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                13 - When the LDA and LSI graphs are ready, you can see the evolution of coherence
                values with increasing numbers of topics. For more detailed information, you can
                select a certain number of topics and models to perform. This activity and the
                following can be performed by collaborators as well
              </SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_10}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* SECTION 14 */}

      <div id="14">
        <SuiBox pt={5}></SuiBox>
        <Grid container spacing={3} justifyContent="center" className="text-justify">
          <Grid item xs={11} lg={11}>
            {/* <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">
                  Why MySLR
                </SuiTypography>
              </SuiBox> */}
            <SuiBox mb={2}>
              <SuiTypography display="inline" variant="body1" fontWeight="regular">
                14 - RESULT SYNTHESIS. When results are ready, you can visualize them for each
                number of topics and selected model. You can visualize:
              </SuiTypography>
            </SuiBox>
            <Grid container item xs={12} lg={11} mb={2} className="mx-auto">
              <SuiTypography variant="body" fontWeight="regular">
                <ul>
                  <li>
                    The interactive LDA explorer that, by putting in contrast results of different
                    numbers of topics, can help to decide which is the best number of topics and
                    which are the keyword to use to give a meaning of each topic
                  </li>
                </ul>
              </SuiTypography>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_12}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} lg={11} mb={2} className="mx-auto">
              <SuiTypography variant="body" fontWeight="regular">
                <ul>
                  <li>
                    The T-SNE visualization that enable the visualization of evolution of papers
                    with years per topic
                  </li>
                </ul>
              </SuiTypography>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_13}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} lg={11} mb={2} className="mx-auto">
              <SuiTypography variant="body" fontWeight="regular">
                <ul>
                  <li>
                    The list of papers per topics with information useful for the subsequent topics
                    discussion phase that is generally performed with a human-based review of a
                    restricted, representative, and relevant subset of papers. For each topic MySLR
                    shows the document-terms matrix that helps selecting the subset of
                    representative papers that can explain the topic itself.
                  </li>
                </ul>
              </SuiTypography>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} className="mx-auto" mb={10}>
                <ReactPlayer
                  url={video_11}
                  playing={false}
                  controls={true}
                  loop={false}
                  width="100%"
                  height="400px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </DashboardLayout>
  );
}

export default HowToUse;
