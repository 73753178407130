/* eslint-disable */

import { useState, useEffect } from "react";
// react-router-dom components
import { useHistory } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "pageRoutes";

import { ChangePassword } from "api/auth";

function Basic() {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [buttonText, setButtonText] = useState("Send");
  const queryParams = new URLSearchParams(window.location.search);

  const token = queryParams.get("token");

  const Redirect = () => {
    const timer = setTimeout(() => {
      return history.push("/authentication/sign-in/cover");
    }, 3000);
    return () => clearTimeout(timer);
  };

  const confirmPassword = async (event) => {
    if (event) {
      event.preventDefault();
    }

    setButtonText("Sending changes");
    try {
      const response = await ChangePassword({
        token,
        password,
      });
      if (response.status === 200) {
        setIsRedirecting(true);
        Redirect();
      }
      if (response.data && response.data.success === false) {
        // console.log(response.data);
        setButtonText("Send");
        return setError("Password change not available");
      }
    } catch (err) {
      // console.log(err);
      setButtonText("Send");
      if (err.response) {
        return setError(err.response.data.password[0]);
      }
      // console.log(err.data);
      return setError("There has been an error.");
    }
  };

  return (
    <PageLayout background="light">
      <DefaultNavbar routes={pageRoutes} />
      <Grid container spacing={3} justifyContent="center" sx={{ minHeight: "75vh" }}>
        <Grid item xs={10} md={6} lg={4}>
          <SuiBox mt={32} mb={3} px={{ xs: 0, lg: 2 }}>
            <Card>
              <SuiBox pt={3} px={3} pb={1} textAlign="center">
                <SuiTypography variant="h4" fontWeight="bold" textGradient>
                  Change password
                </SuiTypography>
                <SuiTypography variant="body2" textColor="text">
                  Please choose a new password for your account
                </SuiTypography>
              </SuiBox>
              {isRedirecting ? (
                <SuiTypography component="label" variant="p" fontWeight="bold">
                  Password changed! You will be redirected to login page.
                </SuiTypography>
              ) : (
                <SuiBox p={3}>
                  <SuiBox component="form" role="form">
                    <SuiBox mb={2}>
                      <SuiInput
                        defaultValue={password}
                        onChange={(event) => {
                          setPassword(event.target.value);
                          setError(undefined);
                        }}
                        type="password"
                        placeholder="Password"
                      />
                    </SuiBox>
                    {error && (
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textColor="error"
                      >
                        {error}
                      </SuiTypography>
                    )}
                    <SuiBox mt={5} mb={1}>
                      <SuiButton
                        onClick={confirmPassword}
                        variant="gradient"
                        buttonColor="dark"
                        size="large"
                        fullWidth
                      >
                        {buttonText}
                      </SuiButton>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              )}
            </Card>
          </SuiBox>
        </Grid>
      </Grid>
      <Footer />
    </PageLayout>
  );
}

export default Basic;
