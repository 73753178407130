/* eslint-disable */
// react-countup components
import CountUp from "react-countup";
import { Tooltip } from "@mui/material";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { Icon } from "@mui/material";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

function OutlinedCounterCard({ color, count, title, prefix, suffix, tooltip }) {
  const { secondary } = colors;
  const { borderWidth } = borders;

  return (
    <SuiBox
      borderRadius="md"
      border={`${borderWidth[1]} dashed ${secondary.main}`}
      textAlign="center"
      py={2}
    >
      <SuiTypography variant="h6" textColor={color} fontWeight="medium" textTransform="capitalize">
        {title}{" "}
        {tooltip && (
          <Tooltip title={tooltip} placement="right">
            <SuiButton variant="outlined" buttonColor="secondary" size="small" circular iconOnly>
              <Icon className="">question_mark_outlined</Icon>
            </SuiButton>
          </Tooltip>
        )}
      </SuiTypography>

      <SuiTypography variant="h4" fontWeight="bold">
        {prefix && (
          <SuiTypography component="span" variant="h5" fontWeight="bold">
            {prefix}
          </SuiTypography>
        )}
        <SuiBox display="inline-block" mx={0.5}>
          <CountUp end={count} duration={1} separator="," />
        </SuiBox>
        {suffix && (
          <SuiTypography component="span" variant="h5" fontWeight="bold">
            {suffix}
          </SuiTypography>
        )}
      </SuiTypography>
    </SuiBox>
  );
}

// Setting default values for the props of OutlinedCounterCard
OutlinedCounterCard.defaultProps = {
  color: "info",
  prefix: "",
  suffix: "",
};

// Typechecking props for the BlogCard
OutlinedCounterCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default OutlinedCounterCard;
